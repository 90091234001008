import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageTurnoffPromoteBar,
} from '../../ducks/UI.duck';
import { sendPostalAddress } from '../../ducks/user.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { EVENT_BROWSE_HOMEPAGE } from '../../util/gtm/gtmConstants';
import { getAddressDetailsByLatLong } from '../../util/googleMaps';
import { fetchFeaturedBCar, selectedPrediction } from '../SearchPage/SearchPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import SectionTestimonials from '../BLandingPage/SectionTestimonials';
import SectionHelpBLanding from '../BLandingPage/SectionHelpBLanding';
import BLandingCss from '../BLandingPage/BLandingPage.css';
import SectionSearchBanner from '../BLandingPage/SectionSearchBanner';
import SectionGrids from '../BLandingPage/SectionGrids';
// import SeoLandingRentalCategories from '../BLandingPage/SeoLandingRentalCategories';
import CarsListings from '../BLandingPage/CarsListings';
import ChannelsListings from '../BLandingPage/ChannelsListings';
import GrabBannerDesktop from '../BLandingPage/GrabBannerDesktop';
import InsuranceLandingModal from '../../components/InsuranceLandingModal/InsuranceLandingModal';
import imageSource from '../../util/imageSource';
import InviteSection from '../BLandingPage/InviteSection';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const MAX_MOBILE_SCREEN_WIDTH = 768;

let EXPERIMENT_DATA = {};

let initialPageData = null;

export const LandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    listings,
    viewport,
    fetchFeaturedCarInProgress,
    onSelectedPrediction,
    onSendPostalAddress,
    isAuthenticated,
    currentUser,
  } = props;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const history = useHistory();
  const [videoModalOpen, onVideoModalOpen] = useState(false);
  const [searchEvents, setSearchEvents] = useState([]);
  const [isPostalCodeExists, setIsPostalCodeExists] = useState(false);
  const [isInsuranceModalOpen, setInsuranceModalOpen] = useState(false);
  const [eventTriggered, setEventTriggered] = useState(false);

  const onPushGTMEvent = (event, buttonId) => {
    // pushGTMBrowseEvent({
    //   props,
    //   event,
    //   buttonId,
    // });
  };

  const triggerEvent = async () => {
    try {
      // console.log("trigger search explore", currentUser, props);
      const response = await getEventsByBucket('search');
      setSearchEvents(response.data);
      
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.SEARCH_EXPLORE,
        eventData: response.data,
        props: { 
          ui: {
                page: 'OnboardingScreen'
          },
          guest: currentUser
         },
         user: currentUser,
         userId: currentUser ? currentUser.id.uuid : null
      });
      
      setEventTriggered(true);
    } catch (error) {
      console.log('Error fetching events:', error);
    }
  };

  useEffect(() => {
    if (!eventTriggered) {
      if (props.isAuthenticated) {
        if (currentUser) {
          triggerEvent(currentUser);
          setEventTriggered(true);
        }
      } else {
        triggerEvent(null);
        setEventTriggered(true);
      }
    }
  }, [props.isAuthenticated, currentUser]); // Run when isAuthenticated or currentUser is updated
  // useEffect(() => {

  //   try {
  //     const response = await getEventsByBucket('search');
  //     // console.log('Events fetched successfully:', response.data);
  
  //     setSearchEvents(response.data);
  //     console.log("GTM Events ssssssssssss", !isAuthenticated && tab === 'signup')
  //     triggerAnalyticsEvent({ event_id: 'search_explore', eventData: response.data, props: { ...this.props }});
  //   } catch (error) {
  //     console.log('Error fetching events:', error);
  //   }

  //   // Here we have to hit

  //   // onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
  // }, []);
  // useEffect(() => {
  //   console.log("Is authenticated", props.isAuthenticated)
  //   const fetchEventsAndTriggerAnalytics = async () => {
  //     try {
  //       const response = await getEventsByBucket('search');
  //       // console.log('Events fetched successfully:', response.data);
        
  //       setSearchEvents(response.data);
        
  //       triggerAnalyticsEvent({
  //         event_id: 'search_explore',
  //         eventData: response.data,
  //         props: { ...props },
  //         userId: currentUser ? currentUser.id.uuid : null
  //       });
  //     } catch (error) {
  //       console.log('Error fetching events:', error);
  //     }

  //     // Trigger GTM event
  //     // onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
  //   };

  //   fetchEventsAndTriggerAnalytics();
  // }, [currentUser]); // Ensure dependencies are correctly listed

  useEffect(() => {
    if (currentUser) {
      const code =
        currentUser.attributes &&
        currentUser.attributes.profile &&
        currentUser.attributes.profile.protectedData &&
        currentUser.attributes.profile.protectedData.locationProxy &&
        currentUser.attributes.profile.protectedData.locationProxy.selectedPlace &&
        currentUser.attributes.profile.protectedData.locationProxy.selectedPlace.postalCode
          ? currentUser.attributes.profile.protectedData.locationProxy.selectedPlace.postalCode
          : '';
      setIsPostalCodeExists(!!code);
    }
  }, [currentUser]);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {}

  const onSelectionPostalAddress = location => {
    if (
      location &&
      location.origin &&
      location.origin.lat &&
      location.origin.lng &&
      !isPostalCodeExists
    ) {
      const latitude = location.origin.lat;
      const longitude = location.origin.lng;
      getAddressDetailsByLatLong(latitude, longitude)
        .then(address => {
          if (
            !address ||
            !address.formattedAddress ||
            !address.formattedAddress.selectedPlace ||
            !address.formattedAddress.selectedPlace.postalCode
          ) {
            // console.log(
            //   'onSelectionPostalAddress: not able to find postalCode from google maps search selection result.'
            // );
            return;
          }
          onSendPostalAddress(
            address.formattedAddress,
            () => {
              // console.log('onSelectionPostalAddress -> onSendPostalAddress: Success');
            },
            error => {
              // console.log('onSelectionPostalAddress -> onSendPostalAddress -> Error: ', error);
            }
          );
        })
        .catch(error => {
          console.log('onSelectionPostalAddress -> Error:', error);
        });
    }
  };

  const inviteSectionAsComponent = (
    <InviteSection
      currentUser={currentUser}
      intl={intl}
      history={history}
      isMobileLayout={isMobileLayout}
    />
  );

  return (
    <Page
      className={BLandingCss.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={[
        {
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        },
        {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          'mainEntity': [
            {
              '@type': 'Question',
              'name': 'What is Drive lah?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p>Drive lah is a unique peer-to-peer car sharing platform which allows people to rent other people\'s cars. People who own a car can rent out their car to fellow Singaporeans when they don\'t need their car. Trips are comprehensively insured for the hosts and guests peace of mind.</p>',
              },
            },
            {
              '@type': 'Question',
              'name': 'Why should I rent with Drive lah?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p>Drive lah is the most convenient way to access to cars in Singapore. We offer the largest variety of cars nearby at a click of a button. Drive lah utilizes unused car hours of individual car owners making us typically 30-40% cheaper than traditional rental companies.</p>',
              },
            },
            {
              '@type': 'Question',
              'name': 'As a host, how is the safety of my car ensured?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p>We carry stringent checks on guests who rent on Drive lah through their NRIC/FIN and driving license. Though highly unlikely, in the event of any unfortunate incident, your car is comprehensively insured through Drive lah protection<sup>TM</sup> including the following: <ul><li>Death or injury to third party(unlimited)</li><li>Damage to third party property(up to S$5,000,000)</li><li>Loss or damage to your vehicle from accident causes (up to market value at the time of loss or damage)</li><li>Loss of damage by fire or theft (up to market value at the time of loss or damage)</li></ul></p>',
              },
            },
            {
              '@type': 'Question',
              'name': 'What are the regulations with regards to peer to peer car sharing in Singapore?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p>The Land Transport Authority(LTA) has given Drive lah special approval which allows rental of private cars on both weekdays & weekends Details here</p>',
              },
            },
          ],
        },
      ]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage={'BLandingPage'}   />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={BLandingCss.contentWrapperBWrapper}>
          <h1 style={{ position: 'absolute', left: -999999, opacity: 0 }}>
            <FormattedMessage id="LandingPage.header1" />
          </h1>
          <SectionSearchBanner
            onSelectionPostalAddress={onSelectionPostalAddress}
            currentUser={currentUser}
            onSelectedPrediction={onSelectedPrediction}
            openInsuranceModal={() => setInsuranceModalOpen(true)}
            isMobileLayout={isMobileLayout}
            searchEvents={searchEvents}
          />
          <div
            style={{
              backgroundImage: !isMobileLayout && `url(${imageSource.bLanding.lightBg})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              zIndex: isMobileLayout && !videoModalOpen && 75,
            }}
          >
            <ChannelsListings
              isMobileLayout={isMobileLayout}
              openInsuranceModal={() => setInsuranceModalOpen(true)}
            />
            <GrabBannerDesktop
              currentUser={currentUser}
              isMobileLayout={isMobileLayout}
              onManageDisableScrolling={onManageDisableScrolling}
              inviteSectionAsComponent={inviteSectionAsComponent}
            />
            <CarsListings isMobileLayout={isMobileLayout} />
            <SectionGrids
              onManageDisableScrolling={onManageDisableScrolling}
              onVideoModalOpen={onVideoModalOpen}
              onPushGTMSeeVideoEvent={onPushGTMEvent}
            />
            <SectionTestimonials />
            <SectionHelpBLanding />
            {/* <SeoLandingRentalCategories /> */}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer currentUser={currentUser}  />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
      <InsuranceLandingModal
        onManageDisableScrolling={onManageDisableScrolling}
        isModalOpen={isInsuranceModalOpen}
        setModalVisibility={setInsuranceModalOpen}
      />
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  // history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const { featuredCarIds, fetchFeaturedCarInProgress, fetchFeaturedCarError } = state.SearchPage;
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredCarRefs);
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
    fetchFeaturedCarInProgress,
    fetchFeaturedCarError,
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: flagVal => dispatch(manageTurnoffPromoteBar(flagVal)),
  onSelectedPrediction: val => dispatch(selectedPrediction(val)),
  onSendPostalAddress: (postalCodeObj, sendSuccess, sendFail) =>
    dispatch(sendPostalAddress(postalCodeObj, sendSuccess, sendFail)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = () => {
  return fetchFeaturedBCar();
};

export default LandingPage;