import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { addIntercomNote, sendUserDataToIntercom } from '../util/intercomHelper';
import { storableError } from '../util/errors';
import { transitionsAsCompleted, transitionsToRequested } from '../util/transaction';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import { getPostalDistrict } from '../util/helpers';
import { authInfo } from './Auth.duck';
import {
  checkVerifiedPhone,
  INVALID_PHONE,
  PHONE_VERIFIED,
  sendOTP,
  USED_PHONE,
  verifyIdentifyFromServer,
  verifyOTP,
} from '../util/identityVerificationHelpers';
import { get } from 'lodash';
import { getUserCreditsAndPromos } from '../util/lightrail';
import { sendGAEvent } from '../util/googleAnalytics';
import { loginIntercomUser } from '../util/intercom';
import { adrollRecordUser } from '../util/adroll';
import config from '../config';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { setCurrentUser } from '../util/browserStorageHelper';

const apiUrl = config.apiUrl;

// ================ Action types ================ //
export const UPDATE_MASTERCARD_PROMO_STATUS = 'events/UPDATE_MASTERCARD_PROMO_STATUS';

export const VERIFY_GENERATED_USER_REQUEST = 'app/user/VERIFY_GENERATED_USER_REQUEST';
export const VERIFY_GENERATED_USER_SUCCESS = 'app/user/VERIFY_GENERATED_USER_SUCCESS';
export const VERIFY_GENERATED_USER_ERROR = 'app/user/VERIFY_GENERATED_USER_ERROR';

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';
export const CURRENT_USER_CALENDER = 'app/user/CURRENT_USER_CALENDER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const SEND_MOBILE_CODE_REQUEST = 'app/user/SEND_MOBILE_CODE_REQUEST';
export const SEND_MOBILE_CODE_SUCCESS = 'app/user/SEND_MOBILE_CODE_SUCCESS';
export const SEND_MOBILE_CODE_ERROR = 'app/user/SEND_MOBILE_CODE_ERROR';
export const UPDATE_IDENTITY_ERROR = 'app/user/UPDATE_IDENTITY_ERROR';

export const SEND_POSTAL_CODE_REQUEST = 'app/user/SEND_POSTAL_CODE_REQUEST';
export const SEND_POSTAL_CODE_SUCCESS = 'app/user/SEND_POSTAL_CODE_SUCCESS';
export const SEND_POSTAL_CODE_ERROR = 'app/user/SEND_POSTAL_CODE_ERROR';

export const UPDATE_IDENTITY_REQUEST = 'app/user/UPDATE_IDENTITY_REQUEST';
export const UPDATE_IDENTITY_SUCCESS = 'app/user/UPDATE_IDENTITY_SUCCESS';

export const VERIFY_MOBILE_CODE_SUCCESS = 'app/user/VERIFY_MOBILE_CODE_SUCCESS';

export const OPEN_MISSING_INFOMATION_MODAL = 'app/ListingPage/OPEN_MISSING_INFOMATION_MODAL';

export const SENT_USER_DATA_TO_INTERCOM = 'app/ListingPage/SENT_USER_DATA_TO_INTERCOM';

export const PAYMENT_METHOD_CHANGE_IS_ALLOWED_REQUEST = 'app/ListingPage/PAYMENT_METHOD_CHANGE_IS_ALLOWED_REQUEST';
export const PAYMENT_METHOD_CHANGE_IS_ALLOWED_SUCCESS = 'app/ListingPage/PAYMENT_METHOD_CHANGE_IS_ALLOWED_SUCCESS';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendMobileCodeInProgress: false,
  sendPostalCodeInProgress: false,
  sendVerificationEmailError: null,
  openMissingInfomationModal: false,
  sentUserDataToIntercom: false,
  verifyGeneratedUserInprogress: false,
  verifyGeneratedUserError: null,
  paymentMethodChangeAllowedInprogress: false,
  paymentMethodChangeAllowed: false,
  currentUserCalender: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case VERIFY_GENERATED_USER_REQUEST:
      return { ...state, verifyGeneratedUserInprogress: true, verifyGeneratedUserError: null };
    case VERIFY_GENERATED_USER_SUCCESS:
      return { ...state, verifyGeneratedUserInprogress: false, verifyGeneratedUserError: null };
    case VERIFY_GENERATED_USER_ERROR:
      return {
        ...state,
        verifyGeneratedUserInprogress: false,
        verifyGeneratedUserError: { type: 'error', ...payload },
      };

    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return { ...state, currentUser: mergeCurrentUser(state.currentUser, payload) };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case SEND_MOBILE_CODE_REQUEST:
      return {
        ...state,
        sendMobileCodeInProgress: true,
        sendMobileCodeError: null,
      };
    case SEND_MOBILE_CODE_SUCCESS:
      return {
        ...state,
        sendMobileCodeInProgress: false,
        sendMobileCodeError: null,
      };
    case SEND_MOBILE_CODE_ERROR:
      return {
        ...state,
        sendMobileCodeInProgress: false,
        sendMobileCodeError: payload,
      };
    case SEND_POSTAL_CODE_REQUEST:
      return {
        ...state,
        sendPostalCodeInProgress: true,
        sendMobileCodeError: null,
      };
    case SEND_POSTAL_CODE_SUCCESS:
      return {
        ...state,
        sendPostalCodeInProgress: false,
      };
    case SEND_POSTAL_CODE_ERROR:
      return {
        ...state,
        sendPostalCodeInProgress: false,
        sendMobileCodeError: payload,
      };
    case UPDATE_IDENTITY_REQUEST:
      return {
        ...state,
        updateIdentityError: null,
      };
    case UPDATE_IDENTITY_SUCCESS:
      return {
        ...state,
        updateIdentityError: null,
      };
    case UPDATE_IDENTITY_ERROR:
      return {
        ...state,
        updateIdentityError: payload,
      };
    case VERIFY_MOBILE_CODE_SUCCESS:
      return {
        ...state,
        currentUser: payload,
      };
    case OPEN_MISSING_INFOMATION_MODAL:
      return { ...state, openMissingInfomationModal: payload.status };
    case SENT_USER_DATA_TO_INTERCOM:
      return { ...state, sentUserDataToIntercom: true };
    case CURRENT_USER_CALENDER:
      return {...state, currentUserCalender: payload }

    case PAYMENT_METHOD_CHANGE_IS_ALLOWED_REQUEST:
      return { ...state, paymentMethodChangeAllowedInprogress: true };
    case PAYMENT_METHOD_CHANGE_IS_ALLOWED_SUCCESS:
      return { ...state, paymentMethodChangeAllowedInprogress: false, paymentMethodChangeAllowed: !!payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

export const mobileCodeSendingInProgress = state => {
  return state.user.sendMobileCodeInProgress;
};

export const postalCodeSendingInProgress = state => {
  return state.user.sendPostalCodeInProgress;
};

// ================ Action creators ================ //

export const verifyGeneratedUserRequest = () => ({
  type: VERIFY_GENERATED_USER_REQUEST,
});

export const verifyGeneratedUserSuccess = () => ({
  type: VERIFY_GENERATED_USER_SUCCESS,
});

export const verifyGeneratedUserError = e => ({
  type: VERIFY_GENERATED_USER_ERROR,
  payload: e,
  error: true,
});

export const currentUserCalenderAction = (payload) => {
  return {
    type: CURRENT_USER_CALENDER,
    payload
  }
}

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const sendMobileCodeRequest = () => ({
  type: SEND_MOBILE_CODE_REQUEST,
});

export const sendMobileCodeSuccess = () => ({
  type: SEND_MOBILE_CODE_SUCCESS,
});

export const verifyMobileCodeSuccess = user => ({
  type: VERIFY_MOBILE_CODE_SUCCESS,
  payload: user,
});

export const sendMobileCodeError = e => ({
  type: SEND_MOBILE_CODE_ERROR,
  error: true,
  payload: e,
});

export const sendPostalAddressRequest = () => ({
  type: SEND_POSTAL_CODE_REQUEST,
});

export const sendPostalAddressSuccess = () => ({
  type: SEND_POSTAL_CODE_SUCCESS,
});

export const sendPostalAddressError = e => ({
  type: SEND_POSTAL_CODE_ERROR,
  error: true,
  payload: e,
});

export const updateIdentityRequest = () => ({
  type: UPDATE_IDENTITY_REQUEST,
});

export const updateIdentitySuccess = () => ({
  type: UPDATE_IDENTITY_SUCCESS,
});

export const updateIdentityError = e => ({
  type: UPDATE_IDENTITY_ERROR,
  error: true,
  payload: e,
});

export const paymentMethodChangeIsAllowedRequest = () => ({ type: PAYMENT_METHOD_CHANGE_IS_ALLOWED_REQUEST });
export const paymentMethodChangeIsAllowedSuccess = (payload = false) => ({ type: PAYMENT_METHOD_CHANGE_IS_ALLOWED_SUCCESS, payload });

// ================ Thunks ================ //

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    include: ['images'],
    'limit.images': 1,
    page: 1,
    per_page: 100,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      // dispatch(sendUserDataToIntercomRequest(response.data));

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

const getAllTransactions = async ({ query, sdk }) => {
  let shouldStop = false;
  let page = 1;
  let results = [];

  while (!shouldStop) {
    const finalQuery = {
      ...query,
      per_page: 100,
      page,
    };

    const res = await sdk.transactions.query(finalQuery);
    const { meta, data } = res.data;

    results = results.concat(data);
    page = page + 1;
    shouldStop = page > meta.totalPages;
  }

  return results;
};

export const sendUserDataToIntercomRequest = listingsData => async (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;

  //query current user's transactions
  const transactionsAsProvider = await getAllTransactions({ query: { only: 'sale' }, sdk });
  const transactionsAsCustomer = await getAllTransactions({ query: { only: 'order' }, sdk });

  const transactionsMap = tx => {
    const attributes = tx.attributes;
    return {
      attributes: {
        lastTransition: attributes.lastTransition,
        payinTotal: attributes.payinTotal && attributes.payinTotal.amount,
        payoutTotal: attributes.payinTotal && attributes.payinTotal.amount,
        transitions: attributes.transitions,
        processName: attributes.processName,
        protectedData: attributes.protectedData,
      },
      id: tx.id.uuid,
    };
  };

  sendUserDataToIntercom({
    listingsData,
    currentUser,
    transactionsAsCustomer: transactionsAsCustomer
      .map(transactionsMap)
      .filter(tx => tx.attributes.processName !== 'admin-preauthorized'),
    transactionsAsProvider: transactionsAsProvider
      .map(transactionsMap)
      .filter(tx => tx.attributes.processName !== 'admin-preauthorized'),
  }).then(() => {
    dispatch({
      type: SENT_USER_DATA_TO_INTERCOM,
    });
  });
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser = (callback, params = null, fetchPromo = false) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: [
      'profileImage',
      'stripeAccount',
      'metadata',
      'stripeCustomer.defaultPaymentMethod',
      'stripeCustomer',
    ],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  let currentUser = null;

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      currentUser = entities[0];

      const hostVerified =
        currentUser.attributes.profile.publicData.hostIdentityVerificationStatus === 'confirmed';
      const hostUnderVerify =
        currentUser.attributes.profile.publicData.hostIdentityVerificationStatus === 'pending';
      const guestVerified =
        currentUser.attributes.profile.publicData.guestIdentityVerificationStatus === 'confirmed';
      const guestUnderVerify =
        currentUser.attributes.profile.publicData.guestIdentityVerificationStatus === 'pending';

      const masterVerified = guestVerified || hostVerified;
      const masterUnderVerify = !masterVerified && (guestUnderVerify || hostUnderVerify);
      const canRequestBooking = !config.restrictUserFromBooking
        ? true
        : !!(
            currentUser &&
            currentUser.attributes.profile.privateData &&
            currentUser.attributes.profile.privateData.canRequestBooking === true
          );

      currentUser.identityStatus = {
        hostVerified,
        hostUnderVerify,
        guestVerified,
        guestUnderVerify,
        masterVerified,
        masterUnderVerify,
      };

      currentUser.canRequestBooking = canRequestBooking;

      if (response.data.data.attributes.profile.protectedData.phoneNumber) {
        window.localStorage.setItem('user-verified', 'true')
      } else {
        window.localStorage.removeItem('user-verified')
      }

      if(fetchPromo) {
        return getUserCreditsAndPromos({ contactId: currentUser.id.uuid });
      }
      else {
        return currentUser;
      }
    })
    .then(data => {
      if(fetchPromo) {
        currentUser.attributes.credits = data.credits;
        currentUser.attributes.promos = data.promos;
        currentUser.attributes.creditItem = data.creditItem;
      }
      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      setCurrentUser(currentUser);
      if (callback) {
        callback();
      }

      dispatch(checkPaymentMethodBlocked())

      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      loginIntercomUser(currentUser, process.env.REACT_APP_INTERCOM_APP_ID);

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

export const sendMobileCode = (phoneObj, sendSuccess, sendFail) => (dispatch, getState, sdk) => {
  if (mobileCodeSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification code sending already in progress'));
  }
  dispatch(sendMobileCodeRequest());
  sdk.currentUser
    .show()
    .then(response => {
      const phoneNumber = typeof phoneObj === 'object' ? phoneObj.phoneCode + phoneObj.phoneNumber : phoneObj;
      const userId = response.data.data.id.uuid;
      sendOTP(userId, phoneNumber)
        .then(response => {
          return response.json();
        })
        .then(jsonData => {
          if (jsonData.message === USED_PHONE) {
            const error = {
              type: 'error',
              name: 'Phone used',
              message: 'phoneNumberVerificationForm.usedPhone',
              status: 400,
              statusText: 'Bad request',
            };
            sendFail(error);
            return dispatch(sendMobileCodeError(storableError(error)));
          }
          if (jsonData.message.includes(INVALID_PHONE)) {
            const error = {
              type: 'error',
              name: 'Phone used',
              message: 'phoneNumberVerificationForm.invalidPhoneNumber',
              status: 400,
              statusText: 'Bad request',
            };
            sendFail(error);
            return dispatch(sendMobileCodeError(storableError(error)));
          }
          if (jsonData.message !== 'ok') {
            const error = {
              type: 'error',
              name: 'send_mobile_code_error',
              message: 'phoneNumberVerificationForm.savePhoneNumberError',
              status: 400,
              statusText: 'Bad request',
            };
            sendFail(error);
            return dispatch(sendMobileCodeError(storableError(error)));
          }
          if (jsonData.message === 'Authenticate') {
            const error = {
              type: 'error',
              name: 'send_mobile_code_error',
              message: 'phoneNumberVerificationForm.savePhoneNumberError',
              status: 400,
              statusText: 'Bad request',
            };
            sendFail(error);
            return dispatch(sendMobileCodeError(storableError(error)));
          }
          const verifyPhoneObj = {
            phoneNumber: phoneNumber,
            phoneNumberVerified: false,
          };

          if (typeof phoneObj === 'object') {
            verifyPhoneObj.phoneObj = {
              phoneCode: phoneObj.phoneCode,
              rawNumber: phoneObj.phoneNumber,
            }
          }

          sdk.currentUser.updateProfile({ protectedData: verifyPhoneObj }).then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }
            dispatch(sendMobileCodeSuccess());
            sendSuccess();
            return response;
          });
        });
    })
    .catch(e => {
      const error = {
        type: 'error',
        name: 'send_mobile_code_error',
        message: 'phoneNumberVerificationForm.savePhoneNumberError',
        status: 400,
        statusText: 'Bad request',
      };
      sendFail(error);
      dispatch(sendMobileCodeError(storableError(e)));
    });
};

/**
 * This method returns the postal district based on postalCode
 * @param {string} postalCode
 * @returns {string} postalDistrict
 */
const getPostalDistrictByPostalCode = (postalCode) => {
  if (!postalCode) return '';
  let postalDistrict = '';
  try {
    postalDistrict = getPostalDistrict(postalCode);
  } catch (error) {
    console.error('getPostalDistrictByPostalCode: ', error);
  }
  return postalDistrict;
};

/**
 * This method save the locationProxy in protectedData
 * @param {Object} postalObject
 * @param {Function} sendSuccess
 * @param {Function} sendFail
 * @returns {Promise}
 */
export const sendPostalAddress = (postalObject, sendSuccess, sendFail) => (dispatch, getState, sdk) => {
  if (postalCodeSendingInProgress(getState())) {
    return Promise.reject(new Error('Postal address sending already in progress'));
  }
  dispatch(sendPostalAddressRequest());
  // Check for postalDistrict to store this for this address.
  let postalDistrict = getPostalDistrictByPostalCode(get(postalObject, 'selectedPlace.postalCode'));
  if (postalDistrict && postalObject.selectedPlace) {
    postalObject.selectedPlace.postalDistrict = postalDistrict;
  }
  const protectedData = {};
  if (postalObject && Object.keys(postalObject).length) {
    protectedData.locationProxy = postalObject;
  };
  return sdk.currentUser
    .updateProfile({ protectedData })
    .then(response => {
      dispatch(sendPostalAddressSuccess());
      dispatch(fetchCurrentUser());
    })
    .catch(e => {
      const error = {
        type: 'error',
        name: 'send_postal_code_error',
        message: 'ModalMissingPostalCode.savePostalCodeError',
        status: 400,
        statusText: 'Bad request',
      };
      sendFail(error);
      dispatch(sendPostalAddressError(storableError(e)));
    });
};

export const resendMobileCode = (sendSuccess, sendFail) => (dispatch, getState, sdk) => {
  if (mobileCodeSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification code sending already in progress'));
  }
  dispatch(sendMobileCodeRequest());
  sdk.currentUser
    .show()
    .then(response => {
      const userId = response.data.data.id.uuid;
      const { phoneCode, phoneNumber } = response.data.data.attributes.profile.protectedData;
      const phoneNumberWithCode = `${phoneCode}${phoneNumber}`;
      return sendOTP(userId, phoneNumberWithCode).then((response) => {
        if (response.ok) {
          dispatch(sendMobileCodeSuccess());
          sendSuccess();
        } else {
          throw new Error()
        }
      });
    })
    .catch(e => {
      sendFail(e);
      dispatch(sendMobileCodeError(storableError(e)));
    });
};

export const checkValidCode = (code, verifySuccess, verifyFail) => (dispatch, getState, sdk) => {
  dispatch(sendMobileCodeRequest()); //To prevent user from asking for more code
  sdk.currentUser
    .show()
    .then(response => {
      const userId = response.data.data.id.uuid;
      const { phoneCode, phoneNumber } = response.data.data.attributes.profile.protectedData;
      const phoneNumberWithCode = `${phoneCode}${phoneNumber}`;
      verifyOTP(userId, phoneNumberWithCode, code)
        .then(response => {
          return response.json();
        })
        .then(dataJson => {
          if (dataJson.message === 'token_expired') {
            const error = {
              type: 'error',
              name: 'token_expired',
              message: 'ModalMissingInformation.tokenExpired',
              status: 400,
              statusText: 'Bad request',
            };
            verifyFail(error);
            return dispatch(sendMobileCodeError(storableError(error)));
          }
          if (dataJson.message === 'otp_max_attempts') {
            const error = {
              type: 'error',
              name: 'token_expired',
              message: 'ModalMissingInformation.otpMaxAttemps',
              status: 400,
              statusText: 'Bad request',
            };
            verifyFail(error);
            return dispatch(sendMobileCodeError(storableError(error)));
          }
          if (dataJson.message === 'code_invalid') {
            const error = {
              type: 'error',
              name: 'code_invalid',
              message: 'ModalMissingInformation.codeInvalid',
              status: 400,
              statusText: 'Bad request',
            };
            verifyFail(error);
            return dispatch(sendMobileCodeError(storableError(error)));
          }
          if (dataJson.message === 'phone_number_not_found') {
            const error = {
              type: 'error',
              name: 'phone_number_not_found',
              message: 'ModalMissingInformation.phoneNumberNotFound',
              status: 400,
              statusText: 'Bad request',
            };
            verifyFail(error);
            return dispatch(sendMobileCodeError(storableError(error)));
          }
          if (dataJson.message !== 'code_valid') {
            const error = {
              type: 'error',
              name: 'send_code_error',
              message: 'ModalMissingInformation.sendCodeErrorUnhandle',
              status: 400,
              statusText: 'Bad request',
            };
            verifyFail(error);
            return dispatch(sendMobileCodeError(storableError(error)));
          }
          checkVerifiedPhone(userId, phoneNumberWithCode)
            .then(response => {
              return response.json();
            })
            .then(jsonData => {
              if (jsonData.message === PHONE_VERIFIED) {
                const verifyPhoneObj = {
                  phoneNumber: phoneNumberWithCode,
                  phoneNumberVerified: true,
                };
                sdk.currentUser
                  .updateProfile({ protectedData: verifyPhoneObj })
                  .then(response => {
                    const entities = denormalisedResponseEntities(response);
                    if (entities.length !== 1) {
                      throw new Error(
                        'Expected a resource in the sdk.currentUser.updateProfile response'
                      );
                    }
                    sdk.currentUser
                      .show({
                        include: ['profileImage'],
                        'fields.image': ['variants.square-small', 'variants.square-small2x'],
                      })
                      .then(response => {
                        const userArr = denormalisedResponseEntities(response);
                        if (userArr.length !== 1) {
                          throw new Error(
                            'Expected a resource in the sdk.currentUser.updateProfile response'
                          );
                        }
                        // dispatch(verifyMobileCodeSuccess(userArr[0]));
                        dispatch(fetchCurrentUser(verifySuccess));
                        // verifySuccess();
                      });
                  })
                  .catch(e => {
                    dispatch(sendMobileCodeError(storableError(e)));
                    throw e;
                  });
              } else {
                verifyFail();
              }
            });
          sendGAEvent({
            eventCategory: 'SignUp',
            eventAction: 'Verify Phone Number',
            eventValue: 1,
          });
          dispatch(sendMobileCodeSuccess());
        });
    })
    .catch(e => dispatch(sendMobileCodeError(storableError(e))));
};

export const openMissingInfomationModal = status => ({
  type: OPEN_MISSING_INFOMATION_MODAL,
  payload: { status: status },
});

export const updateIdentity = (userData, type) => (dispatch, getState, sdk) => {
  dispatch(updateIdentityRequest());

  const protectedData = {
    residencyNumber: userData.nricFn,
    bankAccount: userData.bankName,
    bankAccountName: userData.accountName,
    bankAccountNumber: userData.bankAccountNumber,
    bankCountry: userData.bankCountry,
    location: userData.location,
    building: userData.building,
    floorUnit: userData.floorUnit,
    city: userData.city,
    country: userData.country,
    blockNo: userData.blockNo,
    postalCode: userData.postalCode,
    residencyNumberFront: userData.nricFnFrontUrl,
    residencyNumberBack: userData.nricFnBackUrl,
    drivingLicenseFront: userData.drivingLicenseFrontUrl,
    drivingLicenseBack: userData.drivingLicenseBackUrl,
    internationalDrivingLicenseFront: userData.internationalDrivingLicenseFrontUrl,
    internationalDrivingLicenseBack: userData.internationalDrivingLicenseBackUrl,
    frontalPhoto: userData.photo,
  };

  const { currentUser } = getState().user;

  return sdk.currentUser
    .updateProfile({ protectedData })
    .then(() => {
      return verifyIdentifyFromServer({ userId: currentUser.id.uuid, submitType: type === 'HOST' ? 'host' : 'guest' })
    })
    .then(() => {
      dispatch(fetchCurrentUser());
      dispatch(updateIdentitySuccess());
      sendGAEvent({
        eventCategory: 'SignUp',
        eventAction: 'Submit Driver Verification Info',
        eventValue: 2,
      });

      // adrollRecordUser('5a79a5e9');
    })
    .catch((error) => {
      const errorMessage = error.response.data;
      dispatch(updateIdentityError(errorMessage))
    });
};

export const updateIdentityForPayout = (userData, type) => (dispatch, getState, sdk) => {
  dispatch(updateIdentityRequest());

  const protectedData = {
    bankName: userData.bankName,
    bankCountry: userData.bankCountry,
    bankBranchCode: userData.bankBranchCode,
    bankAccountNumber: userData.bankAccountNumber,
  };

  const { currentUser } = getState().user;

  return sdk.currentUser
    .updateProfile({ protectedData })
    .then(() => {
      dispatch(fetchCurrentUser());
      dispatch(updateIdentitySuccess());
    })
    .catch((error) => {
      const errorMessage = error.response.data;
      dispatch(updateIdentityError(errorMessage))
    });
};

export const verifyGeneratedUser = (
  verificationToken,
  mobileVerificationNeeded,
  newProfileData
) => (dispatch, getState, sdk) => {
  const {
    fname,
    lname,
    gender,
    dateOfBirth,
    password: newPassword,
    currentPassword,
  } = newProfileData;
  const profile = {
    firstName: fname,
    lastName: lname,
    protectedData: {
      gender: gender,
      dateOfBirth: dateOfBirth,
    },
  };
  const { currentUser } = getState().user;

  dispatch(verifyGeneratedUserRequest());
  return sdk.currentUser
    .verifyEmail({ verificationToken })
    .then(() => {
      sendGAEvent({
        eventCategory: 'SignUp',
        eventAction: 'Verify Email',
        eventValue: 1,
      });

      // adrollRecordUser('53c1a316');

      return sdk.currentUser.updateProfile(profile);
    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      return sdk.currentUser.changePassword({ newPassword, currentPassword });
    })
    .then(() => {
      const intercomUserId = get(currentUser, 'attributes.profile.privateData.intercomUserId');
      addIntercomNote({
        intercomUserId,
        userEmail: currentUser.attributes.email,
        note: 'Verified email',
      });
    })
    .then(() => {
      dispatch(verifyGeneratedUserSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .then(() => dispatch(openMissingInfomationModal(mobileVerificationNeeded)))
    .catch(e => {
      dispatch(verifyGeneratedUserError(e));
      throw e;
    });
};

export const updateUserData = data => (dispatch, state, sdk) => {

  return sdk.currentUser
    .updateProfile(
      {
        ...data,
      },
      {
        include: ['profileImage', 'stripeAccount'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        expand: true,
      }
    )
    .then(response => {
      const [currentUser] = denormalisedResponseEntities(response);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    });
};

export const updateMastercardPromoStatus = ({ userId, data }) => {
  if (typeof window === 'undefined') return;

  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      eventType: UPDATE_MASTERCARD_PROMO_STATUS,
    }),
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      }
      return response.json();
    })
  ;
};

export const checkPaymentMethodBlocked = () => async (dispatch, getState, sdk) => {
  dispatch(paymentMethodChangeIsAllowedRequest());
  try {
    const response = await sdk.transactions.query(
      {
        only: 'order',
        'fields.transaction': [
          'protectedData.isFuelInclusion',
          'transitions'
        ]
      },
    );
    const transactions = denormalisedResponseEntities(response);
    const transactionBlocker = transactions.filter(t => {
      const {attributes} = t || {};
      // const bookingAccepted = booking && booking.attributes && booking.attributes.state === BOOKING_STATE_ACCEPTED;
      const { transitions, protectedData = {} } = attributes;
      return !!protectedData.isFuelInclusion && !transitions.some(({transition}) => transitionsAsCompleted.includes(transition));
    })
    dispatch(paymentMethodChangeIsAllowedSuccess(!transactionBlocker));
  } catch(e){
    dispatch(paymentMethodChangeIsAllowedSuccess());
  }
};

export const refetchUser = (params) => (dispath , getState, sdk) =>  {
  return sdk.currentUser.show(params);
}


export const updateRefreshToken = (email, refreshToken) => (dispatch , state, sdk) => {
    return sdk.currentUser.show().then((response) => {
      const res = response.data
      const userId = res.data.id.uuid
      const calenderData = {
        assosicatedEmail: email,
        refreshToken: refreshToken,
      }
      if (userId){
        return sdk.currentUser.updateProfile({
          publicData: {
            calenderData
          } })
      }
    }).then(() => {
      dispatch(currentUserCalenderAction({
        email,
        refreshToken
      }))
    })
  ;
}

export const initCurrentUserCalender = () => (dispatch, state, sdk) => {
  return sdk.currentUser.show().then((response) => {
       const userData = response.data.data
       if (userData.attributes && userData.attributes.profile &&
        userData.attributes.profile.publicData &&
        userData.attributes.profile.publicData.calenderData
        ){
          const { assosicatedEmail, refreshToken } = userData.attributes.profile.publicData.calenderData
          if(assosicatedEmail){
            return dispatch(currentUserCalenderAction({
            email: assosicatedEmail,
            refreshToken
          }))
          }
        }
  })
}

export const revokeRefreshToken = () => (dispatch , state, sdk) => {
  return sdk.currentUser.updateProfile({
    publicData: {
      calenderData: {}
    }
  }).then(() => {
    dispatch(currentUserCalenderAction(null))
  })
}
