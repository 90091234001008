import React, { useRef, useState } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { Button, FieldPhoneNumberInput, FieldTextInput, Form } from '../../components';
import css from './RentForm.css';
import moment from 'moment';
import classNames from 'classnames';
import config from '../../config';
import { DEFAULT_TIMEZONE } from '../../util/dates';
import { drivelahApiPost } from '../../util/apiHelper';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { parse } from '../../util/urlHelpers';

const generateTimeOptions = (isToday, limitTime = null) => {
  const now = new Date();
  const currentMinutes = isToday ? now.getHours() * 60 + now.getMinutes() : -1;
  const limitMoment = limitTime ? moment(limitTime, 'hh:mm a') : null;
  const currentTime = limitMoment ? limitMoment.hours() * 60 + limitMoment.minutes() : -1;
  const mustBeLargerThanValue = Math.max(currentMinutes, currentTime);
  return config.custom.timeSetInput.filter(t => t.hour * 60 + t.minutes > mustBeLargerThanValue);
};

const options = [
  { value: '050', label: '0-50' },
  { value: '50100', label: '50-100' },
  { value: '1001000', label: '100-1000' },
];

const checkValidSearchDate = ({ pickUp, pickupTime, dropOff, dropOffTime }) => {
  if (pickUp && pickupTime && dropOff && dropOffTime) {
    const momentPickup = moment(pickupTime, 'hh:mm a');
    const momentDropOff = moment(dropOffTime, 'hh:mm a');
    const start = moment(pickUp.date)
      .startOf('date')
      .hour(momentPickup.hour())
      .minutes(momentPickup.minutes)
      .toDate();
    const end = moment(dropOff.date)
      .startOf('date')
      .hour(momentDropOff.hours())
      .minutes(momentDropOff.minutes())
      .toDate();
    if (end.getTime() > start.getTime()) return undefined;

    return 'Return date must be after Pick-up date';
  }
  return undefined;
};

let setPickUp, setDropOff;
const RentForm = props => {
  const {
    currentUser,
    setFormStatus,
    formStatus,
    intl,
    totalPrice,
    formatMoney,
    listingDetails,
    handleModalClose,
    handleSuccessEvent,
    ...rest
  } = props;

  const [startTimeSet, setStartTimeSet] = useState([]);
  const [endTimeSet, setEndTimeSet] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const prevValues = useRef(null);

  const location = useLocation();
  const { search } = location;
  const { dates, hours, address } = parse(search);
  const datesArray = dates && dates.split(',');
  const hoursArray = hours && hours.split(',');

  if (datesArray && datesArray.length > 0 && hoursArray && hoursArray.length > 0) {
    setPickUp =
      moment(datesArray[0] + ' ' + hoursArray[0])
        .tz(DEFAULT_TIMEZONE)
        .toDate() || null;
    setDropOff =
      moment(datesArray[1] + ' ' + hoursArray[1])
        .tz(DEFAULT_TIMEZONE)
        .toDate() || null;
  }

  const searchDateTimeData = {
    location,
    searchLocation: { search: address },
    pickUp: { date: setPickUp },
    pickupTime: null,
    dropOff: { date: setDropOff },
    dropOffTime: null,
  };

  const [searchDateTime, setSearchDateTime] = useState(searchDateTimeData);

  const handleSubmit = (e, values, error) => {
    setIsLoading(true);
    e.preventDefault();

    const query = 'jiraModule/create-hubspot-contact';
    const ticketTitle = `${values.name} ${values.email} ${values.phone}`;
    const ticketDescription = `Listing Name : ${listingDetails.name}, Listing Flex ID : ${listingDetails.flexId}, Insurance plan selected : ${listingDetails.insurancePlan}, Mileage selected : ${listingDetails.mileage}, Additional driver selected : ${listingDetails.additionalDriver}, Total price shown to the user : ${listingDetails.totalPrice}`;
    const payload = {
      title: ticketTitle,
      phone: values.phone,
      email: values.email,
      name: values.name,
      description: ticketDescription,
      country: 'Singapore',
    };
    try {
      drivelahApiPost(query, payload).then(res => {
        if (res) {
          handleSuccessEvent();
          setTimeout(() => {
            setIsLoading(false);
            handleModalClose(false);
          }, 1000);

        }
      });
    } catch (e) {
      setIsLoading(false);

    }
  };

  return (
    <FinalForm
      {...rest}
      rawInitialValues={[
        { name: 'name', value: '' },
        { name: 'email', value: '' },
        { name: 'phone', value: '' },
      ]}
      render={formRenderProps => {
        const { form, className, values, valid, invalid, rawInitialValues } = formRenderProps;
        const error = valid;
        if (!mounted) {
          form.batch(() => {
            Object.entries(rawInitialValues).forEach(entry => {
              if (
                currentUser &&
                currentUser.attributes &&
                currentUser.attributes.profile &&
                currentUser.attributes.profile.firstName &&
                currentUser.attributes.profile.lastName
              ) {
                form.change(
                  'name',
                  `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
                );
              }
              if (currentUser && currentUser.attributes && currentUser.attributes.email) {
                form.change('email', currentUser.attributes.email);
              }
              if (
                currentUser &&
                currentUser.attributes &&
                currentUser.attributes.profile &&
                currentUser.attributes.profile.protectedData &&
                currentUser.attributes.profile.protectedData.phoneNumber
              ) {
                const getOnlyNumber = currentUser.attributes.profile.protectedData.phoneNumber.substring(
                  3,
                  20
                );
                form.change('phone', getOnlyNumber);
              }
              return form;
            });
          });
          setMounted(true);
        }



        return (
          <Form
            className={classNames(css.root, className)}
            onSubmit={e => handleSubmit(e, values, error)}
          >
            <FormSpy
              subscription={{ values: true, active: true }}
              onChange={({ values, active }) => {}}
            />

            <div className={css.wrapper}>
              <div className={css.fields}>
                <div className={css.row}>
                  <div className={classNames(css.field, css.column12)}>
                    <div
                      className={classNames(
                        css.formInput,
                        !isValid && !values.email && css.inputError
                      )}
                    >
                      <FieldTextInput
                        type="text"
                        id={'name'}
                        name="name"
                        autoComplete="name"
                        label={'Name'}
                        placeholder={'Enter your name'}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.row}>
                  <div className={classNames(css.field, css.column12)}>
                    <div
                      className={classNames(
                        css.formInput,
                        !isValid && !values.email && css.inputError
                      )}
                    >
                      <FieldTextInput
                        type="email"
                        id={'email'}
                        name="email"
                        autoComplete="email"
                        label={'Email'}
                        placeholder={'Enter email address'}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.row}>
                  <div className={classNames(css.field, css.column12)}>
                    <div
                      className={classNames(
                        css.formInput,
                        css.numberInput,
                        !isValid && !values.phone && css.inputError
                      )}
                    >
                      <label class="" for="price-range">
                        Contact Number
                      </label>
                      <span className={css.code}>+65</span>
                      <span className={css.numberInput}>
                        <FieldPhoneNumberInput
                          placeholder={'Enter phone number'}
                          name="phone"
                          inputClassName={css.contactField}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                {error ? <div className={css.errorDesktop}>{error}</div> : null}
              </div>
            </div>

            <div className={css.buttonWrapper}>
              {formStatus.success ? <p>{formStatus && formStatus.message}</p> : null}
              <div className={css.fixedBottomBtn}>
                <div className={css.monthWisePrice}>
                  <span className={css.monthlyPrice}>
                    {totalPrice && formatMoney(intl, totalPrice, 1)}
                    <span>/month</span>
                  </span>
                </div>
                <Button
                  className={classNames(
                    css.button,
                    !values.name || !values.email || !values.phone ? css.disabled : ''
                  )}
                  type="submit"
                >
                  {isLoading ? (
                    <div className={css.locationLoader}>
                      <span></span>
                    </div>
                  ) : (
                    'Submit'
                  )}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};
export default connect(mapStateToProps)(RentForm);
