import React, { Component, Fragment } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';
import { intlShape } from '../../util/reactIntl';
import {
  dateIsAfter,
  getEndHours,
  getMonthStartInTimeZone,
  getStartHours,
  isDayMomentInsideRange,
  isInRange,
  isSameDate,
  monthIdStringInTimeZone,
  nextMonthFn,
  prevMonthFn,
  resetToStartOfDay,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  timestampToDate,
} from '../../util/dates';
import { propTypes } from '../../util/types';
import { bookingDateRequired } from '../../util/validators';
import { FieldDateInput, FieldSelect } from '../../components';
import moment from 'moment';

import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import OutsideClickHandler from './OutsideClickHandler';
import css from './FieldDateAndTimeInput.css';
import { uniqBy } from 'lodash';
import config from '../../config';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const MAX_TIME_SLOTS_RANGE = 360;
const TODAY = new Date();
let availableEndTimes = [];
const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

const getAvailableStartTimes = (
  intl,
  timeZone,
  bookingStart,
  timeSlotsOnSelectedDate,
  localTimeZone
) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    return [];
  }
  const bookingStartDate = resetToStartOfDay(bookingStart, localTimeZone);

  const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
    const startDate = t.attributes.start;
    const endDate = t.attributes.end;
    const nextDate = resetToStartOfDay(bookingStartDate, localTimeZone, 1);

    // If the start date is after timeslot start, use the start date.
    // Otherwise use the timeslot start time.
    let startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;
    startLimit = new Date().getTime() > startLimit.getTime() ? new Date() : startLimit;
    // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;
    const hours = getStartHours(intl, localTimeZone, startLimit, endLimit);
    return availableHours.concat(hours);
  }, []);
  return uniqBy(allHours, i => i.timestamp);
};

const getAvailableEndTimes = (
  intl,
  timeZone,
  bookingStartTime,
  bookingEndDate,
  selectedTimeSlot,
  localTimeZone
) => {
  if (!selectedTimeSlot || !selectedTimeSlot.attributes || !bookingEndDate || !bookingStartTime) {
    return [];
  }

  const firstDateOnSelectedTimeSlot = selectedTimeSlot.attributes.start;
  const sameDate = moment(firstDateOnSelectedTimeSlot).isSame(moment(bookingEndDate), 'day')

  const endDate = selectedTimeSlot.attributes.end;
  const bookingStartTimeAsDate = timestampToDate(bookingStartTime);
  const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, localTimeZone, 1);
  const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, localTimeZone, 1);
  const startOfEndDay = sameDate ? selectedTimeSlot.attributes.start : resetToStartOfDay(bookingEndDate, localTimeZone);
  // const startOfEndDay = selectedTimeSlot.attributes.start;

  const selectedTimeSlotStart = (selectedTimeSlot &&
    selectedTimeSlot.attributes &&
    selectedTimeSlot.attributes.start) || undefined
  const selectedTimeSlotEnd = (selectedTimeSlot &&
    selectedTimeSlot.attributes &&
    selectedTimeSlot.attributes.end) || undefined

  let startLimit;
  let endLimit;
  if (!dateIsAfter(startOfEndDay, bookingStartTimeAsDate)) {
    startLimit = bookingStartTimeAsDate;
    endLimit = dateIsAfter(dayAfterBookingStart, endDate) ? endDate : dayAfterBookingStart;
  } else {
    // If the end date is on the same day as the selected booking start time
    // use the start time as limit. Otherwise use the start of the selected end date.
    startLimit = dateIsAfter(bookingStartTimeAsDate, startOfEndDay)
      ? bookingStartTimeAsDate
      : startOfEndDay;

    // If the selected end date is on the same day as timeslot end, use the timeslot end.
    // Else use the start of the next day after selected date.
    endLimit = isSameDate(resetToStartOfDay(endDate, localTimeZone), startOfEndDay)
      ? endDate
      : dayAfterBookingEnd;
  }
  let hours = uniqBy(getEndHours(intl, localTimeZone, startLimit, endLimit), i => i.timestamp);
  if (selectedTimeSlotStart && selectedTimeSlotEnd){
    const inRange = (start, end, target) => {
      return target.isSameOrAfter(start) && target.isSameOrBefore(end)
    }
    hours = hours.filter((hour) => {
      return inRange(selectedTimeSlotStart, selectedTimeSlotEnd, moment(hour.timestamp))
    })
  }
  return hours;
};

const getAvailableEndTimesForUpdateBooking = (
  intl,
  timeZone,
  bookingEndTime,
  bookingStartTime,
  bookingEndDate,
  bookingStart,
  timeSlotsOnSelectedDate,
  localTimeZone,
  initialEndDate
) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart || !initialEndDate) {
    return [];
  }

  const bookingCurrentEndDate = resetToStartOfDay(bookingEndDate, localTimeZone);
  const bookingEndTimeAsDate = initialEndDate.date;
  const endOfEndDay = resetToStartOfDay(bookingEndDate, localTimeZone);


  const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
    const endDate = t.attributes.end;
    const nextDate = resetToStartOfDay(bookingCurrentEndDate, localTimeZone, 1);

    let startLimit;
    if (!dateIsAfter(endOfEndDay, bookingEndTimeAsDate)) {
      startLimit = bookingEndTimeAsDate;
    } else {
      // If the end date is on the same day as the selected booking start time
      // use the start time as limit. Otherwise use the start of the selected end date.
      startLimit = dateIsAfter(bookingEndTimeAsDate, endOfEndDay)
        ? bookingEndTimeAsDate
        : endOfEndDay;
    }

    // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;
    const hours = getStartHours(intl, localTimeZone, startLimit, endLimit);
    return availableHours.concat(hours);
  }, []);

  return uniqBy(allHours, i => i.timestamp);
};

const getTimeSlots = (timeSlots, date, timeZone) => {
  return timeSlots && timeSlots[0]
    ? timeSlots.filter(t => isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone))
    : [];
};

const getAllSelectedTimeSlots = (monthlyTimeSlots, firstSelectedSlot, timeZone, localTimeZone) => {
  if (!firstSelectedSlot) {
    return [];
  }

  const currentMonth = getMonthStartInTimeZone(TODAY, localTimeZone)
  const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, currentMonth, timeZone);

  let selectedSlots = [firstSelectedSlot];
  let currentEndSlot = moment(firstSelectedSlot.attributes.end)
    .add(1, 'millisecond')
    .toDate();

  let slot = undefined;
  do {
    slot = timeSlots.find(t => isInRange(currentEndSlot, t.attributes.start, t.attributes.end));
    if (slot) {
      selectedSlots = selectedSlots.concat(slot);
      currentEndSlot = moment(slot.attributes.end).add(1, 'millisecond');
    }
  } while (slot);

  return selectedSlots;
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeSlotsOnSelectedDate,
  startDate,
  localTimeZone,
  selectedStartTime,
  selectedEndDate,
  timeSlots
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
      intl,
      timeZone,
      startDate,
      getTimeSlots(timeSlotsOnSelectedDate, startDate, timeZone),
      localTimeZone
    );

  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
      ? startTimes[0].timestamp
      : null;

  const firstDateOnSelectedTimeSlot = timeSlotsOnSelectedDate.length > 0 ? timeSlotsOnSelectedDate[0].attributes.start : null;
  const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

  let startHours;
  let startMinutes;

  // Note: if some timeSlots on the day is booked, find time of the first available
  if(timeSlotsOnSelectedDate.length > 0 && moment(firstDateOnSelectedTimeSlot).isSame(moment(selectedEndDate), 'day')) {
    startHours = firstDateOnSelectedTimeSlot.getHours();
    startMinutes = firstDateOnSelectedTimeSlot.getMinutes();
  } else {
    startHours = 0;
    startMinutes = 0;
  }

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? timestampToDate(selectedEndDate.setHours(startHours, startMinutes, 0, 0))
      : null;

  let selectedTimeSlot;

  if (startTimeAsDate && moment(startTimeAsDate).isSame(moment(endDate), 'day')) {
    selectedTimeSlot = timeSlotsOnSelectedDate.find(t => {
        return isInRange(startTimeAsDate, t.attributes.start, t.attributes.end);
      }
    );
  } else {
    selectedTimeSlot = timeSlotsOnSelectedDate.find(t => {
        return isInRange(endDate, t.attributes.start, t.attributes.end);
      }
    );
  }

  const selectedTimeSlots = getAllSelectedTimeSlots(timeSlots, selectedTimeSlot, timeZone, localTimeZone);
  const endTimes = getAvailableEndTimes(
    intl,
    timeZone,
    startTime,
    endDate,
    selectedTimeSlot,
    localTimeZone
  );

  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp ? endTimes[0].timestamp : null;

  return { startTime, endDate, endTime, selectedTimeSlot, selectedTimeSlots };
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
      ? monthlyTimeSlots[monthId].timeSlots
      : [];
};

const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
};
const Prev = props => {
  const { currentMonth, timeZone, localTimeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, localTimeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, localTimeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

/////////////////////////////////////
// FieldEndDateAndTimeInput component //
/////////////////////////////////////
class FieldEndDateAndTimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getMonthStartInTimeZone(TODAY, props.localTimeZone),
      ifDropOffChanged: true,
      endDate: false,
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.onBookingStartDateChange = this.onBookingStartDateChange.bind(this);
    this.onBookingStartTimeChange = this.onBookingStartTimeChange.bind(this);
    this.onBookingEndDateChange = this.onBookingEndDateChange.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
    this.triggerAnalyticsEvent = this.triggerAnalyticsEvent.bind(this);
  }

  componentDidMount() {
    const { localTimeZone, updateBooking, location, initialValues } = this.props;
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const endDate = params && params.dates ? moment(params.dates.substring(11)).toDate() : null;
    const startDate = params && params.dates ? moment(params.dates.substring(0, params.dates.length - 11)).toDate() : null;

    if(endDate) {
      this.setState({ currentMonth: getMonthStartInTimeZone(endDate, localTimeZone) });
      this.fetchMonthData(endDate);
      this.fetchMonthData(startDate);
    }

    if(updateBooking) {
      this.setState({ currentMonth: getMonthStartInTimeZone(initialValues.bookingEndDate.date, localTimeZone) })
      this.fetchMonthData(getMonthStartInTimeZone(initialValues.bookingEndDate.date, localTimeZone));
    }
  }

  triggerAnalyticsEvent() {
    if(this.props.gtmEvents) {
      triggerAnalyticsEvent({ 
        event_id: event_trigger_ids.SEARCH_OPEN_CALENDAR, 
        eventData: this.props.gtmEvents, 
        props: {
          listing : this.props.listing,
          host: this.props.listing ? this.props.listing.author: null,
          guest: this.props.currentUser,
          ui: {
            button: 'Open Date',
            page: 'BookingDetailsPage',
          }
        },
        userId: this.props.currentUser ? this.props.currentUser.id.uuid : null
      });
      // triggerAnalyticsEvent({
      //   event_id: 'search_open_calendar',
      //   eventData: this.props.gtmEvents, 
      //   props: {  ...this.props }
      // });
    }
  }

  fetchMonthData(date) {
    const { listingId, timeZone, onFetchTimeSlots } = this.props;
    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onMonthClick(monthFn) {
    const { onMonthChanged, timeZone, localTimeZone } = this.props;

    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth, localTimeZone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(this.state.currentMonth, timeZone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);

        const currentMonthData = this.props.monthlyTimeSlots[monthId];
        if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
          this.fetchMonthData(this.state.currentMonth, timeZone);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  onBookingStartDateChange = value => {
    const { monthlyTimeSlots, timeZone, intl, form, localTimeZone } = this.props;
    if (!value || !value.date) {
      form.batch(() => {
        form.change('bookingStartTime', null);
        form.change('bookingEndDate', null);
        form.change('bookingEndTime', null);
      });
      // Reset the currentMonth too if bookingStartDate is cleared
      this.setState({ currentMonth: getMonthStartInTimeZone(TODAY, localTimeZone) });

      return;
    }

    // this.setState({ currentMonth: getMonthStartInTimeZone(TODAY, localTimeZone) });

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const startDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { startTime, endDate, endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      localTimeZone
    );

    form.batch(() => {
      // form.change('bookingStartTime', startTime);
      // form.change('bookingEndDate', { date: endDate });
      // form.change('bookingEndTime', endTime);
    });
    this.setState({
      ifDropOffChanged: false,
      currentMonth: getMonthStartInTimeZone(startDate, localTimeZone)
    });
  };

  onBookingStartTimeChange = value => {
    const { form } = this.props;

    form.batch(() => {
      // form.change('bookingEndDate', null);
      // form.change('bookingEndTime', null);
    });
  };

  onBookingEndDateChange = value => {
    const { form, values, timeZone } = this.props;
    const prevDropOff =
      values.bookingEndDate && values.bookingEndDate.date ? values.bookingEndDate.date : null;
    const newDropOff = value && value.date;
    const isSameDate = moment(newDropOff).isSame(prevDropOff, 'day');
    if (!isSameDate) {
      this.setState({
        ifDropOffChanged: true,
      });
    } else {
      this.setState({
        ifDropOffChanged: false,
      });
    }
    if (!value || !value.date) {
      // form.change('bookingEndTime', null);
      return;
    }

    const endDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);
    
    this.setState({endDate})

    // form.change('bookingEndTime', null);
  };

  onBookingEndDateBlur = () => {
    console.log("Booking ")
  
    const { localTimeZone, location, initialValues } = this.props;
    // Reset the currentMonth too if click outside the calendar
    const { state } = location;

    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const endDate =  params && params.dates ? moment(params.dates.substring(11)).toDate() : false;
    if((state && state.endDate && !this.state.endDate) || (endDate && !this.state.endDate)) {
      if(state){
        this.setState({ currentMonth: getMonthStartInTimeZone(location.state.endDate, localTimeZone) });
      } else {
        this.setState({ currentMonth: getMonthStartInTimeZone(endDate, localTimeZone) });
      }
    }
    if((!state || state && !state.endDate) && !this.state.endDate && !endDate && !initialValues.bookingEndDate){
      this.setState({ currentMonth: new Date()});
    }
    if(this.state.endDate || initialValues.bookingEndDate) {
      if(this.state.endDate) {
        this.setState({ currentMonth: getMonthStartInTimeZone(this.state.endDate, localTimeZone) });
      }
      if(initialValues.bookingEndDate && !this.state.endDate){
        this.setState({ currentMonth: getMonthStartInTimeZone(initialValues.bookingEndDate.date, localTimeZone) });
      }

    }
  }

  isOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone) {
    if (!selectedTimeSlot) {
      return true;
    }

    // 'day' is pointing to browser's local time-zone (react-dates gives these).
    // However, bookingStartDate and selectedTimeSlot refer to times in listing's timeZone.
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);

    // Given day (endDate) should be after the start of the day of selected booking start date.
    const startDate = resetToStartOfDay(bookingStartDate, timeZone);
    // 00:00 would return wrong day as the end date.
    // Removing 1 millisecond, solves the exclusivity issue.
    const inclusiveEnd = new Date(selectedTimeSlot.attributes.end.getTime() - 1);
    // Given day (endDate) should be before the "next" day of selected timeSlots end.
    const endDate = resetToStartOfDay(inclusiveEnd, timeZone, 1);
    return (
      !(dateIsAfter(localizedDay, startDate) && dateIsAfter(endDate, localizedDay)) ||
      this.props.isOutsideRange(localizedDay)
    );
  }

  isHourlyOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone){
    if (!selectedTimeSlot) {
      return true;
    }

    const availableDates = [];
    availableDates.push(moment(bookingStartDate).format('YYYY-MM-DD'));
    return !availableDates.some(date => moment(day).format('YYYY-MM-DD') === date);
  }

  startDateIsOutsizeRange = (day, timeZone) => {
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);

    return this.props.isOutsideRange(localizedDay);
  };

  render() {
    const {
      rootClassName,
      className,
      formId,
      startDateInputProps,
      endDateInputProps,
      values,
      timeZone,
      intl,
      timeSlots,
      monthlyTimeSlots,
      localTimeZone,
      hourlyAvailability,
      updateBooking,
      initialValues,
      transaction
    } = this.props;


    const classes = classNames(rootClassName || css.root, className);
    const bookingStartDate =
      values.bookingStartDate && values.bookingStartDate.date ? values.bookingStartDate.date : null;
    const bookingStartTime = values.bookingStartTime ? values.bookingStartTime : null;
    const bookingEndTime = values.bookingEndTime ? values.bookingEndTime : null;
    const bookingEndDate =
      values.bookingEndDate && values.bookingEndDate.date ? values.bookingEndDate.date : null;


    const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      monthlyTimeSlots,
      this.state.currentMonth,
      localTimeZone
    );

    const timeSlotsOnSelectedDate = getTimeSlots(
      timeSlotsOnSelectedMonth,
      bookingEndDate,
      localTimeZone
    );

    const availableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      bookingStartDate,
      timeSlotsOnSelectedDate,
      localTimeZone
    );

    const firstAvailableStartTime =
      availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
        ? availableStartTimes[0].timestamp
        : null;

    const { startTime, endDate, selectedTimeSlot, selectedTimeSlots } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      bookingStartDate,
      localTimeZone,
      bookingStartTime || firstAvailableStartTime,
      bookingEndDate || bookingStartDate,
      monthlyTimeSlots,
      updateBooking
    );

    availableEndTimes = getAvailableEndTimes(
      intl,
      timeZone,
      bookingStartTime,
      bookingEndDate,
      selectedTimeSlot,
      localTimeZone,
      updateBooking
    );

    let availableEndTimesForUpdateBooking = getAvailableEndTimesForUpdateBooking(
      intl,
      timeZone,
      bookingEndTime,
      bookingStartTime,
      bookingEndDate,
      bookingStartDate,
      timeSlotsOnSelectedDate,
      localTimeZone,
      initialValues && initialValues.bookingEndDate
    );

    availableEndTimes = updateBooking ? config.custom.times : availableEndTimes;

    const isDayBlocked = timeSlotsOnSelectedMonth && !updateBooking
      ? day =>
        !timeSlotsOnSelectedMonth.find(timeSlot => {
            return isDayMomentInsideRange(
              day,
              timeSlot.attributes.start,
              timeSlot.attributes.end,
              timeZone
            )
          }
        )
      : () => false;

    const placeholderTime = '00:00';
    const today = moment();
    const bookedEndDate = transaction && transaction.booking && transaction.booking.attributes ? transaction.booking.attributes.displayEnd : null;
    const beforeBookedEnd = bookedEndDate ? moment(bookedEndDate, localTimeZone) : null;
    const diffHours = beforeBookedEnd ? beforeBookedEnd.diff(today, 'hours', true) : 0;
    const disableUpdate = diffHours < 2;
    const endDateDisabled = !bookingStartDate || (!updateBooking && !bookingStartTime) || (updateBooking && disableUpdate);
    const endTimeDisabled = !bookingEndDate || (updateBooking && disableUpdate);

    const endTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.endTime' });
    /**
     * NOTE: In this template the field for the end date is hidden by default.
     * If you want to enable longer booking periods, showing the end date in the form requires some code changes:
     * 1. Move the bookingStartTime field to the same formRow with the bookingStartDate field
     * 2. Remove the div containing the line between dates
     * 3. Remove the css related to hiding the booking end date from the bottom of the FieldEndDateAndTimeInput.css field
     */

    return (
      <div className={classes}>
        <OutsideClickHandler onClick={this.onBookingEndDateBlur}>
          <div className={updateBooking ? classNames(css.updateBookingFormRow, css.endDate) : classNames(css.formRow, css.endDate)}>
            <div className={classNames(css.field)}>

              <FieldDateInput
                {...endDateInputProps}
                labelClassName={css.label}
                name="bookingEndDate"
                id={formId ? `${formId}.bookingEndDate` : 'bookingEndDate'}
                className={css.fieldDateInput}
                label={endDateInputProps.label}
                placeholderText={endDateInputProps.placeholderText}
                format={v =>
                  v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
                }
                parse={v =>
                  v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
                }
                disabled={endDateDisabled}
                isDayBlocked={isDayBlocked}
                onChange={this.onBookingEndDateChange}
                onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
                onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
                navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                navPrev={<Prev localTimeZone={localTimeZone} currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                useMobileMargins
                showErrorMessage={false}
                showLabelAsDisabled={endDateDisabled}
                validate={bookingDateRequired('Required')}
                triggerAnalyticsEvent={() => this.triggerAnalyticsEvent()}
              />

            </div>

            <div className={updateBooking ? classNames(css.lineBetween, css.updateBookingLineBetween) : bookingStartDate ? css.lineBetween : css.lineBetweenDisabled}>-</div>

            <div className={updateBooking ? css.updateBookingField : css.field}>
              <FieldSelect
                labelClassName={css.label}
                name="bookingEndTime"
                id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
                className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
                selectClassName={bookingStartDate ? css.select : css.selectDisabled}
                label={endTimeLabel}
                disabled={endTimeDisabled}
              >
                {bookingEndDate ? (
                  <Fragment>
                    <option value="">{initialValues.bookingEndDate && initialValues.bookingEndDate.date && !this.state.endDate ? moment(initialValues.bookingEndDate.date).format('hh:mm a') : '00:00'}</option>
                    {availableEndTimes.map(p => (
                      <option
                        key={`bookingEndTime_${p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}`}
                        value={p.timestamp}
                      >
                        {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                      </option>
                    ))}
                  </Fragment>
                ) : (
                  <option>{placeholderTime}</option>
                )}
              </FieldSelect>
            </div>
          </div>
        </OutsideClickHandler>
        {/*<p className={css.error}>*/}
        {/*  {updateBooking && availableEndTimes.length === 0 && <span>*/}
        {/*    Unfortunately no drop off time is available for booking*/}
        {/*  </span>}*/}
        {/*</p>*/}
      </div>
    );
  }
}

FieldEndDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  timeZone: null,
  isOutsideRange: () => false,
};

FieldEndDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  bookingStartLabel: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,
  isOutsideRange: func,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default FieldEndDateAndTimeInput;
