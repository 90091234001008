import React, { useEffect, useRef, useState } from 'react';
import css from './UserVerificationWithOnfidoForms.css';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { updateUserData } from '../../ducks/user.duck';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureCurrentUser } from '../../util/data';
import {
  AddressDetailsForm,
  EmailVerificationFormAccountSettings,
  LOSForm,
  PersonalDetailsForm,
  PhoneNumberForm,
  UserResidencyForm,
} from '../../forms';
import dynamic from 'next/dynamic';
import { saveContactDetails } from './PhoneNumber.duck';
import {
  createRawPropertiesForGTM,
  getEventsByBucket
} from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { saveAddressDetails, saveUpdateUserDetails } from './AddressDetailsPage.duck';

import { EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS } from '../../util/conversions/conversionsConstants';
import { savePersonalDetails } from '../../containers/PersonalDetailsPage/PersonalDetailsPage.duck';

import { checkAndCreateOnfidoToken, updateFlexUserCheckId } from '../../util/onfidoHelpers';
import { Button } from '..';
import { verifyIdentifyFromServer } from '../../util/identityVerificationHelpers';
import { getActiveTrips, updateTransactionVerification } from '../../containers/TransactionPage/TransactionPage.duck';
import { TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT,TRANSITION_UNVERIFIED_VERIFICATION_SENT } from '../../util/transaction';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';
// import {
//   initializeOnfidoDriverVerification,
//   initializeOnfidoNricFin,
// } from '../OnfidoHelpers/OnfidoModalInitiator';

const { LatLng } = sdkTypes;

let initializeOnfido = null;

const DynamicHeader = dynamic(
  () =>
    import('../OnfidoHelpers/OnfidoModalInitiator').then(comp => {
      initializeOnfido = comp;
    }),
  {}
);

const JustToModifyStep = ({ modifyStep, handleSteps, step, title }) => {
  React.useEffect(() => {
    modifyStep && handleSteps(step, title);
  }, []);
  return <></>;
};

const UserVerificationWithOnfidoFormsComponent = props => {
  const {
    currentUser,
    onUpdateUserData,
    onGetActiveTrips,
    onUpdateTransition,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
    onChange,
    onSubmitContactDetails,
    onSumitUserUpdateDetails,
    intl,
    onSubmitAddressDetailsForm,
    onSubmitPersonalDetailsForm,
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    savePersonalDetailsInProgress,
    savePersonalDetailsSuccess,
    savePersonalDetailsError,
    saveUpdateUserSuccess,
    saveUpdateUserError,
    saveUpdateUserInProgress,
    transactionId,
    haveNextTransit,
    nextInteractableTransitions,
    transaction,
    onTransit,
    isHost,
    userLocation,
    phoneNumberVerified,
    isEmailVerified,
    dateOfBirth,
    handleSteps,
    fetchCurrentUser,
    isSubmittedOnfidoNricVerification,
    isSubmittedOnfidoDriverVerification,
    isResidencyStatus,
    residencyStatus,
    flexLosDocumentUrl,
    pageName,
    selectVerificationProcess,
    key
  } = props;

  const user = ensureCurrentUser(currentUser);
  const userId = get(currentUser, 'id.uuid', null);
  const userPhoneNumber = get(currentUser, 'attributes.profile.protectedData.phoneNumber', null);
  const userOnfidoApplicantId = get(
    currentUser,
    'attributes.profile.protectedData.onfidoUserID',
    null
  );
  const modalReference = useRef(null);
  const modalReferenceDriver  = useRef(null)
  const [onfidoApplicatnId, setOnfidoApplicantId] = useState(userOnfidoApplicantId);
  const [options, setOptions] = useState(null);
  const [onfidoInstance, setOnfidoInstance] = useState(null);

  const [losDocumentUrl, setlosDocumentUrl] = useState(flexLosDocumentUrl);
  const [onfidoInProgress, setOnfidoInProgress] = useState(false);

  const protectedData = user.attributes.profile.protectedData || {};
  const userEmail = user.attributes.email;
  const currentPhoneNumber = protectedData.phoneObj ? protectedData.phoneObj.rawNumber : '';
  const currentPhoneCode = protectedData.phoneObj ? protectedData.phoneObj.phoneCode : '';
  const { location, floorUnit, city, state, postalCode } = protectedData;
  const { selectedPlace } = location || {};
  const { address, origin } = selectedPlace || {};
  const initialValues = {
    location: {
      search: address,
      selectedPlace: {
        address,
        origin: new LatLng(origin && origin.lat, origin && origin.lng),
      },
    },
    floorUnit,
    city,
    state,
    postalCode,
  };

  useEffect(() => {
    if(window) {
       window.scrollTo(0, 0)
     }
  }, [])

  const dobInitialValues = {
    dateOfBirth,
  };

  const [addressVisible, setaddressVisible] = useState(false);
  const [phoneVisible, setphoneVisible] = useState(true);
  const [verifyIdVisible, setverifyIdVisible] = useState(false);
  const [Disable, setDisable] = useState(true);

  let EnableIDVerify = true;

  const _createCheckAndUpdateUserData = async (
    reportNames,
    onfidoApplicantUniqueId,
    isLastStep = false,
    isNricCheck = false,
    isDriverVerificationCheck = false
  ) => {
    try {
      let applicantId = onfidoApplicantUniqueId;

      if (applicantId) {
        const res = await checkAndCreateOnfidoToken(currentUser);
        if (res) {
          const { onfidoUserId, token } = res || {};
          // Create check
          let updateUserData = await updateFlexUserCheckId({
            reportNames,
            onfidoApplicantId: onfidoUserId,
            isHost,
            isNricCheck,
            isDriverVerificationCheck,
          });
          try {
            if(isHost) {
              const response = await getEventsByBucket('hostverify');
              triggerAnalyticsEvent({
                event_id: event_trigger_ids.HOSTVERIFY_COMPLETE_ONFIDO,
                eventData: response.data,
                props: {
                   guest: currentUser,
                   ui: {
                      page: 'IdVerificationScreen',
                      button: "Next"
                   }
                },
                userId,
              });
            }
            else {
              const response = await getEventsByBucket('guestverify');
              triggerAnalyticsEvent({
                event_id: event_trigger_ids.GUEST_VERIFY_COMPLETE_ONFIDO,
                eventData: response.data,
                props: {
                  guest:currentUser,
                  ui: {
                    page: 'IdVerificationScreen',
                    button: "Next"
                  }
                },
                userId,
              });
            }
          } catch (error) {
            console.log("Error while trigging event", error);
          }
         
          if (isLastStep) {
            updateUserData.publicData.guestIdentityVerificationStatus = 'pending';
            updateUserData.publicData.guestVerificationSent =  new Date().toISOString();
          }

          await onSumitUserUpdateDetails(updateUserData);
          if (isLastStep) {
            if (isHost) {
              // 
              verifyIdentifyFromServer({
                userId,
                submitType: 'host',
                verificationType: 'manual',
              });
            } else {
              try {
                const response = await getEventsByBucket('guestverify');
                triggerAnalyticsEvent({
                  event_id: event_trigger_ids.GUEST_VERIFY_SUBMIT_FORM,
                  isBackendApiCall: true,
                  eventData: response.data,
                  props: {
                    guest: currentUser,
                    ui: {
                      page: 'IdVerificationScreen',
                      button: "Submit"
                    }
                  },
                  userId,
                });
              } catch (error) {
                console.log("Error while trigging event", error);
              }
              verifyIdentifyFromServer({
                userId,
                submitType: 'guest',
                verificationType: 'manual',
              });
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    setOnfidoInProgress(false);
  };

  const transactionData = {
    transaction,
    transactionId,
    nextInteractableTransitions,
    haveNextTransit,
    onTransit,
    isHost,
  };

  const _setOnfidoHeader = val => {
    setOnfidoInProgress(val);
  }

  const _handleInitiateNricFinVerification = async () => {
    try {
      setOnfidoInProgress(true);
      const userId = await get(currentUser, 'id.uuid');

      const onfidoUserRes = await checkAndCreateOnfidoToken(currentUser);
      const { onfidoUserId, token } = onfidoUserRes || {};

      setOnfidoApplicantId(onfidoUserId);

      initializeOnfido.initializeOnfidoNricFin(
        token,
        currentUser,
        modalReference,
        onfidoUserId,
        _createCheckAndUpdateUserData,
        false,
        _setOnfidoHeader
      );
    } catch (err) {}
  };

  const _handleInitiateDriverVerification = async () => {
    try {
      setOnfidoInProgress(true);
      const userId = await get(currentUser, 'id.uuid');

      const onfidoUserRes = await checkAndCreateOnfidoToken(currentUser);
      const { onfidoUserId, token } = onfidoUserRes || {};

      setOnfidoApplicantId(onfidoUserId);
      updateTransitionAfterVerification()
      initializeOnfido.initializeOnfidoDriverVerification(
        token,
        currentUser,
        modalReferenceDriver,
        onfidoUserId,
        _createCheckAndUpdateUserData,
        residencyStatus && residencyStatus === 'Dependent Pass' ? false : true,
        _setOnfidoHeader
      );
    } catch (err) {}
  };

  const updateTransitionAfterVerification = async () => {
    const result = await onGetActiveTrips({ page: 1 });

    if (result.trips.length > 0) {
      for (let t of result.trips) {
        const isInstant =
          t &&
          t.listing &&
          t.listing.attributes &&
          t.listing.attributes.publicData &&
          t.listing.attributes.publicData.instantBooking;
        let transition = '';

        transition = isInstant
          ? TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT
          : TRANSITION_UNVERIFIED_VERIFICATION_SENT;

        let data = {
          transactionId: t.id.uuid,
          transition: transition,
        };

        await onUpdateTransition(data);
      }
    }
  };

  return (
    <>
      <div className={css.root} style={{ zIndex: 999 }} key={key}>
      {(!isHost &&
          userId &&
          !isSubmittedOnfidoNricVerification) ||
        (isHost && phoneNumberVerified && isEmailVerified && location && dateOfBirth &&
          !isSubmittedOnfidoNricVerification) ? (
          <>
            <DynamicHeader />
            <JustToModifyStep
              modifyStep={true}
              handleSteps={handleSteps}
              step={isHost ? 5 : 1}
              title={'Identity verification'}
            />
            <div className={css.identityVerificationGuide}>
              <p className={css.identityVerificationGuideText}>
                <FormattedMessage id="identityVerification.guideLinet1" />
                <br></br>
              </p>
            </div>
            <div
              className={css.verificationModalButton}
              onClick={_handleInitiateNricFinVerification}
            >
              <Button
                type="submit"
                inProgress={saveUpdateUserInProgress || onfidoInProgress}
                ready={saveUpdateUserSuccess}
              >
                Continue
              </Button>
            </div>
            <br></br>
            <div ref={modalReference} style={{ width: 200 }}>
              {' '}
            </div>
          </>
        ) : null}

        {(!isHost && userId && !phoneNumberVerified && isSubmittedOnfidoNricVerification && phoneVisible == true) || (isHost && userId && !phoneNumberVerified && phoneVisible == true) ? (
          <PhoneNumberForm
            className={css.form}
            initialValues={{ phoneNumber: currentPhoneNumber, phoneCode: currentPhoneCode }}
            savePhoneNumberError={savePhoneNumberError}
            currentUser={currentUser}
            onSubmit={async values => {
              onSubmitContactDetails({ ...values, currentPhoneNumber, currentPhoneCode });
              // // await setverifyIdVisible(true)
              try {
                if(isHost) {
                  const response = await getEventsByBucket('hostverify');
                  triggerAnalyticsEvent({
                    event_id: event_trigger_ids.HOSTVERIFY_ADD_CONTACT_DETAILS,
                    eventData: response.data,
                    props: {
                      guest:currentUser,
                      ui: {
                        page: 'IdVerificationScreen',
                        button: "Next"
                     }
                    },
                    userId,
                  });
                }
                else {
                  const response = await getEventsByBucket('hostverify');
                    triggerAnalyticsEvent({
                      event_id: event_trigger_ids.GUEST_VERIFY_ADD_CONTACT,
                      eventData: response.data,
                      props: {
                        guest: currentUser,
                        ui: {
                          page: 'IdVerificationScreen',
                          button: "Next"
                       }
                      },
                      user: currentUser,
                      userId: currentUser ? currentUser.id.uuid : null
                    });
                }
                
              } catch (error) {
                console.error('Error triggering analytics event:', error);
              }
              // await setphoneVisible(false)
              // await setaddressVisible(true)
            }}
            onChange={onChange}
            inProgress={saveContactDetailsInProgress}
            ready={contactDetailsChanged}
            modifyStep={true}
            handleSteps={handleSteps}
            step={2}
          />
        ) : (
          ''
        )}

        { (!isHost && userId && phoneNumberVerified && isSubmittedOnfidoNricVerification && !isEmailVerified) || (isHost && userId && phoneNumberVerified && !isEmailVerified ) ? (
          <EmailVerificationFormAccountSettings
            fetchCurrentUser={fetchCurrentUser}
            selectVerificationProcess={selectVerificationProcess}
            isEmailVerified={isEmailVerified}
            userEmail={userEmail}
            modifyStep={true}
            handleSteps={handleSteps}
            step={3}
          />
        ) : null}
        { (!isHost && userId && phoneNumberVerified && isEmailVerified && isSubmittedOnfidoNricVerification && !userLocation ) || (isHost && userId && phoneNumberVerified && isEmailVerified && !userLocation) ? (
          <AddressDetailsForm
            className={css.form}
            initialValues={initialValues}
            currentUser={currentUser}
            inProgress={saveAddressDetailsInProgress}
            ready={saveAddressDetailsSuccess}
            saveAddressDetailsError={saveAddressDetailsError}
            onSubmit={async values => {
              const { location, floorUnit, city, state, postalCode } = values;
              const {
                selectedPlace: { address, origin },
              } = location;
              const updatedValues = {
                location: {
                  search: address,
                  selectedPlace: {
                    address,
                    origin: new LatLng(origin.lat, origin.lng),
                  },
                },
                floorUnit,
                state,
                city,
                postalCode,
              };

              onSubmitAddressDetailsForm(updatedValues);

              try {
                if(isHost) {
                  const response = await getEventsByBucket('hostverify');
                  triggerAnalyticsEvent({
                    event_id: event_trigger_ids.HOSTVERIFY_ADD_ADDRESS,
                    eventData: response.data,
                    props: {
                      guest: currentUser,
                      ui: {
                        page: 'IdVerificationScreen',
                        button: "Next"
                     }
                    },
                    userId,
                  });
                }
                else {
                  const response = await getEventsByBucket('guestverify');
                  triggerAnalyticsEvent({
                    event_id: event_trigger_ids.GUEST_VERIFY_ADD_ADDRESS,
                    eventData: response.data,
                    props: {
                      guest: currentUser,
                      ui: {
                        page: 'IdVerificationScreen',
                        button: 'Next'
                      }
                    },
                    user: currentUser,
                    userId: currentUser ? currentUser.id.uuid : null
                  });
                }
                
              } catch (error) {
                console.error('Error triggering analytics event:', error);
              }

              const properties = createRawPropertiesForGTM({
                props,
              });

              createConversionEvents(
                properties,
                EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS,
                'click'
              );
              EnableIDVerify = false;
            }}
            pageName={pageName}
            isVerification={true}
            modifyStep={true}
            handleSteps={handleSteps}
            step={isHost ? 3 : 4}
          />
        ) : null}

        { (!isHost &&userId &&
        // !isHost &&
        phoneNumberVerified &&
        isSubmittedOnfidoNricVerification &&
        userLocation &&
        isEmailVerified &&
        !dateOfBirth ) || (isHost && userId &&
          // !isHost &&
          phoneNumberVerified &&
          userLocation &&
          isEmailVerified &&
          !dateOfBirth) ? (
          <PersonalDetailsForm
            className={css.form}
            initialValues={dobInitialValues}
            currentUser={currentUser}
            inProgress={savePersonalDetailsInProgress}
            ready={savePersonalDetailsSuccess}
            saveAddressDetailsError={savePersonalDetailsError}
            onSubmit={async values => {
              const { dateOfBirth } = values;
              const updatedValues = {
                dateOfBirth,
              };
              await onSubmitPersonalDetailsForm(updatedValues);
              // Trigger the analytics event
              try {
                if(isHost) {
                  const response = await getEventsByBucket('hostverify');
                  triggerAnalyticsEvent({
                    event_id: event_trigger_ids.HOSTVERIFY_ADD_DOB,
                    eventData: response.data,
                    props: {
                      guest: currentUser,
                      ui: {
                        page: 'IdVerificationScreen',
                        button: "Next"
                     }
                    },
                    userId: currentUser ? currentUser.id.uuid : null,
                  });
                }
                else {
                  const response = await getEventsByBucket('guestverify');
                  triggerAnalyticsEvent({
                    event_id: event_trigger_ids.GUEST_VERIFY_Add_Dob,
                    eventData: response.data,
                    props: {
                      guest: currentUser
                    },
                    user: currentUser,
                    userId: currentUser ? currentUser.id.uuid : null
                  });
                }
              } catch (error) {
                console.error('Error triggering analytics event:', error);
              }

            }}
            showGuide={true}
            modifyStep={true}
            handleSteps={handleSteps}
            step={5}
          />
        ) : null}
        <br></br>

        {!isHost &&
        userId &&
        phoneNumberVerified &&
        userLocation &&
        dateOfBirth &&
        !isHost &&
        isSubmittedOnfidoNricVerification &&
        !isResidencyStatus ? (
          <>
            <div>
              <JustToModifyStep
                modifyStep={true}
                handleSteps={handleSteps}
                step={6}
                title={'Residential status'}
              />
            </div>
            <UserResidencyForm
              residencyStatus={residencyStatus}
              modifyStep={true}
              onUpdateUserData={onUpdateUserData}
              handleSteps={handleSteps}
              step={6}
            />
          </>
        ) : null}

        {!isHost &&
        isEmailVerified &&
        dateOfBirth &&
        isResidencyStatus &&
        isSubmittedOnfidoNricVerification &&
        !isSubmittedOnfidoDriverVerification ? (
          <>
            <DynamicHeader />
            {/* <div id="onfido-mount"></div> */}
            <JustToModifyStep
              modifyStep={true}
              handleSteps={handleSteps}
              step={isHost ? 7 : 7}
              title={'Driver license verification'}
            />
            <div className={css.identityVerificationGuide}>
              <p className={css.identityVerificationGuideText}>
                <FormattedMessage id="driverIdentityVerification.guideLinet1" />
                <br></br>
              </p>
            </div>
            <div
              className={css.verificationModalButton}
              onClick={_handleInitiateDriverVerification}
            >
              <Button
                inProgress={saveUpdateUserInProgress || onfidoInProgress}
                ready={saveUpdateUserSuccess}
              >
                Continue
              </Button>
            </div>
            <br></br>
            <div ref={modalReference} style={{ width: 200 }}>
              {' '}
            </div>
            <div ref={modalReferenceDriver} style={{ width: 200 }}>
              {' '}
            </div>
          </>
        ) : null}

        {!isHost ? (
          isEmailVerified &&
          dateOfBirth &&
          isResidencyStatus &&
          isSubmittedOnfidoNricVerification &&
          isSubmittedOnfidoDriverVerification &&
          residencyStatus === 'Dependent Pass' ? (
            <>
              <JustToModifyStep
                modifyStep={true}
                handleSteps={handleSteps}
                step={8}
                title={'Letter of sponsorship'}
              />
              <LOSForm
                inProgress={saveUpdateUserInProgress}
                ready={saveUpdateUserSuccess}
                saveUpdateUserInProgress={saveUpdateUserInProgress}
                currentUser={currentUser}
                className={css.form}
                initialValues={{ losDocumentUrl }}
                onSubmit={values => {
                  const updateValues = {
                    publicData: {
                      sponsorshipLetter: losDocumentUrl,
                      guestIdentityVerificationStatus: 'pending',
                    },
                  };
                  updateTransitionAfterVerification()
                  onUpdateUserData(updateValues);
                }}
                step={8}
                losDocumentUrl={losDocumentUrl}
                updateLosDocumentUrl={data => setlosDocumentUrl(data)}
              />
            </>
          ) : null
        ) : null}
      </div>
    </>
  );
};

UserVerificationWithOnfidoFormsComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
  saveAddressDetailsSuccess: false,
  saveAddressDetailsError: false,
  saveUpdateUserSuccess: false,
  saveUpdateUserError: null,
  saveUpdateUserInProgress: false,
};

const { bool, func } = PropTypes;

UserVerificationWithOnfidoFormsComponent.propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveContactDetailsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  contactDetailsChanged: bool.isRequired,
  onSubmitContactDetails: func.isRequired,
  onSubmitAddressDetailsForm: func.isRequired,
  scrollingDisabled: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
  } = state.ContactDetailsPage;

  const {
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    saveUpdateUserSuccess,
    saveUpdateUserError,
    saveUpdateUserInProgress,
  } = state.AddressDetailsPage;

  return {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    contactDetailsChanged,
    scrollingDisabled: isScrollingDisabled(state),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    connectCalendarTabVisibility,
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    saveUpdateUserSuccess,
    saveUpdateUserError,
    saveUpdateUserInProgress,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUserData: data => dispatch(updateUserData(data)),
    onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
    onSumitUserUpdateDetails: values => dispatch(saveUpdateUserDetails(values)),
    onSubmitPersonalDetailsForm: values => dispatch(savePersonalDetails(values)),
    onSubmitAddressDetailsForm: address_values => dispatch(saveAddressDetails(address_values)),
    onGetActiveTrips:(param)=>dispatch(getActiveTrips(param)),
    onUpdateTransition:(param)=>dispatch(updateTransactionVerification(param))
  };
};

const UserVerificationWithOnfidoForms = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(UserVerificationWithOnfidoFormsComponent);

export default UserVerificationWithOnfidoForms;
