import React, { useEffect, useH } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import {
  checkValidCode,
  hasCurrentUserErrors,
  openMissingInfomationModal,
  resendMobileCode,
  sendMobileCode,
  sendPostalAddress,
  sendVerificationEmail,
} from '../../ducks/user.duck';
import {
  $currentUserStripeAccountRestricted,
  closeStripeAccountRestrictedAlert,
} from '../../ducks/alert.duck';
import { authenticationInProgress, logout } from '../../ducks/Auth.duck';
import {
  manageDisableScrolling,
  setupWaitingForUploadAvaThenVerifyGuest,
} from '../../ducks/UI.duck';
import {
  clearRedirectUrl,
  loginOrSignupClick,
  selectLoginOrSugnupRedirectUrl,
} from './Topbar.duck';
import { Topbar } from '../../components';
import { getEventsByBucket, triggerAnalyticsEventById } from '../../util/gtm/gtmCreateProperties';
import { SIGNUP_SUBMIT_BUTTON_ID } from '../../util/gtm/gtmConstants';

export const TopbarContainerComponent = props => {
  const {
    notApplySearch,
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onSendMobileCode,
    onSendPostalAddress,
    sendMobileCodeInProgress,
    onResendMobileCode,
    checkValidCode,
    openMissingInfomationModal,
    onOpenMissingInfomationModal,
    waitingForUploadAva,
    onSetupWaitingForUploadAvaThenVerifyGuest,
    isMobileLayout,
    searchSelectedPrediction,
    loginOrSignupRedirectUrl,
    onLoginOrSignupClick,
    clearRedirectUrl,
    ...rest
  } = props;

  useEffect(() => {
    if (isAuthenticated && !!loginOrSignupRedirectUrl) {
      history.push(loginOrSignupRedirectUrl);
      clearRedirectUrl();
    }
  }, [isAuthenticated, loginOrSignupRedirectUrl]);

  const handleSignupLinkClick = async () => {
    try {
      console.log("Signup link clicked", )
      const response = await getEventsByBucket('signup');
      console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEventById({ event_id: 'signup_open_email_form', eventData: response.data, props: {
        props,
        button: {
          buttonId: SIGNUP_SUBMIT_BUTTON_ID,
        },
      },
      userId: currentUser && currentUser.id && currentUser.id.uuid
    });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
    onLoginOrSignupClick();
  };

  //NOTICE: If you want to pass sthing to MODALMISSINGINFOMATION
  // ||
  // ||
  // \/
  //This object was pass to ModalMissingInfomation
  //Just pass properties in this Obj, it will magically
  //transfers to ModalMissingInfomation component.
  const modalMissingInfomationProps = {
    sendMobileCodeInProgress, //State that show code is sending
    onSendMobileCode, //Send mobile code handler
    onSendPostalAddress, //Send postal code handler
    onResendMobileCode, //Resend mobile code handler
    sendMobileCodeError: null,
    callMobileCodeInProgress: null, //State that show code is calling
    callMobileCodeError: null, //Call error flag
    onCallMobileCode: null, //Call handler,
    checkValidCode,
    onResendVerificationEmail,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    openMissingInfomationModal,
    onOpenMissingInfomationModal,
  };

  return (
    <Topbar
      notApplySearch={notApplySearch}
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentSearchParams={currentSearchParams}
      currentUser={currentUser}
      currentUserHasListings={currentUserHasListings}
      currentUserHasOrders={currentUserHasOrders}
      history={history}
      isAuthenticated={isAuthenticated}
      authScopes={authScopes}
      location={location}
      notificationCount={notificationCount}
      onLogout={onLogout}
      onManageDisableScrolling={onManageDisableScrolling}
      showGenericError={hasGenericError}
      modalMissingInfomationProps={modalMissingInfomationProps}
      waitingForUploadAva={waitingForUploadAva}
      isMobileLayout={isMobileLayout}
      searchSelectedPrediction={searchSelectedPrediction}
      onSetupWaitingForUploadAvaThenVerifyGuest={onSetupWaitingForUploadAvaThenVerifyGuest}
      onLoginOrSignupClick={handleSignupLinkClick}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  authScopes: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onSendMobileCode: func.isRequired,
  onResendMobileCode: func.isRequired,
  onSendPostalAddress: func.isRequired,
  checkValidCode: func.isRequired,
  sendMobileCodeInProgress: bool.isRequired,
  hasGenericError: bool.isRequired,
  onLoginOrSignupClick: func.isRequired,
  loginOrSignupRedirectUrl: string,
  clearRedirectUrl: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes, logoutInProgress } = state.Auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendMobileCodeInProgress,
    sendVerificationEmailError,
    openMissingInfomationModal,
  } = state.user;
  const { searchSelectedPrediction } = state.SearchPage;

  const { waitingForUploadAva } = state.UI;

  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    loginOrSignupRedirectUrl: selectLoginOrSugnupRedirectUrl(state),
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendMobileCodeInProgress,
    sendVerificationEmailError,
    hasGenericError,
    openMissingInfomationModal,
    waitingForUploadAva,
    searchSelectedPrediction,
    currentUserStripeAccountRestricted: $currentUserStripeAccountRestricted(state),
    logoutInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSendMobileCode: (phoneObj, sendSuccess, sendFail) =>
    dispatch(sendMobileCode(phoneObj, sendSuccess, sendFail)),
  onSendPostalAddress: (postalCodeObj, sendSuccess, sendFail) => dispatch(sendPostalAddress(postalCodeObj, sendSuccess, sendFail)),
  onResendMobileCode: (onSuccess, onFail) => dispatch(resendMobileCode(onSuccess, onFail)),
  checkValidCode: (code, verifySuccess, verifyFail) =>
    dispatch(checkValidCode(code, verifySuccess, verifyFail)),
  onOpenMissingInfomationModal: status => dispatch(openMissingInfomationModal(status)),
  onSetupWaitingForUploadAvaThenVerifyGuest: status =>
    dispatch(setupWaitingForUploadAvaThenVerifyGuest(status)),
  onLoginOrSignupClick: (page) => dispatch(loginOrSignupClick({page})),
  clearRedirectUrl: () => dispatch(clearRedirectUrl()),
  onCloseAlert: () => dispatch(closeStripeAccountRestrictedAlert())
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TopbarContainerComponent);

export default TopbarContainer;
