import classNames from 'classnames';
import memoize from 'lodash/memoize';
import moment from 'moment-timezone';
import 'react-dates/initialize';
import { func, object, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import Switch from 'react-switch';
import IconTrash from '../../assets/listingPage/trash.svg';
import { Button, Form, Modal } from '../../components';
import {
  ensureAvailabilityException,
  ensureBooking,
  ensureDayAvailabilityPlan,
} from '../../util/data';
import {
  getHourlyBlockDate,
  isInPartialRange,
  isInRange,
  isSameDate,
  momentTimeOfDayFromLocalToTimeZone,
  monthIdStringInTimeZone,
  resetToStartOfDay,
  sameDay,
  timeOfDayFromLocalToTimeZone,
  utcToTimeZone,
  resetToStartOfDayForPartial,
  DEFAULT_TIMEZONE,
} from '../../util/dates';
import { DAYS_OF_WEEK, propTypes } from '../../util/types';
import css from '../EditListingAvailabilityForm/ManageAvailabilityCalendar.css';

// Constants

const HORIZONTAL_ORIENTATION = 'horizontal';
const MAX_AVAILABILITY_EXCEPTIONS_RANGE = 365;
const MAX_BOOKINGS_RANGE = 180;
const TODAY_MOMENT = moment().startOf('day');
const END_OF_RANGE_MOMENT = TODAY_MOMENT.clone()
  .add(MAX_AVAILABILITY_EXCEPTIONS_RANGE - 1, 'days')
  .startOf('day');
const END_OF_BOOKING_RANGE_MOMENT = TODAY_MOMENT.clone()
  .add(MAX_BOOKINGS_RANGE - 1, 'days')
  .startOf('day');

// Constants for calculating day width (aka table cell dimensions)
const TABLE_BORDER = 2;
const TABLE_COLUMNS = 7;
const MIN_CONTENT_WIDTH = 272;
const MIN_CELL_WIDTH = Math.floor(MIN_CONTENT_WIDTH / TABLE_COLUMNS); // 38
const MAX_CONTENT_WIDTH_DESKTOP = 603;
const MAX_CELL_WIDTH_DESKTOP = Math.floor(MAX_CONTENT_WIDTH_DESKTOP / TABLE_COLUMNS); // 108
const VIEWPORT_LARGE = 1024;

// Helper functions

// Calculate the width for a calendar day (table cell)
const dayWidth = (wrapperWidth, windowWith) => {
  if (windowWith >= VIEWPORT_LARGE) {
    // NOTE: viewportLarge has a layout with sidebar.
    // In that layout 30% is reserved for paddings and 282 px goes to sidebar and gutter.
    const width = windowWith * 0.7 - 282;
    return width > MAX_CONTENT_WIDTH_DESKTOP
      ? MAX_CELL_WIDTH_DESKTOP
      : Math.floor((width - TABLE_BORDER) / TABLE_COLUMNS);
  } else {
    return wrapperWidth > MIN_CONTENT_WIDTH
      ? Math.floor((wrapperWidth - TABLE_BORDER) / TABLE_COLUMNS)
      : MIN_CELL_WIDTH;
  }
};

// Get a function that returns the start of the previous month
const prevMonthFn = (currentMoment, timeZone = 'Asia/Singapore') =>
  currentMoment
    .clone()
    .tz(timeZone)
    .subtract(1, 'months')
    .startOf('month');

// Get a function that returns the start of the next month
const nextMonthFn = (currentMoment, timeZone = 'Asia/Singapore') =>
  currentMoment
    .clone()
    .tz(timeZone)
    .add(1, 'months')
    .startOf('month');

// Get the start and end Dates in UTC

const dateStartAndEndInTimeZone = (date, timeZone = 'Asia/Singapore') => {
  return {
    start: resetToStartOfDay(date, timeZone),
    end: resetToStartOfDay(date, timeZone, 1),
  };
};

const dateStartAndEndInTimeZonePartial = (date, timeZone = 'Asia/Singapore') => {
  return {
    start: resetToStartOfDayForPartial(date, timeZone),
    end: resetToStartOfDayForPartial(date, timeZone, 1),
  };
};

const hourDateStartAndEndInTimeZone = (date, start, end, timeZone = 'Asia/Singapore') => {

  return {
    start: getHourlyBlockDate(start, timeZone),
    end: getHourlyBlockDate(end, timeZone),
  };
};

export const utcToListingTimeZone = (dateInUTC, timeZone = DEFAULT_TIMEZONE) => {
  const formattedDate = moment(dateInUTC).format('YYYY-MM-DD HH:mm');
  const timeZoneConverted = moment(formattedDate).tz(timeZone).format('YYYY-MM-DD HH:mm')
  return new Date(timeZoneConverted);
};

const hourDateStartAndEndInTimeZoneForPartial = (date, start, end, timeZone = 'Asia/Singapore') => {
  const formattedStartDate = moment(start).format('YYYY-MM-DD HH:mm');
  const startTimeZoneConverted = moment(formattedStartDate).tz(timeZone).format('YYYY-MM-DD HH:mm') 
  const formattedEndDate = moment(end).format('YYYY-MM-DD HH:mm');
  const getTimeZoneTimeDifference = moment(formattedStartDate).diff(moment(startTimeZoneConverted), 'minutes')
  return {
    start: moment(formattedStartDate).add(1 * getTimeZoneTimeDifference, 'minutes').toDate(),
    end: moment(formattedEndDate).add(1 * getTimeZoneTimeDifference, 'minutes').toDate(),
  };
};

// outside range -><- today ... today+MAX_AVAILABILITY_EXCEPTIONS_RANGE -1 -><- outside range
const isDateOutsideRange = (date, timeZone) => {
  const localizedDay = momentTimeOfDayFromLocalToTimeZone(date, timeZone);
  return (
    !isInclusivelyAfterDay(
      localizedDay,
      momentTimeOfDayFromLocalToTimeZone(TODAY_MOMENT, timeZone)
    ) ||
    !isInclusivelyBeforeDay(
      localizedDay,
      momentTimeOfDayFromLocalToTimeZone(END_OF_RANGE_MOMENT, timeZone)
    )
  );
};
const isOutsideRange = memoize(isDateOutsideRange);

const isMonthInRange = (monthMoment, startOfRange, endOfRange) => {
  const isAfterThisMonth = monthMoment.isSameOrAfter(startOfRange, 'month');
  const isBeforeEndOfRange = monthMoment.isSameOrBefore(endOfRange, 'month');
  return isAfterThisMonth && isBeforeEndOfRange;
};

const isPast = date => !isInclusivelyAfterDay(date, TODAY_MOMENT);
const isAfterEndOfRange = date => !isInclusivelyBeforeDay(date, END_OF_RANGE_MOMENT);
const isAfterEndOfBookingRange = date => !isInclusivelyBeforeDay(date, END_OF_BOOKING_RANGE_MOMENT);

const isBooked = (bookings, day, timeZone) => {
  return !!bookings.find(b => {
    const booking = ensureBooking(b);
    const displayStart = booking.attributes.displayStart;
    const displayEnd = booking.attributes.displayEnd;
    const localizedDay = day.toDate();

    return (
      sameDay(displayStart, localizedDay) ||
      sameDay(displayEnd, localizedDay) ||
      isInRange(localizedDay, displayStart, displayEnd, 'day')
    );
  });
};

const getSameDayBookings = (bookings, day, timeZone) => {
  let dayBookings = [];
  bookings.map(b => {
    const booking = ensureBooking(b);
    const displayStart = booking.attributes.displayStart;
    const displayEnd = booking.attributes.displayEnd;
    const localizedDay = day.toDate();
    if(sameDay(displayStart, localizedDay) ||
    sameDay(displayEnd, localizedDay) ||
    isInRange(localizedDay, displayStart, displayEnd, 'day')) {
      dayBookings.push(b);
    }
  });
  return dayBookings
};

const findException = (exceptions, day, timeZone = 'Asia/Singapore') => {
  const final =  exceptions.find(exception => {
    const availabilityException = ensureAvailabilityException(exception.availabilityException);
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    const isSameTimeZone =
      moment(availabilityException.attributes.start)
        .tz(timeZone)
        .hours() === 0;
    const start = isSameTimeZone
      ? availabilityException.attributes.start
      : utcToTimeZone(availabilityException.attributes.start);
    const end = isSameTimeZone
      ? availabilityException.attributes.end
      : utcToTimeZone(availabilityException.attributes.end);

    return isInRange(localizedDay, start, end, undefined, timeZone, '[)');
  });

  return final;
};

const findPartiallExceptions = (exceptions, day, timeZone = 'Asia/Singapore', isFullDay) => {
  let partialExceptionsList = [];
  exceptions.map(exception => {
    const availabilityException = ensureAvailabilityException(exception.availabilityException);
    if(availabilityException.attributes.seats == 2) {
      return ;
    }
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    console.log("this.state.modalFullDayBlocked", isFullDay)
    const dateRange = isFullDay ? dateStartAndEndInTimeZone(day.toDate(), timeZone) : dateStartAndEndInTimeZonePartial(day.toDate(), timeZone);
    // const dateRange = dateStartAndEndInTimeZonePartial(day.toDate(), timeZone);
    const dayStart = dateRange.start;
    const dayEnd = dateRange.end;
    const isSameTimeZone =
      moment(availabilityException.attributes.start)
        .tz(timeZone)
        .hours() === 0;
    // const start = isSameTimeZone
    //   ? availabilityException.attributes.start
    //   : utcToTimeZone(availabilityException.attributes.start);
    // const end = isSameTimeZone
    //   ? availabilityException.attributes.end
    //   : utcToTimeZone(availabilityException.attributes.end);

    const start = availabilityException.attributes.start;
    const end = availabilityException.attributes.end;
    // return false;

    const res = isInPartialRange(dayStart, dayEnd, start, end, undefined, timeZone, '[]');
    if(res) {
      partialExceptionsList.push({
        exception: availabilityException,
        fullException: exception,
        start: start,
        end: end
      });

    }
  });
  if(partialExceptionsList.length) {
  }
  return partialExceptionsList;
};

const findPartiallException = (exceptions, day, timeZone = 'Asia/Singapore') => {
  const final =  exceptions.find(exception => {
    const availabilityException = ensureAvailabilityException(exception.availabilityException);
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    const dateRange = dateStartAndEndInTimeZone(day.toDate(), timeZone);
    const dayStart = dateRange.start;
    const dayEnd = dateRange.end;
    const isSameTimeZone =
      moment(availabilityException.attributes.start)
        .tz(timeZone)
        .hours() === 0;
    const start = isSameTimeZone
      ? availabilityException.attributes.start
      : utcToTimeZone(availabilityException.attributes.start);
    const end = isSameTimeZone
      ? availabilityException.attributes.end
      : utcToTimeZone(availabilityException.attributes.end);


    // return false;
    const res = isInPartialRange(dayStart, dayEnd, start, end, undefined, timeZone, '[]');
    if(res) {

    }
    return res
  });
  return final;
};

const isBlocked = (availabilityPlan, exception, date, timeZone) => {
  const planEntries = ensureDayAvailabilityPlan(availabilityPlan).entries;
  const localizedDay = timeOfDayFromLocalToTimeZone(date, timeZone);
  const planEntry = planEntries.find(
    weekDayEntry => weekDayEntry.dayOfWeek === DAYS_OF_WEEK[moment(localizedDay).isoWeekday() - 1]
  );
  const seatsFromPlan = planEntry ? planEntry.seats : 0;

  const seatsFromException =
    exception && ensureAvailabilityException(exception.availabilityException).attributes.seats;
  const seats = exception ? seatsFromException : seatsFromPlan;
  return seats === 0;
};

const isPartiallyBlocked = (availabilityPlan, exception, date, timeZone) => {
  const planEntries = ensureDayAvailabilityPlan(availabilityPlan).entries;
  const localizedDay = timeOfDayFromLocalToTimeZone(date, timeZone);
  const planEntry = planEntries.find(
    weekDayEntry => weekDayEntry.dayOfWeek === DAYS_OF_WEEK[moment(localizedDay).isoWeekday() - 1]
  );
  const seatsFromPlan = planEntry ? planEntry.seats : 0;

  const seatsFromException =
    exception && ensureAvailabilityException(exception.availabilityException).attributes.seats;
  const seats = exception ? seatsFromException : seatsFromPlan;
  return seats === 0;
};

const dateModifiers = (availabilityPlan, exceptions, bookings, date) => {
  const { timezone } = availabilityPlan;
  const exception = findException(exceptions, date, timezone);
  const partialExceptions = findPartiallExceptions(exceptions, date, timezone);
  if(partialExceptions.length) {
  }
  //  Date modifier

  let sameDayBookings = [];
  const isCarBooked = isBooked(bookings, date, timezone);
  if(isCarBooked) {
    sameDayBookings = getSameDayBookings(bookings, date, timezone);
  }
  return {
    isOutsideRange: isOutsideRange(date, timezone),
    isSameDay: isSameDate(
      timeOfDayFromLocalToTimeZone(date, timezone),
      timeOfDayFromLocalToTimeZone(TODAY_MOMENT, timezone)
    ),
    isBlocked: isBlocked(availabilityPlan, exception, date, timezone),
    isPartiallyBlocked: true ?  partialExceptions.length : false,
    isBooked: isBooked(bookings, date, timezone),
    isInProgress: exception && exception.inProgress,
    isFailed: exception && exception.error,
    partialExceptions: partialExceptions,
    exception: exception,
    sameDayBookings
  };
};


const makeDraftException = (exceptions, start, end, seats) => {
  const draft = ensureAvailabilityException({ attributes: { start, end, seats } });
  return { availabilityException: draft };
};

////////////////////////////////
// EditListingAvailiblityCalenderForm //
////////////////////////////////
class EditListingAvailiblityCalenderForm extends Component {
  constructor(props) {
    super(props);
    // DOM refs

    this.state = {
      focused: true,
      date: null,
      isUpdatedAvailability: false,
      isModalOpen: this.props.isModalOpen,
      modalDate: null,
      slotsList: this.props.slotsList,
      availbleCertainHours: this.props.availbleCertainHours,
      modalFullDayBlocked: this.props.modalFullDayBlocked,
      slotIndex: 0,
      selectedStartTime: null,
      selectedEndTime: null,
      exceptionsOnDate: [],
      selectedBlockSlots: [],
      slotWithIndexDataList: [],
      slotsIntervalList: []
    };

    this.onDayAvailabilityChange = this.onDayAvailabilityChange.bind(this);
    this.onDayHourlyAvailabilityChange = this.onDayHourlyAvailabilityChange.bind(this);
    // this.onDateChange = this.onDateChange.bind(this);
    // this.onFocusChange = this.onFocusChange.bind(this);
    // this.onMonthClick = this.onMonthClick.bind(this);
  }

  componentDidMount() {
    if(this.state.availbleCertainHours) {
      let slotWithIndexDataList = [];
      let slotIndex = this.state.slotIndex;
      // Make All the slots availble
      this.props.partialExceptions.map(exception => {
        if(exception.fullException.availabilityException.attributes.seats == 2) {
          return ;
        }
        const start = exception.start;
        const end = exception.end;
        slotWithIndexDataList.push({
          id: slotIndex,
          start:  moment.utc(moment(start)).valueOf(),
          end: moment.utc(moment(end)).valueOf(),
          displayStart: moment(start),
          displayEnd: moment(end) ,
          isNew: false,
        });
        slotIndex += 1;
      })
      const modifiedSlots = [...this.state.slotWithIndexDataList, ...slotWithIndexDataList]
      modifiedSlots.sort((slotOne, slotTwo) => {
        return slotOne.start - slotTwo.start
      })
      this.setState({
        slotIndex: slotIndex,
        slotWithIndexDataList: modifiedSlots
      }, () => {
      });
    }
    // Fetch month data if user have navigated to availability tab in EditListingWizard
    // this.fetchMonthData(this.state.currentMonth);
    // Fetch next month too.
    // this.fetchMonthData(nextMonthFn(this.state.currentMonth));

  }



  onDayHourlyAvailabilityChange(date, seats, exceptions, newExceptions) {
    // Here we Have to check

    const { availabilityPlan, listingId } = this.props;
    const { timezone } = availabilityPlan;
    let draftExceptions = [];
    const planEntries = ensureDayAvailabilityPlan(availabilityPlan).entries;
    const seatsFromPlan = planEntries.find(
      weekDayEntry => weekDayEntry.dayOfWeek === DAYS_OF_WEEK[date.isoWeekday() - 1]
    ).seats;
    newExceptions.map(ex => {
      const {start: draftStart, end: draftEnd}  = ex;
      const { start, end } = hourDateStartAndEndInTimeZoneForPartial(date, draftStart, draftEnd, timezone);
      // console.log("Start date before creation", start);
      // console.log("End date before creation", end);
      const draftException = makeDraftException(exceptions, start, end, seatsFromPlan);
      const params = { listingId, start, end, seats, currentException: draftException };

      this.props.availability.onCreateAvailabilityException(params);
    })
    // const { start, end } = dateStartAndEndInTimeZone(date.startOf('day').toDate(), timezone);
    // const planEntries = ensureDayAvailabilityPlan(availabilityPlan).entries;
    // const seatsFromPlan = planEntries.find(
    //   weekDayEntry => weekDayEntry.dayOfWeek === DAYS_OF_WEEK[date.isoWeekday() - 1]
    // ).seats;
    // Here draft exception
    // const currentException = findException(exceptions, date, timezone, true);
    // Draft Excpetion

    // const draftException = makeDraftException(exceptions, start, end, seatsFromPlan);
    // const exception = currentException || draftException;
    // const hasAvailabilityException = currentException && currentException.availabilityException.id;
    // const params = { listingId, start, end, seats, currentException: exception };
    // if (hasAvailabilityException) {
    //   const id = currentException.availabilityException.id;
    //   const isResetToPlanSeats = seatsFromPlan === seats;
    //   if (isResetToPlanSeats) {
    //     // Delete the exception, if the exception is redundant
    //     // (it has the same content as what user has in the plan).
    //     this.props.availability.onDeleteAvailabilityException({
    //       id,
    //       currentException: exception,
    //       seats: seatsFromPlan,
    //     });
    //   } else {
    //     // If availability exception exists, delete it first and then create a new one.
    //     // NOTE: currently, API does not support update (only deleting and creating)
    //     this.props.availability
    //       .onDeleteAvailabilityException({ id, currentException: exception, seats: seatsFromPlan })
    //       .then(() => {
    //         const params = { listingId, start, end, seats, currentException: exception };
    //         this.props.availability.onCreateAvailabilityException(params);
    //       });
    //   }
    // } else {
    //   // If there is no existing AvailabilityExceptions, just create a new one
    //   const params = { listingId, start, end, seats, currentException: exception };
    //   this.props.availability.onCreateAvailabilityException(params);
    // }
    // if (this.props.onUpdateAvailability && !this.state.isUpdatedAvailability) {
    //   this.setState({ isUpdatedAvailability: true }, () => this.props.onUpdateAvailability());
    // }
  }

  getStartSlotList = () => {
    if(this.state.exceptionsOnDate && this.state.exceptionsOnDate.length) {
      this.state.exceptionsOnDate.map(exception => {

      })
    }
    else {
      // If no exception Blocking
    }
  }

  onDayAvailabilityChange(date, seats, exceptions) {
    const { availabilityPlan, listingId } = this.props;
    const { timezone } = availabilityPlan;
    const { start, end } = dateStartAndEndInTimeZone(date.startOf('day').toDate(), timezone);
    const planEntries = ensureDayAvailabilityPlan(availabilityPlan).entries;
    const seatsFromPlan = planEntries.find(
      weekDayEntry => weekDayEntry.dayOfWeek === DAYS_OF_WEEK[date.isoWeekday() - 1]
    ).seats;
    // Here draft exception
    const currentException = findException(exceptions, date, timezone, true);
    // Draft Excpetion

    const draftException = makeDraftException(exceptions, start, end, seatsFromPlan);
    const exception = currentException || draftException;
    const hasAvailabilityException = currentException && currentException.availabilityException.id;
    const params = { listingId, start, end, seats, currentException: exception };
    if (hasAvailabilityException) {
      const id = currentException.availabilityException.id;
      const isResetToPlanSeats = seatsFromPlan === seats;
      if (isResetToPlanSeats) {
        // Delete the exception, if the exception is redundant
        // (it has the same content as what user has in the plan).
        this.props.availability.onDeleteAvailabilityException({
          id,
          currentException: exception,
          seats: seatsFromPlan,
        }).then(() => {
          if(this.props.isModalOpen) {
            this.props.modalCloseAfterUpdate();
          }
        });
      } else {
        // If availability exception exists, delete it first and then create a new one.
        // NOTE: currently, API does not support update (only deleting and creating)
        this.props.availability
          .onDeleteAvailabilityException({ id, currentException: exception, seats: seatsFromPlan })
          .then(() => {
            const params = { listingId, start, end, seats, currentException: exception };
            this.props.availability.onCreateAvailabilityException(params);
          });
      }
    } else {
      // If there is no existing AvailabilityExceptions, just create a new one
      const params = { listingId, start, end, seats, currentException: exception };
      if (this.state.modalFullDayBlocked){
        this.props.availability.onCreateAvailabilityException(params);
      }
      if(this.state.isModalOpen) {
        this.props.modalCloseAfterUpdate();
      }
    }
    if (this.props.onUpdateAvailability && !this.state.isUpdatedAvailability) {
      this.setState({ isUpdatedAvailability: true }, () => this.props.onUpdateAvailability());
    }
  }

  onBookingStartTimeChange = (e, object) => {


    // If User Has Selected already a date

    let slotWithIndexDataList = this.state.slotWithIndexDataList;
    const objIndex = this.state.slotWithIndexDataList.findIndex((obj => obj.id == object.id));
    slotWithIndexDataList[objIndex].start =   parseInt(e.target.value);
    slotWithIndexDataList[objIndex].end =  null;
    this.setState({
      slotWithIndexDataList: slotWithIndexDataList
    })
  };

  onBookingEndTimeChange = (e, object) => {

    let slotWithIndexDataList = this.state.slotWithIndexDataList;
    const objIndex = this.state.slotWithIndexDataList.findIndex((obj => obj.id == object.id));
    slotWithIndexDataList[objIndex].end = parseInt(e.target.value);
    this.setState({
      slotWithIndexDataList: slotWithIndexDataList
    });
  };

  addAnotherSlot = () => {
    const { slotIndex } = this.state;
    let slotWithIndexDataList = this.state.slotWithIndexDataList;
      slotWithIndexDataList.push({
        id: slotIndex,
        isNew: true,
    });
    this.setState({
      slotIndex:  slotIndex + 1,
      slotWithIndexDataList: slotWithIndexDataList
    });
  };

  removeSlot = (slotIndex) => {
    const slotWithIndexDataList = this.state.slotWithIndexDataList;
    const myArray = slotWithIndexDataList.filter(function( obj ) {
      return obj.id !== slotIndex;
    });
    this.setState({
      slotWithIndexDataList: myArray
    });
  }

  slotSelectionField = (object ) => {
    // object contains: id, start, end, time and end time
    let newListOfHours = [];
    let startHourList = [];
    let endHourList = [];
    let allHoursList = this.state.slotsList;

    newListOfHours = [...allHoursList];
    if (object.start){
      endHourList = [...allHoursList].filter((timeObject) => {
        return timeObject.timestamp > object.start
      });
    }

    // let startSlotsForThisIndex = this.state.slotsList;
    // const newObjectItemFieldList = this.state.slotWithIndexDataList.filter(item => item.id != object.id);
    // if(newObjectItemFieldList && newObjectItemFieldList.length) {
    //   // Here make exclude start time from this list
    //   newObjectItemFieldList.map(slotObject => {
    //     const start = slotObject.start;
    //     const end = slotObject.end;
    //     if(start && end) {
    //       allHoursList.map(timeObj => {
    //         if((start && moment(parseInt(timeObj.timestamp))  < moment(start)) || (end && moment(parseInt(timeObj.timestamp)) > moment(end))) {
    //           newListOfHours.push(timeObj);
    //         }
    //       });
    //     }
    //   })

    // }
    // if(!newListOfHours.length) {
    //   newListOfHours = allHoursList;

    // }
    // endHourList = allHoursList;
    // if(object.start) {
    //   // There shoud be no gap if gap is there
    //   newListOfHours.map((hour, index) => {
    //     const hourIndex = (this.state.slotsList).indexOf(hour);
    //     const nextIndexItem = this.state.slotsList[hourIndex + 1];

    //     if(nextIndexItem) {
    //       if(moment(hour.timestamp) > moment(object.start) && (moment(nextIndexItem.timestamp).isSame(moment(hour.timestamp).add(30, 'minutes')) )) {
    //         endHourList.push(hour);
    //       }
    //     }
    //     else {
    //         if(moment(hour.timestamp) > moment(object.start)) {
    //           endHourList.push(hour);
    //         }
    //     }


    //   })
    //    // endHourList = newListOfHours.filter(item => item.timestamp != object.start || moment(item.timestamp) > moment(object.start))
    //  }
    // newListOfHours.map(obj => {
    //   if(object.start == obj.timestamp) {
    //   }
    // });
    // Here get the start and end range
    // if(!object.start) {
    //   this.state.slotWithIndexDataList.map(slotEntered => {

    //   })

    // }
    // if(!object.end) {
    // }

    const isTimeFallsInGapForStart = (start, end, currentTime) => {
      return  currentTime.isSameOrAfter(start) && currentTime.isBefore(end);
    }


    const isTimeFallsInGapForEnd = (start, end, currentTime) => {
      return  currentTime.isAfter(start) && currentTime.isSameOrBefore(end);
    }

    const sameDayBookings = this.props.sameDayBookings
    if(sameDayBookings && sameDayBookings.length){
      sameDayBookings.forEach((booking) => {
        let start = booking.attributes && booking.attributes.displayStart || undefined
        let end = booking.attributes && booking.attributes.displayEnd || undefined
        const selectedDate = this.props.date;
        const stringDate = selectedDate.format("YYYY-MM-DD");
        if(start || end){
          start = start || selectedDate.startOf('day')
          end = end || selectedDate.endOf('day');
          newListOfHours = newListOfHours.filter((hour) => {
            return !isTimeFallsInGapForStart(start, end, moment(`${stringDate} ${hour.timeOfDay}`));
          })
          endHourList = endHourList.filter((hour) => {
            return !isTimeFallsInGapForEnd(start, end, moment(`${stringDate} ${hour.timeOfDay}`));
          })
        }
      })
    }

    const partialExceptions = this.props.partialExceptions;
    if (partialExceptions && partialExceptions.length){
      partialExceptions.forEach((exception) => {
        let start = exception.start || undefined
        let end = exception.end || undefined
        const selectedDate = this.props.date;
        const stringDate = selectedDate.format("YYYY-MM-DD");
        if (start || end){
          start = start || selectedDate.startOf('day')
          end = end || selectedDate.endOf('day');
          newListOfHours = newListOfHours.filter((hour) => {
            return !isTimeFallsInGapForStart(start, end, moment(`${stringDate} ${hour.timeOfDay}`));
          })
          endHourList = endHourList.filter((hour) => {
            return !isTimeFallsInGapForEnd(start, end, moment(`${stringDate} ${hour.timeOfDay}`));
          })
        }
      })
    }

    const otherException = this.props.exception
    if (otherException && otherException.availabilityException){
        const availabilityException = otherException.availabilityException
        let start = availabilityException.attributes && availabilityException.attributes.start || undefined
        let end = availabilityException.attributes && availabilityException.attributes.end || undefined
        const selectedDate = this.props.date;
        const stringDate = selectedDate.format("YYYY-MM-DD");
        if(start || end){
          start = start || selectedDate.startOf('day')
          end = end || selectedDate.endOf('day');
          newListOfHours = newListOfHours.filter((hour) => {
            return !isTimeFallsInGapForStart(start, end, moment(`${stringDate} ${hour.timeOfDay}`));
          })
          endHourList = endHourList.filter((hour) => {
            return !isTimeFallsInGapForEnd(start, end, moment(`${stringDate} ${hour.timeOfDay}`));
          })
        }
    }

    const allOtherSlots = this.state.slotWithIndexDataList.filter((slot) => {
      return slot.isNew && slot.id !== object.id
    });
    if (allOtherSlots && allOtherSlots.length){
      allOtherSlots.forEach((slot) => {
        let start = slot.start || undefined
          let end = slot.end || undefined
          const selectedDate = this.props.date;
          const stringDate = selectedDate.format("YYYY-MM-DD");
          if (start || end){
            start = start ? moment(start) : selectedDate.startOf('day')
            end = end ? moment(end) : selectedDate.endOf('day');
            newListOfHours = newListOfHours.filter((hour) => {
              return !isTimeFallsInGapForStart(start, end, moment(`${stringDate} ${hour.timeOfDay}`));
            })
            endHourList = endHourList.filter((hour) => {
              return !isTimeFallsInGapForEnd(start, end, moment(`${stringDate} ${hour.timeOfDay}`));
            })
          }
      })
    }


    if (endHourList && endHourList.length){
      let startHourTimestamp = object.start;
      let longestBlockingRanges = [];
      for (let index = 0 ; index < endHourList.length; index++){
          const endHour = endHourList[index];
          const endHourTimestamp = endHour.timestamp;

          if (moment(endHourTimestamp).diff(moment(startHourTimestamp), 'minutes') > 30 ){
            break;
          }else{
            longestBlockingRanges.push(endHour);
            startHourTimestamp = endHourTimestamp;
          }
      }
      endHourList = longestBlockingRanges;
    }


    if(!object.isNew){
      return <div key={object.id} className={css.presentSlotItemContainer}>
        <span>{moment(object.displayStart).format("hh:mm a")} - {moment(object.displayEnd).format("hh:mm a")}</span>
        <span onClick={() => this.removeSlot(object.id)} className={css.presentSlotRemoval}>
          <img src={IconTrash} title="delete slot" alt="delete slot icon" />
        </span>
      </div>
    }

    return (
      <div key={object.id} className={css.fieldWrapper}>
        <div className={classNames(css.newInput, css.field)}>
          <select required className={css.fieldSelect} id="lang2" onChange={(value) => this.onBookingStartTimeChange(value, object)}>
              <option key={'dfjdfs'} value='' selected={true ? !object.start: false} disabled>Select</option>
              {newListOfHours.map(slot =><option hello={moment(slot.timestamp).toDate()} key={slot.timestamp} selected={object.start && (slot.timestamp === object.start)}  value={slot.timestamp} >{slot.timeOfDay}</option>)}
          </select>
        </div>
        <div className={classNames(css.newInput, css.field)}>
          <select className={css.fieldSelect} id="lang1" required onChange={(value) => this.onBookingEndTimeChange(value, object)} value={object.start && object.end} >
            <option key={'dfjdfs'} value='' selected={true ? !object.end: false} disabled>Select</option>
            {object.start && endHourList.map(slot =><option hello={moment(slot.timestamp).toDate()} key={slot.timestamp} selected={object.start && (slot.timestamp === object.end)} value={slot.timestamp}>{slot.timeOfDay}</option>)}
          </select>
        </div>
        <div className={css.selectionRemove} onClick={() => this.removeSlot(object.id)}>
            {/* <IconClose rootClassName={css.closeIcon} /> */}
            <img src={IconTrash} alt="" />
        </div>
      </div>
    );
  };

  onChangeFullDayBlocking = (value) => {
    if(value) {
      this.setState({modalFullDayBlocked: value, availbleCertainHours: false, slotIndex: 0, slotWithIndexDataList: []})
    }
    else {
      this.setState({modalFullDayBlocked: value })
    }
  };

  onChangeAvailbleCertainHours = (value) => {
    if(value) {
      this.setState({availbleCertainHours: value, modalFullDayBlocked: false});
    }
    else {
      this.setState({availbleCertainHours: value});
    }
  };

  blockButtonHalder = (blockFlag, date)  => {
    const { availabilityPlan, availability, disableAllException } = this.props;
    const { timezone } = availabilityPlan;
    const calendar = availability.calendar;
    // This component is for day/night based processes. If time-based process is used,
    // you might want to deal with local dates using monthIdString instead of monthIdStringInUTC.
    const { exceptions = [], bookings = [] } =
      calendar[monthIdStringInTimeZone(date, timezone)] || {};
      const { isOutsideRange, isSameDay, isBlocked, isPartiallyBlocked, isBooked, partialExceptions, isInProgress, isFailed } = dateModifiers(
        availabilityPlan,
        exceptions,
        bookings,
        date
      );

      // Partially booked
      // Partially blocked

      const deletePartialExceptions = async _ => {
            const promises = partialExceptions.map(async exception => {
              const id = exception.exception.id;
              return this.props.availability
              .onDeleteAvailabilityException({ id, currentException: exception.fullException , seats: 1 })
              // .then(() => {
              //   const params = { listingId, start, end, seats, currentException: exception };
              //   this.props.availability.onCreateAvailabilityException(params);
              // });
              // return numFruit
            })

            const deleteRes = await Promise.all(promises)
            if (!blockFlag) {
              // Unblock the date (seats = 1)
              this.onDayAvailabilityChange(date, 1, exceptions);
            } else {
              // Block the date (seats = 0)
              this.onDayAvailabilityChange(date, 0, exceptions);
            }
            if(this.state.isModalOpen) {
              this.props.modalCloseAfterUpdate();
            }
      }

      if(isPartiallyBlocked) {
        deletePartialExceptions();
        // then delete all the exceptions
      }
      else {
        if (!blockFlag) {
          // Unblock the date (seats = 1)
          this.onDayAvailabilityChange(date, 1, exceptions);

        } else {
          // Block the date (seats = 0)
          this.onDayAvailabilityChange(date, 0, exceptions)
        }
      }


    // this.onClosePopup();
  }


  hourlyBlockingHandler = (date, slotList)  => {
    const { availabilityPlan, availability, disableAllException } = this.props;
    const { timezone } = availabilityPlan;
    const calendar = availability.calendar;
    // This component is for day/night based processes. If time-based process is used,
    // you might want to deal with local dates using monthIdString instead of monthIdStringInUTC.
    const { exceptions = [], bookings = [] } = calendar[monthIdStringInTimeZone(date, timezone)] || {};
    // const { isPast, isBlocked, isBooked, isInProgress } = dateModifiers(
    //   availabilityPlan,
    //   exceptions,
    //   bookings,
    //   date
    // );
    if (slotList.length) {
      // Unblock the date (seats = 1)
      this.onDayHourlyAvailabilityChange(date, 0, exceptions, slotList);
    } else {
      // Block the date (seats = 0)
      this.onDayHourlyAvailabilityChange(date, 1, exceptions, slotList);
    }

    // this.onClosePopup();
  }


  handleSubmit = values => {

    const { availabilityPlan, availability, disableAllException } = this.props;
    const { timezone } = availabilityPlan;

    if(this.state.modalFullDayBlocked) {
      this.blockButtonHalder(true, this.props.date);
    }
    else {
      if(!this.state.availbleCertainHours) {
        // Unblock the day
        this.blockButtonHalder(false, this.props.date);
      }
      else {
        // Check for time blocking

          // Here Hourly Index

              const deletePartialExceptions = async _ => {
                const promises = this.props.partialExceptions.map(async exception => {
                  const id = exception.exception.id;
                  return this.props.availability
                  .onDeleteAvailabilityException({ id, currentException: exception.fullException , seats: 1 })
                  // .then(() => {
                  //   const params = { listingId, start, end, seats, currentException: exception };
                  //   this.props.availability.onCreateAvailabilityException(params);
                  // });
                  // return numFruit
                })

                const deleteRes = await Promise.all(promises)
                if(this.state.slotWithIndexDataList && this.state.slotWithIndexDataList.length) {
                  this.hourlyBlockingHandler(this.props.date, this.state.slotWithIndexDataList)
                }
          }
          deletePartialExceptions();
        }

      //  Handle Time blocking
    }
    this.props.modalCloseAfterUpdate();

  };

  render() {
    const {
      className,
      rootClassName,
      listingId,
      availability,
      availabilityPlan,
      onMonthChanged,
      monthFormat,
      onManageDisableScrolling,
      intl,
      date,
      isModalOpen,
      changeModalVisiblity,
      fullDayinput,
      certainHourInput,
      partialException,
      exception,
      ...rest
    } = this.props;

    const availbleCertainHours =  this.state.availbleCertainHours

    const totalSlots = (this.state.slotWithIndexDataList || [])
    const canAddMoreSlots = (totalSlots.length || 0) < 3
    const alreadyPresentSlots = (totalSlots).filter((record) => !record.isNew);
    const newSlots = (totalSlots).filter((record) => record.isNew);


    let isButtonDisabled = false;
    if (availbleCertainHours){
      if (newSlots.find((slot) => !(slot.start && slot.end) )){
        isButtonDisabled = true
      }else if (!newSlots.length  && !alreadyPresentSlots.length){
        isButtonDisabled = true
      }
    }


    return (
        <Modal
          id="availabilitySelection"
          onManageDisableScrolling={onManageDisableScrolling}
          isOpen={this.props.isModalOpen}
          containerClassName={css.availabilityContainerClass}
          closeIconClassName={css.availabilityCloseIcon}
          scrollLayerClassName={css.availabilityScrolllayer}
          closeButtonClassName={css.availabilityCloseButton}
          closeTextClassName={css.availabilityCloseText}
          contentClassName={css.availabilityContentClass}
          onClose={() => {
            changeModalVisiblity(false);
          }}
        >
          <div className={css.modalcontainer}>
            <div className={css.modalHeader}>
              <h2>{date ? date.format('MMMM DD '): ''}</h2>
            </div>
            <Form   >
            <div className={css.modalContent}>
              {this.props.sameDayBookings && this.props.sameDayBookings.length ?
              <div className={css.bookedBlock}>
                        <>
                          <div>
                            <label className={css.guestBookingLabel}>Guest Booking</label>
                          </div>
                          {this.props.sameDayBookings.map(r => (
                            <div>
                                {/* <div className={css.userAvatar}></div> */}
                                  <div className={css.userDetails}>
                                    <span className={css.userTime}>Transaction id: {r.id.uuid}</span>
                                    <span className={css.userTime}>{moment(r.attributes.displayStart).format('MMM DD, hh:mm a')} - {moment(r.attributes.displayEnd).format('MMM DD, hh:mm a')}</span>
                                </div>
                             </div>
                          ))}
                        </>
              </div>: '' }
              {!(this.props.sameDayBookings && this.props.sameDayBookings.length) ?
              <div className={css.fullDayBooking}>
                <div className={classNames(css.customToggleInput, css.field)}>
                  <div className={css.toggleLabels}>
                    <label htmlFor="fullDayBooking">Block full day</label>
                  </div>
                  <div className={css.toggleInput}>
                    <Switch
                      onColor="#026786"
                      offColor="#b2b2b2"
                      onHandleColor="#ffffff"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={24}
                      width={40}
                      className="react-switch"
                      id="modalFullDayBlocked"
                      name="modalFullDayBlocked"
                      // label="Drive lah Go"
                      input={fullDayinput}
                      checked={this.state.modalFullDayBlocked}
                      onChange={this.onChangeFullDayBlocking}
                    />
                  </div>
                </div>
                <div className={css.infoText}>
                  <p>The entire day will not be available for guest to book.</p>
                </div>
              </div>: '' }

              <div className={css.fullDayBooking}>
                <div className={classNames(css.customToggleInput, css.field)}>
                  <div className={css.toggleLabels}>
                    <label htmlFor="instantBooking">Not available during certain hours</label>
                  </div>
                  <div className={css.toggleInput}>
                    <Switch
                      onColor="#026786"
                      offColor="#b2b2b2"
                      onHandleColor="#ffffff"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={24}
                      width={40}
                      className="react-switch"
                      id="availbleCertainHours"
                      name="availbleCertainHours"
                      // label="Drive lah Go"
                      checked={this.state.availbleCertainHours}
                      input={certainHourInput}
                      onChange={this.onChangeAvailbleCertainHours}
                    />
                  </div>
                </div>
                <div className={css.infoText}>
                  <p>
                    The listing will not be available during the time selected. It is recommended
                    to keep the listing available the entire day as it leads to much higher demand
                    and earnings.
                  </p>
                </div>


                { this.state.availbleCertainHours ?  (
                  <>
                    {/* already present slots */}
                    <div>
                    {alreadyPresentSlots.length ? (
                      <div className={css.presentSlots}>
                        {alreadyPresentSlots.map(object => {
                          return this.slotSelectionField(object);
                        })}
                      </div>
                    ) : null }
                    </div>

                    {/* This is Slot selection section important one */}
                    <div>
                        <div className={css.slotSelection}>
                        {newSlots &&
                          newSlots.map(object => {
                            return this.slotSelectionField(object);
                          })}
                      </div>
                      {(this.state.availbleCertainHours && canAddMoreSlots ) ?  <div className={css.addSlotFields} onClick={() => this.addAnotherSlot()}>
                        + Add another slot
                      </div>: '' }

                    </div>
                  </>) : null}
              </div>

              <div className={css.modalFooter}>
                <Button
                  className={css.submitButton}
                  // type="submit"
                  onClick={this.handleSubmit}
                  disabled={isButtonDisabled}
                  // id="saveAvailability"
                >
                  Save
                </Button>
              </div>
            </div>

            </Form>

          </div>
        </Modal>
    );
  }
}

EditListingAvailiblityCalenderForm.defaultProps = {
  className: null,
  rootClassName: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  isDayBlocked: () => false,
  isOutsideRange,
  isDayHighlighted: () => false,
  enableOutsideDays: true,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
  onMonthChanged: null,
};

EditListingAvailiblityCalenderForm.propTypes = {
  className: string,
  rootClassName: string,
  availability: shape({
    calendar: object.isRequired,
    onFetchAvailabilityExceptions: func.isRequired,
    onFetchBookings: func.isRequired,
    onDeleteAvailabilityException: func.isRequired,
    onCreateAvailabilityException: func.isRequired,
  }).isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
  onMonthChanged: func,
  onUpdateAvailability: func,
};

export default EditListingAvailiblityCalenderForm;
