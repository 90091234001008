import React from 'react';
import css from './SectionReview.css';
import approved from '../../assets/longTermLandingPage/approvedImage.png';
import insurance from '../../assets/longTermLandingPage/insuranceLogo.png';
import Income from '../../assets/landingPageNew/income_new.png';
import { TrustBox } from '../../components';

const SectionReview = () => {
  return (
    <div className={css.root}>
      <div className={css.content}>
        <div>
          <div className={css.title}>Our insurance partners</div>
          <img src={insurance} className={css.insurance} />
          <br />
          <img className={css.channelItemImage} src={Income} />
        </div>
        <div>
          <div className={css.title}>Approved by</div>
          <img src={approved} className={css.approvedBy} />
          <br />
          <TrustBox height={80} />
        </div>
      </div>
    </div>
  );
};

export default SectionReview;
