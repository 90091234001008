import React, { useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { array, arrayOf, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { createSlug, parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { Button, IconSpinner, ModalInMobile, NamedLink, PrimaryButton } from '../../components';
import { BookingTimeForm } from '../../forms';
import moment from 'moment';
import css from './BookingPanel.css';
import { types } from '../../util/sdkLoader';
import { getDatesFromUrlParams, momentTimeOfDayFromLocalToTimeZone } from '../../util/dates';

const { Money } = types;

const TODAY = new Date();
const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl, longTermRental) => {
  if (price && price.currency === config.currency) {
    const priceMaybe = longTermRental ? new Money(price.amount * 30, price.currency) : price;
    const formattedPrice = formatMoney(intl, priceMaybe);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    history,
    location,
    intl,
    search,
    currentUser,
    initialDate,
    onCheckingVoucher,
    checkedCode,
    checkCodeInProgress,
    checkCodeErorr,
    onResetCode,
    calculateMobileHeight,
    isLiveListing,
    onReadInsurance,
    requestButtonId,
    onFetchTimeSlots,
    onEstimateBreakdown,
    tripFeesExperiment,
    estimateBreakdownInProgress,
    estimatedTx,
    onOpenRentalAgreement,
    bookingConfig,
    estimateError,
    fetchTimeSlotsInProgress,
    localTimeZone,
    isLongTerm,
    isLongTermBooking,
    shouldShowPricePerMonth,
    formattedPrice,
    priceTitle,
    longTermRental,
    monthlyTimeSlots,
    transaction,
    updateBooking,
    onRequestToUpdateBooking,
    getChildTransactionData,
    isEditTrip,
    gtmEvents,
    searchQueryData
  } = props;
  const { price } = listing.attributes;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm =
    hasListingState && !isClosed && isLiveListing && !fetchTimeSlotsInProgress;
  const showClosedListingHelpText = listing.id && isClosed;
  // const { formattedPrice, priceTitle } = priceData(price, intl, shouldShowPricePerMonth);
  const isBook = !!parse(location.search).book;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;

  const isInstantBooking = listing && listing.attributes.publicData.instantBooking;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const bookingPanelMobileElem = window.document.getElementsByClassName(
        css.openBookingFormWrapper
      );
      const bookingPanelMobileHeight =
        bookingPanelMobileElem &&
        bookingPanelMobileElem[0] &&
        bookingPanelMobileElem[0].clientHeight;
      calculateMobileHeight(bookingPanelMobileHeight);
    }
  }, []);

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey =
    longTermRental && shouldShowPricePerMonth ? 'ListingPage.perMonth' : 'ListingPage.perDay';

  const classes = updateBooking
    ? classNames(rootClassName || css.updateBookingPanel)
    : classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const listingParams = {
    slug: createSlug(listing && listing.attributes.title),
    id: listing && listing.id.uuid,
  };

  const isPCar =
    false &&
    listing &&
    listing.id &&
    listing.attributes.publicData &&
    listing.attributes.publicData.vehicleType &&
    listing.attributes.publicData.vehicleType.match(/^P/);
  // todo: base on vehicle type, filter the timeslot

  const { startDate, endDate, pickupTime, dropoffTime } = initialDate;
  const bookingStartDateMaybe = startDate ? startDate : null;
  const bookingEndDateMaybe = endDate ? endDate : null;
  const pickupTimeToDate = pickupTime ? moment(pickupTime, 'hh:mm a').toDate() : null;

  const dropoffTimeToDate = dropoffTime ? moment(dropoffTime, 'hh:mm a').toDate() : null;
  let bookingStartTimeMaybe = null;
  let bookingEndTimeMaybe = null;
  if (pickupTimeToDate && bookingStartDateMaybe) {
    bookingStartTimeMaybe = bookingStartDateMaybe.setHours(
      pickupTimeToDate.getHours(),
      pickupTimeToDate.getMinutes()
    );
  }

  if (dropoffTimeToDate && bookingEndDateMaybe) {
    bookingEndTimeMaybe = bookingEndDateMaybe.setHours(
      dropoffTimeToDate.getHours(),
      dropoffTimeToDate.getMinutes()
    );
  }

  const initialValues = {
    bookingStartDate: bookingStartDateMaybe ? { date: bookingStartDateMaybe } : null,
    bookingEndDate: bookingEndDateMaybe ? { date: bookingEndDateMaybe } : null,
    bookingStartTime: bookingStartTimeMaybe
      ? momentTimeOfDayFromLocalToTimeZone(bookingStartTimeMaybe, localTimeZone).format('x')
      : null,
    bookingEndTime: bookingEndTimeMaybe
      ? momentTimeOfDayFromLocalToTimeZone(bookingEndTimeMaybe, localTimeZone).format('x')
      : null,
  };

  const _handleLocationStates = locationParams => {
    if (
      location.state &&
      location.state.startDate &&
      location.state.startDate &&
      !moment(location.state.startDate).isValid() &&
      !moment(location.state.endDate).isValid()
    ) {
      const queryMaybe = parse(location.search);
      const resp = getDatesFromUrlParams(queryMaybe.dates, queryMaybe.hours);
      let passLocationStates = locationParams.state;
      passLocationStates = {
        ...passLocationStates,
        startDate: resp.start instanceof Date ? resp.start : resp.start.toDate(),
        endDate: resp.end instanceof Date ? resp.end : resp.end.toDate(),
        params: listingParams,
        isFromListingPage: true,
      };
      return passLocationStates;
    } else {
      return {
        ...locationParams.state,
        params: listingParams,
        isFromListingPage: true,
      };
    }
  };

  const isNewCar = get(listing, 'attributes.metadata.isNewCar');

  // Getting all the possibilities of driving license issue date and driving license experience
  const numberOfYearsOfDrivingLicenseExperience = get(currentUser, "attributes.profile.privateData.numberOfYearsOfDrivingLicenseExperience", null);
  const drivingLicenseRegistrationDate = get(currentUser, "attributes.profile.privateData.drivingLicenseRegistrationDate", null);
  const drivingForeignLicenseRegistrationDate = get(currentUser, "attributes.profile.privateData.drivingForeignLicenseRegistrationDate", null);
  const drivingLicenseIssue = get(currentUser, "attributes.profile.protectedData.drivingLicense.drivingLicenseIssue", null);

  // Calculating the users age
  const userAge = () => {
    let age = get(currentUser, "attributes.profile.protectedData.age", null) || get(currentUser, "attributes.profile.metadata.intercomUserStat.age", null);
    if (age) return age;
    const dob = get(currentUser, "attributes.profile.protectedData.dateOfBirth", null);
    if (dob) {
      age = moment().diff(moment(dob), 'years');
      return age;
    }
  }

  // Calulating driving License Age i.e. user's driving experience
  const drivingLicenseAge = (...dates) => {
    const dlAges = [numberOfYearsOfDrivingLicenseExperience]
    dates.filter(Boolean).forEach(d => {
      const dateObj = moment(d, ['YYYY-MM-DD', 'DD/MM/YYYY', 'MM-DD-YYYY', 'MM/DD/YYYY', 'DD-MM-YYYY', 'D/M/YYYY', 'M-D-YYYY', 'D-M-YYYY', 'YYYY/M/DD']);
      if (dateObj.isValid()) dlAges.push(moment().diff(dateObj, 'years'));
    });
    return Math.max(...dlAges);
  };

  // Setting flag for cdg and komoco cars.
  const cdgKomocoCars = get(listing, "attributes.publicData.isYoungDriverNotAllowed", null);

  // Storing user's driving experience.
  const drivingExperience = drivingLicenseAge(drivingLicenseRegistrationDate, drivingForeignLicenseRegistrationDate, drivingLicenseIssue);

  // Setting a flag if user is allowed to book that certain car or not.
  const isAllowedToBook = () => {
    if (!cdgKomocoCars) return true;
    if (cdgKomocoCars && userAge() >= 22 && drivingExperience >= 2) return true;
    return false;
  };

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
          </div>
        </div>

        {!updateBooking && (
          <div className={css.bookingHeading}>
            <h2 className={titleClasses}>{title}</h2>
            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
          </div>
        )}
        {updateBooking && (
          <div>
            <h3 className={css.updateBookingTitle}>
              <FormattedMessage id="BookingPanel.updateBookingTitle" />
            </h3>
            <p className={css.updateBookingSubTitle}>
              <FormattedMessage id="BookingPanel.updateBookingSubTitle" />
            </p>
            <p className={css.updateBookingSubTitle}>
              <FormattedMessage id="BookingPanel.updateBookingSubTitle2" />
            </p>
          </div>
        )}
        {showBookingDatesForm && !updateBooking ? (
          <BookingTimeForm
            currentUser={currentUser}
            timeSlots={timeSlots}
            monthlyTimeSlots={monthlyTimeSlots}
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            searchQueryData={searchQueryData}
            isOwnListing={isOwnListing}
            listingId={listing.id}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            listing={listing}
            onResetCode={onResetCode}
            checkedCode={checkedCode}
            checkCodeErorr={checkCodeErorr}
            checkCodeInProgress={checkCodeInProgress}
            onCheckingVoucher={onCheckingVoucher}
            onEstimateBreakdown={onEstimateBreakdown}
            tripFeesExperiment={tripFeesExperiment}
            estimateBreakdownInProgress={estimateBreakdownInProgress}
            estimatedTx={estimatedTx}
            requestButtonId={requestButtonId}
            listingParams={listingParams}
            onManageDisableScrolling={onManageDisableScrolling}
            onReadInsurance={onReadInsurance}
            onOpenRentalAgreement={onOpenRentalAgreement}
            bookingConfig={bookingConfig}
            initialDate={initialDate}
            estimateError={estimateError}
            initialValues={initialValues}
            localTimeZone={localTimeZone}
            isNewCar={isNewCar}
            isLongTerm={isLongTerm}
            isLongTermBooking={isLongTermBooking || shouldShowPricePerMonth}
            transaction={transaction}
            updateBooking={updateBooking}
            onRequestToUpdateBooking={onRequestToUpdateBooking}
            getChildTransactionData={getChildTransactionData}
            location={location}
            gtmEvents={gtmEvents}
            isAllowedToBook={isAllowedToBook()}
          />
        ) : fetchTimeSlotsInProgress ? (
          <div className={css.loadingTimeSlotWrapper}>
            <IconSpinner />
          </div>
        ) : null}
      </ModalInMobile>
      {showBookingDatesForm && updateBooking ? (
        <BookingTimeForm
          currentUser={currentUser}
          timeSlots={timeSlots}
          monthlyTimeSlots={monthlyTimeSlots}
          className={css.bookingForm}
          formId="BookingPanel"
          submitButtonWrapperClassName={css.submitButtonWrapper}
          unitType={unitType}
          onSubmit={onSubmit}
          price={price}
          isOwnListing={isOwnListing}
          listingId={listing.id}
          onFetchTimeSlots={onFetchTimeSlots}
          startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          timeZone={timeZone}
          listing={listing}
          onResetCode={onResetCode}
          checkedCode={checkedCode}
          checkCodeErorr={checkCodeErorr}
          checkCodeInProgress={checkCodeInProgress}
          onCheckingVoucher={onCheckingVoucher}
          onEstimateBreakdown={onEstimateBreakdown}
          tripFeesExperiment={tripFeesExperiment}
          estimateBreakdownInProgress={estimateBreakdownInProgress}
          estimatedTx={estimatedTx}
          requestButtonId={requestButtonId}
          listingParams={listingParams}
          onManageDisableScrolling={onManageDisableScrolling}
          onReadInsurance={onReadInsurance}
          onOpenRentalAgreement={onOpenRentalAgreement}
          bookingConfig={bookingConfig}
          initialDate={initialDate}
          estimateError={estimateError}
          initialValues={initialValues}
          localTimeZone={localTimeZone}
          isNewCar={isNewCar}
          isLongTerm={isLongTerm}
          isLongTermBooking={isLongTermBooking || shouldShowPricePerMonth}
          transaction={transaction}
          updateBooking={updateBooking}
          onRequestToUpdateBooking={onRequestToUpdateBooking}
          getChildTransactionData={getChildTransactionData}
          location={location}
          gtmEvents={gtmEvents}
          isAllowedToBook={isAllowedToBook()}
        />
      ) : fetchTimeSlotsInProgress ? (
        <div className={css.loadingTimeSlotWrapper}>
          <IconSpinner />
        </div>
      ) : null}
      {!updateBooking && (
        <div className={css.openBookingFormWrapper}>
          <div className={css.openBookingForm}>
            {isAllowedToBook() && (
            <div className={css.priceContainer}>
              <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            </div>
            )}

            {isLiveListing ? (
              <div>
                {isAllowedToBook() ? (
                <p className={css.smallPrintForMember}>
                  <FormattedMessage id="BookingPanel.memberUseDrivelah" />
                </p>
                ) : (
                <p className={css.error} style={{paddingTop:"5px"}}>
                  <span style={{marginRight:"5px"}}>
                        <svg id="Group_5243" data-name="Group 5243" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22 22">
                          <g id="Ellipse_1207" data-name="Ellipse 1207" transform="translate(0 0)" fill="none" stroke="red" stroke-width="2">
                            <circle cx="11" cy="11" r="11" stroke="none" />
                            <circle cx="11" cy="11" r="10" fill="none" />
                          </g>
                          <path id="Path_4525" data-name="Path 4525" d="M1.712-3.168a.689.689,0,0,1-.776-.748L.77-9.234a.659.659,0,0,1,.748-.776H2.46a.659.659,0,0,1,.748.776L3.041-3.916a.689.689,0,0,1-.776.748ZM.895-1.423a.673.673,0,0,1,.762-.762h.665a.673.673,0,0,1,.762.762v.554a.673.673,0,0,1-.762.762H1.656A.673.673,0,0,1,.895-.869Z" transform="translate(9.01 16.059)" fill="red" />
                        </svg>
                  </span>
                  Guests aged 22+ with 2+ years of driving experience are eligible to book this car. Please select another car.
                </p>
                )}

                {currentUser && currentUser.id ? (
                  <Button
                    rootClassName={css.bookButton}
                    disabled={!currentUser.canRequestBooking || !isAllowedToBook()}
                    onClick={() => {
                      openBookModal(isOwnListing, isClosed, history, location);
                    }}
                  >
                    <FormattedMessage id="BookingPanel.ctaButtonMessage" />
                  </Button>
                ) : (
                  <NamedLink
                    name="LoginPage"
                    to={{
                      state: _handleLocationStates(location),
                      // state: { ...location.state, params: listingParams, isFromListingPage: true },
                    }}
                  >
                    <PrimaryButton type="button">
                      <FormattedMessage id="BookingPanel.ctaButtonMessage" />
                    </PrimaryButton>
                  </NamedLink>
                )}

                {!isInstantBooking && (
                  <p className={css.smallPrint}>
                    <FormattedMessage id="BookingPanel.noCharge" />
                  </p>
                )}
              </div>
            ) : (
              <div className={css.closedListingButton}>
                <FormattedMessage id="ListingPage.listingNotLive" />
              </div>
            )}
            {isClosed ? (
              <div className={css.closedListingButton}>
                <FormattedMessage id="BookingPanel.closedListingButtonText" />
              </div>
            ) : null}
          </div>
          {isOwnListing && (
            <p className={css.smallPrint}>
              <FormattedMessage id="BookingPanel.ownListing" />
            </p>
          )}
        </div>
      )}
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  isLiveListing: false,
  monthlyTimeSlots: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: oneOfType([arrayOf(propTypes.timeSlot), array]),
  monthlyTimeSlots: object,
  fetchTimeSlotsError: propTypes.error,
  isLiveListing: bool,
  onReadInsurance: func,
  requestButtonId: string,
  tripFeesExperiment: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  isLongTerm: bool,
  isLongTermBooking: bool,
};

export default compose(withRouter, injectIntl)(BookingPanel);
