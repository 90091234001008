import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingPoliciesForm } from '../../forms';

import css from './EditListingPoliciesPanel.css';

const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    submitButtonId,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPoliciesPanel.panelDescription" />
  );
  const title = <FormattedMessage id="EditListingPoliciesPanel.panelTitle" />;
  const panelDescription = <FormattedMessage id="EditListingPoliciesPanel.panelDescription" />;

  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      <div className={css.titleSection}>
        <h1 className={css.title}>{title}</h1>
        <p>{panelDescription}</p>
      </div>
      <EditListingPoliciesForm
        className={css.form}
        publicData={publicData}
        initialValues={{
          rules: publicData.rules,
          keyRules: publicData.keyRules,
          millagePerDay: Boolean(publicData.millagePerDay) ? publicData.millagePerDay : 999,
        }}
        onSubmit={values => {
          const { rules = '', keyRules = [], millagePerDay = '' } = values;
          const millage = !millagePerDay || millagePerDay === '' ? 999 : millagePerDay;
          
          const updateValues = {
            publicData: {
              canDriveToMalaysia: !keyRules.find(
                item => item === 'Not permitted to drive to Malaysia'
              ),
              rules,
              keyRules,
              millagePerDay: millage,
              isPetFriendly: !keyRules.find(r => r === 'No Pets'),
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        submitButtonId={submitButtonId}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
};

export default EditListingPoliciesPanel;
