import {
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ACCEPTED_PENDING_STATE,
  TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
  TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED,
  TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING,
  TRANSITION_ADMIN_CANCEL_NON_REFUND,
  TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_REFUND,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_ADMIN_REFUND,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_AUTO_COMPLETE,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_PICK_UP_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING,
  TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
  TRANSITION_DECLINE,
  TRANSITION_DISPUTE,
  TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_ENQUIRE,
  TRANSITION_EXPIRE,
  TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_EXPIRE_PICK_UP_CONFIRMED,
  TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR,
  TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_MARK_RESOLVED,
  TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING,
  TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE,
  TRANSITION_PROVIDER_CANCEL_REFUNDABLE,
  TRANSITION_REQUEST,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_CONFIRMED,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_DECLINED,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_CONFIRMED,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_DECLINED,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE,
  TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT,
  TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE,
  TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT,
  TRANSITION_UNVERIFIED_EXPIRE,
  TRANSITION_UNVERIFIED_EXPIRE_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED,
  TRANSITION_UNVERIFIED_VERIFICATION_DECLINE,
  TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN,
  TRANSITION_UNVERIFIED_WITHDRAW,
  TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
  TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT,
  TRANSITION_WITHDRAW,
  TRANSITION_WITHDRAW_BY_ADMIN,
  TRANSITION_ACCEPT_BY_ADMIN,
  TRANSTION_TRIP_STARTED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_TRIP_STARTED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_TRIP_PICK_UP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_TRIP_PICKUP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE,
} from '../../util/transaction';
import {
  TRANSITION_LTF_ACCEPT,
  TRANSITION_LTF_CONFIRM_PAYMENT,
  TRANSITION_LTF_CONFIRM_PICK_UP,
  TRANSITION_LTF_CUSTOMER_CANCEL,
  TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE,
  TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED,
  TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED,
  TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_LTF_PROVIDER_CANCEL,
  TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE,
  TRANSITION_LTF_REQUEST_PICK_UP,
  TRANSITION_LTF_WITHDRAW,
} from '../../util/transactionLongTermFirst';
import {
  TRANSITION_LTL_CONFIRM_DROP_OFF,
  TRANSITION_LTL_CONFIRM_PAYMENT,
  TRANSITION_LTL_CUSTOMER_CANCEL,
  TRANSITION_LTL_REQUEST_DROP_OFF,
  TRANSITION_LTL_TRIP_END,
} from '../../util/transactionLongTermLast';
import { getLastUpdateObj } from '../../util/updateTransaction';
import {
  TRANSITION_LTM_CONFIRM_PAYMENT,
  TRANSITION_LTM_CUSTOMER_CANCEL,
} from '../../util/transactionLongTermMiddle';
import moment from 'moment';

export const getNextInteractableTransition = (transaction, isProvider) => {
  if (!transaction || !transaction.attributes || !transaction.attributes.lastTransition) return [];
  const lastTransition = transaction.attributes.lastTransition;
  switch (lastTransition) {
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE];
      }
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
      if (isProvider) {
        return [TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE];
      } else {
        return [];
      }
    case TRANSITION_ACCEPT:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED];
      }
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE];
      }
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_DROP_OFF];
      }
    case TRANSITION_REQUEST_DROP_OFF:
      if (isProvider) {
        return [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE];
      } else {
        return [];
      }
    case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
      if (isProvider) {
        return [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE];
      } else {
        return [];
      }
    case TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTF_REQUEST_PICK_UP];
      }
    case TRANSITION_LTF_REQUEST_PICK_UP:
      if (isProvider) {
        return [TRANSITION_LTF_CONFIRM_PICK_UP];
      } else {
        return [];
      }
    case TRANSITION_LTL_TRIP_END:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTL_REQUEST_DROP_OFF];
      }
    case 'transition/long-term-last-admin-transit-trip-end': // just for test
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTL_REQUEST_DROP_OFF];
      }
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      if (isProvider) {
        return [TRANSITION_LTL_CONFIRM_DROP_OFF];
      } else {
        return [];
      }
    case TRANSITION_CONFIRM_PAYMENT_UNVERIFIED:
      if (isProvider) {
        // return [TRANSITION_ACCEPT, TRANSITION_DECLINE];
        return []; //Resue the old accept and decline button
      } else {
        return [TRANSITION_UNVERIFIED_VERIFICATION_SENT];
      }
    case TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED:
      if (isProvider) {
        // return [TRANSITION_ACCEPT, TRANSITION_DECLINE];
        return []; //Resue the old accept and decline button
      } else {
        return [TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT];
      }

    default:
      return [];
  }
};

export const getNextCancelTransition = (transaction, isProvider) => {
  if (!transaction || !transaction.attributes || !transaction.attributes.lastTransition) return [];
  const lastTransition = transaction.attributes.lastTransition;

  switch (lastTransition) {
    case TRANSITION_REQUEST:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_ACCEPTED_PENDING_STATE:
    case TRANSITION_CONFIRM_PAYMENT:
      if (isProvider) {
        // return [TRANSITION_ACCEPT, TRANSITION_DECLINE];
        return []; //Resue the old accept and decline button
      } else {
        return [TRANSITION_WITHDRAW];
      }
    case TRANSITION_CONFIRM_PAYMENT_UNVERIFIED:
      if (isProvider) {
        // return [TRANSITION_ACCEPT, TRANSITION_DECLINE];
        return []; //Resue the old accept and decline button
      } else {
        return [TRANSITION_UNVERIFIED_WITHDRAW];
      }
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING];
      }

    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_ACCEPT:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_REFUNDABLE];
      }
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR];
      }
    case TRANSITION_CONFIRM_PAYMENT_UNVERIFIED:
      if (isProvider) {
        // return [TRANSITION_ACCEPT, TRANSITION_DECLINE];
        return []; //Resue the old accept and decline button
      } else {
        return [TRANSITION_UNVERIFIED_WITHDRAW];
      }
    case TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED:
      if (isProvider) {
        // return [TRANSITION_ACCEPT, TRANSITION_DECLINE];
        return []; //Resue the old accept and decline button
      } else {
        return [TRANSITION_UNVERIFIED_WITHDRAW_INSTANT];
      }
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_REFUNDABLE];
      }
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_REFUNDABLE];
      }

    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE];
      }

    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE];
      }
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_DROP_OFF:
      return [];
    case TRANSITION_LTF_CONFIRM_PAYMENT:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTF_WITHDRAW];
      }
    case TRANSITION_LTF_ACCEPT:
      if (isProvider) {
        return [TRANSITION_LTF_PROVIDER_CANCEL];
      } else {
        return [TRANSITION_LTF_CUSTOMER_CANCEL];
      }
    case TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE:
      if (isProvider) {
        return [TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE];
      } else {
        return [TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE];
      }
    case TRANSITION_LTF_REQUEST_PICK_UP:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED];
      }
    case TRANSITION_LTF_CONFIRM_PICK_UP:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED];
      }
    case TRANSITION_LTM_CONFIRM_PAYMENT:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTM_CUSTOMER_CANCEL];
      }
    case TRANSITION_LTL_CONFIRM_PAYMENT:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTL_CUSTOMER_CANCEL];
      }
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE];
      }
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_REFUNDABLE];
      }
    default:
      return [];
  }
};

export const getTransitButtonLabelId = transition => {
  switch (transition) {
    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_LTF_WITHDRAW:
      return 'TransactionPanel.withdrawButton';
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_LTF_PROVIDER_CANCEL:
    case TRANSITION_LTL_CUSTOMER_CANCEL:
    case TRANSITION_LTM_CUSTOMER_CANCEL:
      return 'TransactionPanel.cancel';
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_LTF_REQUEST_PICK_UP:
      return 'TransactionPanel.pickup';

    case TRANSTION_TRIP_STARTED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_TRIP_STARTED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_TRIP_PICK_UP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_TRIP_PICKUP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE:
      return 'TransactionPanel.pickupAsAdmin';
  
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_LTF_CONFIRM_PICK_UP:
      return 'TransactionPanel.confirmPickup';
    case TRANSITION_REQUEST_DROP_OFF:
    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      return 'TransactionPanel.dropoff';
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_LTL_CONFIRM_DROP_OFF:
      return 'TransactionPanel.confirmDropoff';
    case TRANSITION_DISPUTE:
      return 'TransactionPanel.dispute';
    default:
      return null;
  }
};

export const getTransitButtonPolicyId = transition => {
  switch (transition) {
    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_LTF_WITHDRAW:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_LTF_PROVIDER_CANCEL:
    case TRANSITION_LTL_CUSTOMER_CANCEL:
    case TRANSITION_LTM_CUSTOMER_CANCEL:
      return 'TransactionPanel.cancelPolicy';
    default:
      return null;
  }
};

export const getTransitErrorLabelId = transition => {
  switch (transition) {
    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_LTF_WITHDRAW:
      return 'TransactionPanel.withdrawError';
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED:
    case TRANSITION_LTF_PROVIDER_CANCEL:
    case TRANSITION_LTL_CUSTOMER_CANCEL:
    case TRANSITION_LTM_CUSTOMER_CANCEL:
      return 'TransactionPanel.cancelError';
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_LTF_REQUEST_PICK_UP:
      return 'TransactionPanel.pickupError';
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_LTF_CONFIRM_PICK_UP:
      return 'TransactionPanel.confirmPickupError';
    case TRANSITION_REQUEST_DROP_OFF:
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      return 'TransactionPanel.dropoffError';
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_LTL_CONFIRM_DROP_OFF:
      return 'TransactionPanel.confirmDropoffError';
    case TRANSITION_DISPUTE:
      return 'TransactionPanel.disputeError';
    default:
      return 'TransactionPanel.pickupError';
  }
};

export const computedGuestTripStatusMessages = (
  lastTx,
  hostName,
  startDate,
  startTime,
  homeDelivery
) => {
  let result = {
    heading: '',
    message: '',
    pickupChecklist: false,
    bookAnotherCar: false,
    callUsNow: false,
    referNow: false,
  };
  let name = 'Drive lah';

  switch (lastTx) {
    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE:
      result.heading = 'Request sent';
      result.message =
        'You will only be charged and notified once the host has accepted the booking.';
      return result;

    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
      if (homeDelivery) {
        result.heading = 'Booking confirmed';
        result.message = `Your booking has been confirmed. ${hostName} will be delivering the car at your location by ${startDate} ${startTime}. Ensure your are aware of the pickup process.`;
      } else {
        result.heading = 'Booking confirmed';
        result.message =
          'Your booking has been confirmed. Ensure you are at the location at the time of the pickup and are aware of the pickup process.';
      }

      result.pickupChecklist = true;
      return result;

    case TRANSITION_DECLINE:
    case TRANSITION_EXPIRE:
      result.heading = 'Booking declined';
      result.message =
        'Your booking request has been declined by the host. A full refund has been initiated. We apologise for the inconvenience.';
      result.bookAnotherCar = true;
      return result;

    case TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE:
    case TRANSITION_UNVERIFIED_VERIFICATION_DECLINE:
    case TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT:
      result.heading = 'Verification declined';
      result.message =
        'We were not able to verify your account and hence have to decline the booking. Reach out to our support team to learn more.';
      result.callUsNow = true;
      return result;

    case TRANSITION_UNVERIFIED_EXPIRE:
      result.heading = 'Booking failed';
      result.message =
        'We were not able to verify your account and hence have to decline the booking. Reach out to our support team to learn more.';
      result.callUsNow = true;
      return result;

    case TRANSITION_UNVERIFIED_EXPIRE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE:
    case TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE:
      result.heading = 'Booking failed';
      result.message =
        'Your verification could not be completed. Reach out to our support team to learn more.';
      result.callUsNow = true;
      return result;

    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING:
      result.heading = 'Booking cancelled';
      result.message = 'You cancelled the booking.';

      return result;

    case TRANSITION_WITHDRAW_BY_ADMIN:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_ADMIN_CANCEL_NON_REFUND:
    case TRANSITION_ADMIN_CANCEL_REFUND:
    case TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_ADMIN_REFUND:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE:
      result.heading = 'Booking cancelled';
      result.message = `Booking cancelled by ${name}. Reach out to our support team to learn more.`;
      result.callUsNow = true;
      return result;

    case TRANSITION_ACCEPT:
    case TRANSITION_ACCEPT_BY_ADMIN:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
      if (homeDelivery) {
        result.heading = 'Booking confirmed';
        result.message = `Your booking has been confirmed. ${hostName} will be delivering the car at your location by ${startDate} ${startTime}. Ensure your are aware of the pickup process.`;
      } else {
        result.heading = 'Booking confirmed';
        result.message = `Your booking has been confirmed. Ensure you are at the location by ${startDate} ${startTime} and are aware of the pickup process.`;
      }

      result.pickupChecklist = true;
      return result;

    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING:
      result.heading = 'Booking cancelled';
      result.message =
        'Your booking request has been cancelled by the host. A full refund has been initiated. We apologise for the inconvenience.';
      result.bookAnotherCar = true;
      return result;

    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
      result.heading = 'Booking cancelled';
      result.message = 'You cancelled the booking. This is a refundable cancellation.';
      return result;

    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
      result.heading = 'Booking cancelled';
      result.message = 'You cancelled the booking. This is a non-refundable cancellation.';
      return result;

    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
      result.heading = 'Waiting for host to confirm';
      result.message = `Pickup request has been sent to ${hostName} who will review and confirm it any minute now.`;
      return result;

    case TRANSITION_UNVERIFIED_VERIFICATION_SENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN:
      result.heading = 'Verification pending';
      result.message =
        'Your verification is in progress. Once completed, your request will be sent to the host.';
      return result;

    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN:
      result.heading = 'Verification pending';
      result.message =
        'Your verification is in progress. Once completed, your booking will be confirmed.';
      return result;

    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
      if (homeDelivery) {
        result.heading = 'Ongoing';
        result.message = `Enjoy your trip and ensure you return at your location by ${startDate} ${startTime} where ${hostName} will be waiting to receive the car. `;
      } else {
        result.heading = 'Ongoing';
        result.message = `Enjoy your trip and make sure you return the car by ${startDate} ${startTime}`;
      }
      return result;

    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_CONFIRMED:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_REVIEW_1_BY_PROVIDER:
      result.heading = 'Review trip';
      result.message =
        'Your trip has been completed. Please take 2 minutes to review your host as it will be helpful to future guests.';
      return result;

    case TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_REQUEST_DROP_OFF:
      result.heading = 'Waiting for host to confirm';
      result.message = `Drop-off request has been sent to ${hostName} who will review and confirm it any minute now.`;
      return result;

    case TRANSITION_DISPUTE:
      result.heading = 'Disputed';
      result.message =
        'This trip has been disputed by the host. Contact our team to find out more.';
      return result;

    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD:
    case TRANSITION_MARK_RESOLVED:
      result.heading = 'Completed';
      result.message =
        'Hope you had a great trip with us. Refer a friend and get $20 off your next trip.';
      result.referNow = true;
      return result;

    case TRANSITION_EXPIRE_PAYMENT:
      result.heading = 'Payment failed';
      result.message = 'Your payment was unsuccessful. Please try again.';
      return result;

    case TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED:
    case TRANSITION_CONFIRM_PAYMENT_UNVERIFIED:
      result.heading = '';
      result.message = '';
      return result;

    case TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT:
      result.heading = 'Modification requested';
      result.message = `${hostName} will check the request and accept it anytime.`;
      return result;

    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT:
      result.heading = 'Modification accepted';
      result.message = `Your revised trip timings are ${startDate} ${startTime}. Have a great trip!`;
      return result;

    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
      result.heading = 'Modification expired';
      result.message = `Your request got expired as Host did not respond and original Trip timings are retained. You may reach out to the Host and create a new request.`;
      return result;

    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN:
      result.heading = 'Modification declined';
      result.message = `Your request is declined by Drive lah team and original trip timings are retained. This usually happens when Host's car is not available. Reach out to Drive lah team to know more.`;
      result.callUsNow = true;
      return result;

    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE:
      result.heading = 'Modification declined';
      result.message = `Your request is declined by the Host and original trip timings are retained. This usually happens when the car is not available. You may reach out to the Host to know about car availability and send a new request.`;
      return result;

    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE:
      result.heading = 'Modification expired';
      result.message = `Your request got expired as Host did not respond and original Trip timings are retained. You may reach out to the Host and create a new request.`;
      return result;
  }

  return result;
};

export const computedHostTripStatusMessages = (
  lastTx,
  startDate,
  startTime,
  guestName,
  endDate,
  endTime,
  totalPriceHost,
  pickupAddress,
  homeDelivery
) => {
  let result = {
    heading: '',
    message: '',
    pickupChecklist: false,
    bookAnotherCar: false,
    callUsNow: false,
    referNow: false,
  };

  let name = 'Drive lah';
  switch (lastTx) {
    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE:
      if (homeDelivery) {
        result.heading = 'Booking request received';
        result.message = `Home delivery trip. Please proceed to accept ${guestName}’s  booking request. You will earn ${totalPriceHost}. Declining or not responding will negatively impact your car’s positioning on the platform.`;
      } else {
        result.heading = 'Booking request received';
        result.message = `Please proceed to accept ${guestName}’s  booking request. You will earn ${totalPriceHost}. Declining or not responding will negatively impact your car’s positioning on the platform.`;
      }

      return result;

    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_ACCEPT:
    case TRANSITION_ACCEPT_BY_ADMIN:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      if (homeDelivery) {
        result.heading = 'Booking confirmed';
        result.message = `Please deliver the car at <guest address> before <pick up date & time>`;
      } else {
        result.heading = 'Booking confirmed';
        result.message = `${guestName} will pick up your car on ${startDate} ${startTime} from ${pickupAddress}`;
      }

      return result;

    case TRANSITION_DECLINE:
      result.heading = 'Booking declined';
      result.message =
        'You declined the booking request. Frequent declines & cancellations may lead to the removal of your listing.';
      return result;

    case TRANSITION_EXPIRE:
      result.heading = 'Booking expired';
      result.message = `Booking expired as you didn't take any action. Frequent declines & cancellations may lead to the removal of your listing.`;
      return result;

    case TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE:
    case TRANSITION_UNVERIFIED_VERIFICATION_DECLINE:
    case TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_EXPIRE:
    case TRANSITION_UNVERIFIED_EXPIRE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE:
    case TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN:
      result.heading = '';
      result.message = '';
      return result;

    case TRANSITION_WITHDRAW:
      result.heading = 'Booking cancelled';
      result.message = `${guestName} has cancelled the booking.`;
      return result;

    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING:
      result.heading = 'Booking cancelled';
      result.message = `You cancelled the booking request. Frequent declines & cancellations may lead to the removal of your listing.`;
      return result;

    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING:
      result.heading = 'Booking cancelled';
      result.message = `${guestName} has cancelled the booking. We apologise for the inconvenience.`;
      return result;

    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
      result.heading = 'Confirm pick-up';
      result.message = `${guestName} has sent a pick up request. Review & accept to commence insurance.`;
      return result;

    case TRANSITION_WITHDRAW_BY_ADMIN:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_ADMIN_CANCEL_NON_REFUND:
    case TRANSITION_ADMIN_CANCEL_REFUND:
    case TRANSITION_ADMIN_REFUND:
    case TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
      result.heading = 'Booking cancelled';
      result.message = `Booking cancelled by ${name}. Reach out to our support team to learn more.`;
      result.callUsNow = true;
      return result;

    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
      result.heading = 'Ongoing';
      result.message = `${guestName} is on the trip. Drop off is scheduled on ${endDate} at ${endTime}`;
      return result;

    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_CONFIRMED:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      result.heading = 'Review trip';
      result.message =
        'Your trip has been completed. Please take 2 minutes to review your guest as it will be helpful to future hosts.';
      return result;

    case TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_REQUEST_DROP_OFF:
      result.heading = 'Confirm drop-off';
      result.message = `${guestName} has sent a drop-off request. Review & accept for insurance to apply.`;
      return result;

    case TRANSITION_DISPUTE:
      result.heading = 'Disputed';
      result.message = 'You have disputed this trip. Please provide all details to our team.';
      return result;

    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD:
    case TRANSITION_MARK_RESOLVED:
      result.heading = 'Completed';
      result.message = 'Hope you had a great hosting experience with us. Keep up the great work.';
      result.referNow = true;
      return result;

    case TRANSITION_REVIEW_1_BY_PROVIDER:
      result.heading = 'Completed';
      result.message =
        'Hope you had a great hosting experience with us. Keep up the great work. Guest review pending';
      result.referNow = true;
      return result;

    case TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
      result.heading = 'Modification request received';
      result.message = `${guestName} has requested to modify the trip and the modified trip timings are ${endDate} ${endTime}. Please accept for our friend to be able to enjoy and drive your car more.`;
      return result;

    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
      result.heading = 'Modification accepted';
      result.message = `Revised trip timings are ${endDate} ${endTime}.`;
      return result;

    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
      result.heading = 'Modification accepted';
      result.message = `Trip modification request was accepted by Drive lah team and revised trip timings are ${endDate} ${endTime} . Any questions? Reach out to Drive lah team.`;
      result.callUsNow = true;
      return result;

    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN:
      result.heading = 'Modification declined';
      result.message = `Trip modification request was declined by Drive lah team and original trip timings are retained . Any questions? Reach out to Drive lah team.`;
      result.callUsNow = true;
      return result;

    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
      result.heading = 'Modification declined';
      result.message = `Trip modification request was declined by Drive lah team and original trip timings are retained . Any questions? Reach out to Drive lah team.`;
      return result;

    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE:
      result.heading = 'Modification expired';
      result.message = `Request got expired as you didn't take any action. We encourage reaching out to the Guest and discuss your car availability so that they can send a new request.`;
      return result;

    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE:
      result.heading = 'Modification declined';
      result.message = `You declined the request and original trip timings are retained. We encourage reaching out to the Guest and discuss your car availability for a successful Trip modification.`;
      return result;

    case TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT:
      result.heading = 'Modification request received';
      result.message = `${guestName} has requested to modify the trip and the modified trip timings are <RevisedDate&Time>. Please accept for guest to be able to enjoy and drive your car more.`;
      return result;
  }

  return result;
};

export const computedHostLocationMessages = (lastTx, homeDelivery) => {
  let result = '';
  switch (lastTx) {
    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_ACCEPT:
    case TRANSITION_ACCEPT_BY_ADMIN:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
      if (homeDelivery) {
        result = 'You will need to deliver and pick up the car from here';
      } else {
        result = 'Guest will arrive here for pick up.';
      }

      break;

    case TRANSITION_DECLINE:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE:
    case TRANSITION_UNVERIFIED_VERIFICATION_DECLINE:
    case TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_EXPIRE:
    case TRANSITION_UNVERIFIED_EXPIRE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE:
    case TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE:
    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_WITHDRAW_BY_ADMIN:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_CONFIRMED:
    case TRANSITION_REQUEST_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_DISPUTE:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_ADMIN_CANCEL_NON_REFUND:
    case TRANSITION_ADMIN_CANCEL_REFUND:
    case TRANSITION_ADMIN_REFUND:
    case TRANSITION_ENQUIRE:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
      result = '';
      break;

    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT:
      if (homeDelivery) {
        result = 'You will need to deliver and pick up the car from here';
      } else {
        result = '';
      }
      break;
  }

  return result;
};

export const computedGuestLocationMessages = (lastTx, startTimeDiff, endTimeDiff, homeDelivery) => {
  let result = {
    showIcon: false,
    message: '',
  };
  switch (lastTx) {
    case TRANSITION_DECLINE:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE:
    case TRANSITION_UNVERIFIED_VERIFICATION_DECLINE:
    case TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_EXPIRE:
    case TRANSITION_UNVERIFIED_EXPIRE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE:
    case TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE:
    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_WITHDRAW_BY_ADMIN:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_CONFIRMED:
    case TRANSITION_REQUEST_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_DISPUTE:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_ADMIN_CANCEL_NON_REFUND:
    case TRANSITION_ADMIN_CANCEL_REFUND:
    case TRANSITION_ADMIN_REFUND:
    case TRANSITION_ENQUIRE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT:
    case TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD:
    case TRANSITION_MARK_RESOLVED:
      result.message = '';
      break;

    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
      if (homeDelivery) {
        result.message = 'Host will deliver the car at this location';
      } else {
        result.message =
          'This is an approximate location. Actual location of the car will be shown here 1 hour before the pickup time.';
      }
      break;

    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
      if (endTimeDiff <= 60) {
        result.message = 'Head to the drop off address.';
        result.showIcon = true;
      } else {
        result.message = '';
      }
      break;

    case TRANSITION_UNVERIFIED_VERIFICATION_SENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
      if (homeDelivery) {
        result.message = 'Host will deliver the car at this location.';
      } else {
        result.message = 'Exact location will be shared 1 hour before the pickup time.';
      }

      break;

    case TRANSITION_ACCEPT:
    case TRANSITION_ACCEPT_BY_ADMIN:
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
      if (homeDelivery) {
        result.message = 'Host will deliver the car at this location.';
      } else {
        if (startTimeDiff <= 60) {
          result.message = 'Head to pick-up address.';
          result.showIcon = true;
        } else {
          result.message = 'Exact location will be shared 1 hour before the pickup time.';
        }
      }

      break;
  }

  return result;
};

export const canCall = lastTx => {
  let result = false;
  switch (lastTx) {
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_ACCEPT:
    case TRANSITION_ACCEPT_BY_ADMIN:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_CONFIRMED:
    case TRANSITION_REQUEST_DROP_OFF:
    case TRANSITION_DISPUTE:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT:
    case TRANSITION_MARK_RESOLVED:
      result = true;
      break;
  }

  return result;
};

export const showGuestSideMap = lastTx => {
  let result = false;
  switch (lastTx) {
    case TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN:
    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_ACCEPT:
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
      result = true;
      break;
  }

  return result;
};

export const computedHostDashboardLabel = lastTx => {
  let result = {
    text: '',
    bgColor: '',
    color: '',
  };

  switch (lastTx) {
    case TRANSITION_ENQUIRE:
      result.text = 'Enquiry';
      result.bgColor = '';
      result.color = '';
      break;
    case TRANSITION_REQUEST:
      result.text = 'Payment pending';
      result.bgColor = '';
      result.color = '';
      break;
    case TRANSITION_EXPIRE_PAYMENT:
      result.text = 'Payment failed';
      result.bgColor = '';
      result.color = '';
      break;

    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_WITHDRAW:
    case TRANSITION_WITHDRAW_BY_ADMIN:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_ADMIN_CANCEL_NON_REFUND:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_CANCEL_REFUND:
    case TRANSITION_ADMIN_REFUND:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
      result.text = 'Booking cancelled';
      result.bgColor = '#fde8e9';
      result.color = '#D91320';
      break;

    case TRANSITION_ACCEPT:
    case TRANSITION_ACCEPT_BY_ADMIN:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_CONFIRMED:
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
      result.text = 'Booking confirmed';
      result.bgColor = '#e6feff';
      result.color = ' #00A3AD';
      break;

    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_CONFIRMED:
      result.text = 'Awaiting your response';
      result.bgColor = '#e6feff';
      result.color = ' #00A3AD';
      break;

    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
      result.text = 'Ongoing';
      result.bgColor = '#ebf2f9';
      result.color = ' #508CCA';
      break;

    case TRANSITION_DECLINE:
      result.text = 'Booking declined';
      result.bgColor = '#fde8e9';
      result.color = '#D91320';
      break;

    case TRANSITION_EXPIRE:
      result.text = 'Booking expired';
      result.bgColor = '#fde8e9';
      result.color = '#D91320';
      break;

    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
      result.text = 'Confirm pick-up';
      result.bgColor = '#ebf2f9';
      result.color = '#508CCA';
      break;

    case TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT:
      result.text = 'Modification requested';
      result.bgColor = '#e6feff';
      result.color = '#00A3AD';
      break;

    case TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_REQUEST_DROP_OFF:
      result.text = 'Confirm drop-off';
      result.bgColor = '#ebf2f9';
      result.color = ' #508CCA';
      break;

    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_EXPIRE_PICK_UP_CONFIRMED:
      result.text = 'Review trip';
      result.bgColor = '#ebf2f9';
      result.color = ' #508CCA';
      break;

    case TRANSITION_DISPUTE:
      result.text = 'Disputed';
      result.bgColor = '#ebf2f9';
      result.color = ' #508CCA';
      break;

    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD:
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
    case TRANSITION_MARK_RESOLVED:
    case TRANSITION_REVIEW_1_BY_PROVIDER:
      result.text = 'Completed';
      result.bgColor = '#e6feff';
      result.color = '#00A3AD';
      break;

    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED:
    case TRANSITION_CONFIRM_PAYMENT_UNVERIFIED:
    case TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE:
    case TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_DECLINE:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE:
    case TRANSITION_UNVERIFIED_EXPIRE:
    case TRANSITION_UNVERIFIED_EXPIRE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN:
      result.text = '';
      result.bgColor = '';
      result.color = '';
      break;
  }

  return result;
};

export const computedGuestDashboardLabel = (
  lastTx,
  startTimeDiff,
  endTimeDiff,
  homeDeliveryAddon
) => {
  let result = {
    text: '',
    bgColor: '',
    color: '',
  };

  switch (lastTx) {
    case TRANSITION_REQUEST:
      result.text = 'Payment pending';
      result.bgColor = '';
      result.color = '';
      break;
    case TRANSITION_EXPIRE_PAYMENT:
      result.text = 'Payment failed';
      result.bgColor = '';
      result.color = '';
      break;
    case TRANSITION_ENQUIRE:
      result.text = 'Enquiry';
      result.bgColor = '';
      result.color = '';
      break;
    case TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED:
    case TRANSITION_CONFIRM_PAYMENT_UNVERIFIED:
      result.text = 'Complete verification now';
      result.bgColor = '';
      result.color = '';
      break;

    case TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE:
    case TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_DECLINE:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_DECLINED:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_DECLINED:
      result.text = 'Verification declined';
      result.bgColor = '';
      result.color = '';
      break;

    case TRANSITION_UNVERIFIED_EXPIRE:
    case TRANSITION_UNVERIFIED_EXPIRE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE:
      result.text = 'Booking failed';
      result.bgColor = '';
      result.color = '';
      break;

    case TRANSITION_UNVERIFIED_VERIFICATION_SENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN:
      result.text = 'Verification pending';
      result.bgColor = '';
      result.color = '';
      break;

    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_WITHDRAW_BY_ADMIN:
    case TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_ADMIN_CANCEL_NON_REFUND:
    case TRANSITION_ADMIN_CANCEL_REFUND:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING:
    case TRANSITION_ADMIN_REFUND:
      result.text = 'Booking cancelled';
      result.bgColor = '#fde8e9';
      result.color = '#D91320';
      break;

    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_CONFIRMED:
      result.text = 'Request sent';
      result.bgColor = '#e6feff';
      result.color = ' #00A3AD';
      break;

    case TRANSITION_DECLINE:
    case TRANSITION_EXPIRE:
      result.text = 'Booking declined';
      result.bgColor = '#fde8e9';
      result.color = '#D91320';
      break;

    case TRANSITION_ACCEPT:
    case TRANSITION_ACCEPT_BY_ADMIN:
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
      if (startTimeDiff < 60 && !homeDeliveryAddon) {
        result.text = 'Location available';
        result.bgColor = '#e6feff';
        result.color = ' #00A3AD';
      } else {
        result.text = 'Booking confirmed';
        result.bgColor = '#e6feff';
        result.color = ' #00A3AD';
      }

      break;

    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
    case TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_CONFIRMED:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
      result.text = 'Booking confirmed';
      result.bgColor = '#e6feff';
      result.color = ' #00A3AD';
      break;

    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
      if (endTimeDiff < 60) {
        result.text = 'Drop off available';
        result.bgColor = '#ebf2f9';
        result.color = ' #508CCA';
      } else {
        result.text = 'Ongoing';
        result.bgColor = '#ebf2f9';
        result.color = ' #508CCA';
      }

      break;

    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN:
    case TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
      result.text = 'Ongoing';
      result.bgColor = '#ebf2f9';
      result.color = ' #508CCA';
      break;

    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
      result.text = 'Pick-up requested';
      result.bgColor = '#ebf2f9';
      result.color = '#508CCA';
      break;

    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
      // case TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT:
      result.text = 'Modification requested';
      result.bgColor = '#e6feff';
      result.color = '#00A3AD';
      break;

    case TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF:
      result.text = 'Trip extension requested';
      result.bgColor = '#e6feff';
      result.color = '#00A3AD';
      break;

    case TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
      result.text = 'Drop-off requested';
      result.bgColor = '#ebf2f9';
      result.color = ' #508CCA';
      break;

    case TRANSITION_REQUEST_DROP_OFF:
      result.text = 'Drop off requested';
      result.bgColor = '#ebf2f9';
      result.color = ' #508CCA';
      break;

    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_EXPIRE_PICK_UP_CONFIRMED:
      result.text = 'Review trip';
      result.bgColor = '#ebf2f9';
      result.color = ' #508CCA';
      break;

    case TRANSITION_DISPUTE:
      result.text = 'Disputed';
      result.bgColor = '#ebf2f9';
      result.color = ' #508CCA';
      break;

    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD:
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
    case TRANSITION_MARK_RESOLVED:
      result.text = 'Completed';
      result.bgColor = '#e6feff';
      result.color = '#00A3AD';
      break;
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      result.text = 'Completed';
      result.bgColor = '#ebf2f9';
      result.color = '#508CCA';

      break;
    case TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
      result.text = 'Booking cancelled';
      result.bgColor = '#fde8e9';
      result.color = '#D91320';
      break;

    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
      result.text = '';
      result.bgColor = '';
      result.color = '';
      break;

    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
      if (endTimeDiff < 60) {
        result.text = 'Drop off available';
        result.bgColor = '#ebf2f9';
        result.color = ' #508CCA';
      } else {
        result.text = '';
        result.bgColor = '';
        result.color = '';
      }
      break;
  }

  return result;
};

export const RefactorStatusTitleMessage = (
  transaction,
  displayMessage,
  isDelivery,
  timeZone,
  totalPriceHost
) => {
  let metadata = displayMessage.meta;
  let returnMessage = '';
  if (isEmptyObject(metadata)) {
    returnMessage = displayMessage.tripPageStatusTitle;
  } else {
    returnMessage = getMetaValues(
      transaction,
      metadata,
      displayMessage.tripPageStatusTitle,
      timeZone,
      totalPriceHost
    );
  }

  return returnMessage;
};

export const computedMapRender = (tripanimate, currentListing) => {
  let addressLocation =
    currentListing && currentListing.attributes && currentListing.attributes.publicData.location;
  let fullAddress = `${currentListing.attributes.publicData.location.floorUnit ||
    ''} ${currentListing.attributes.publicData.location.address || ''} ${currentListing.attributes
    .publicData.location.postalCode || ''}`;
  let result = {
    mapShow: false,
    address: '',
    mapText: '',
    showIcon: false,
  };

  switch (tripanimate) {
    case '2A':
      result.mapShow = true;
      result.address = `${addressLocation.neighborhood || ''} ${addressLocation.postalCode || ''}`;
      result.mapText = 'Exact location will be shared 1 hour before the pickup time';
      result.showIcon = false;
      return result;
    case '2B':
      result.mapShow = true;
      result.address = fullAddress;
      result.mapText = '';
      result.showIcon = true;
      return result;
    case '2C':
      result.mapShow = true;
      result.address = fullAddress;
      result.mapText = 'Host will deliver the car at this location';
      result.showIcon = true;
      return result;
    case '2D':
      result.mapShow = true;
      result.address = fullAddress;
      result.mapText = '';
      result.showIcon = true;
      return result;
    case '2E':
      result.mapShow = false;
      result.address = '';
      result.mapText = '';
      result.showIcon = false;
      return result;
    case '2F':
      result.mapShow = false;
      result.address = '';
      result.mapText = '';
      result.showIcon = false;
      return result;
    case '2G':
      result.mapShow = false;
      result.address = '';
      result.mapText = '';
      result.showIcon = false;
      return result;
    case '3A':
      result.mapShow = true;
      result.address = fullAddress;
      result.mapText = 'Guest will arrive here for pick up';
      result.showIcon = true;
      return result;
    case '3B':
      result.mapShow = true;
      result.address = fullAddress;
      result.mapText = '';
      result.showIcon = true;
      return result;
    case '3C':
      result.mapShow = true;
      result.address = fullAddress;
      result.mapText = 'You will need to deliver and pick up the car from here';
      result.showIcon = true;
      return result;
  }
};

function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}';
}

export const RefactorStatusMessage = (
  transaction,
  displayMessage,
  isDelivery,
  timeZone,
  totalPriceHost
) => {
  let metadata = displayMessage.meta;

  let returnMessage = '';
  if (isDelivery) {
    if (isEmptyObject(metadata)) {
      returnMessage = displayMessage.tripPageStatusTextHomeDelivery;
    } else {
      returnMessage = getMetaValues(
        transaction,
        metadata,
        displayMessage.tripPageStatusTextHomeDelivery,
        timeZone,
        totalPriceHost
      );
    }
  } else {
    if (isEmptyObject(metadata)) {
      returnMessage = displayMessage.tripPageStatusText;
    } else {
      returnMessage = getMetaValues(
        transaction,
        metadata,
        displayMessage.tripPageStatusText,
        timeZone,
        totalPriceHost
      );
    }
  }

  return returnMessage;
};

const getMetaValues = (transaction, metaObj, displayMessages, timeZone, totalPriceHost) => {
  let entries = Object.keys(metaObj);
  let returnResult = displayMessages;
  entries.map(val => {
    if (displayMessages.includes(val)) {
      let updateMessage = MessageUpdateByKeys(transaction, val, timeZone, totalPriceHost);
      returnResult = returnResult.replace(val, updateMessage);
      return returnResult;
    }
  });

  return returnResult;
};

const MessageUpdateByKeys = (transaction, Key, timeZone, totalPriceHost) => {
  const { bookingStart: updatedBookingStart, bookingEnd: updatedBookingEnd } = getLastUpdateObj(
    transaction
  );
  // const displayStartString =
  //   transaction &&
  //   transaction.attributes &&
  //   transaction.attributes.protectedData &&
  //   transaction.attributes.protectedData.displayStartString;
  // const displayEndString =
  //   transaction &&
  //   transaction.attributes &&
  //   transaction.attributes.protectedData &&
  //   transaction.attributes.protectedData.displayStartString;
  const displayStartTime =
    transaction &&
    transaction.booking &&
    transaction.booking.attributes &&
    transaction.booking.attributes.displayStart;
  const displayEndTime =
    transaction &&
    transaction.booking &&
    transaction.booking.attributes &&
    transaction.booking.attributes.displayEnd;
  let startDate = moment(displayStartTime)
    .tz(timeZone)
    .format('DD MMM');
  let startTime = moment(displayStartTime)
    .tz(timeZone)
    .format('hh:mm A');
  let endDate = moment(displayEndTime)
    .tz(timeZone)
    .format('DD MMM');
  let endTime = moment(displayEndTime)
    .tz(timeZone)
    .format('hh:mm A');

  let UpdatedstartDate = moment(updatedBookingStart)
    .tz(timeZone)
    .format('DD MMM');
  let UpdatedstartTime = moment(updatedBookingStart)
    .tz(timeZone)
    .format('hh:mm A');
  let UpdatedendDate = moment(updatedBookingEnd)
    .tz(timeZone)
    .format('DD MMM');
  let UpdatedendTime = moment(updatedBookingEnd)
    .tz(timeZone)
    .format('hh:mm A');

  // let UpdatedstartDate = moment(
  //   transaction.attributes &&
  //   transaction.attributes.protectedData &&
  //   transaction.attributes.protectedData.childTransaction &&
  //   transaction.attributes.protectedData.childTransaction[0].bookingStart
  // )
  //   .tz(timeZone)
  //   .format('DD MMM');
  // let UpdatedstartTime = moment(
  //   transaction.attributes &&
  //   transaction.attributes.protectedData &&
  //   transaction.attributes.protectedData.childTransaction &&
  //   transaction.attributes.protectedData.childTransaction[0].bookingStart
  // )
  //   .tz(timeZone)
  //   .format('hh:mm A');
  // let UpdatedendDate = moment(
  //   transaction.attributes &&
  //   transaction.attributes.protectedData &&
  //   transaction.attributes.protectedData.childTransaction &&
  //   transaction.attributes.protectedData.childTransaction[0].bookingEnd
  // )
  //   .tz(timeZone)
  //   .format('DD MMM');
  // let UpdatedendTime = moment(
  //   transaction.attributes &&
  //   transaction.attributes.protectedData &&
  //   transaction.attributes.protectedData.childTransaction &&
  //   transaction.attributes.protectedData.childTransaction[0].bookingEnd
  // )
  //   .tz(timeZone)
  //   .format('hh:mm A');

  let result = '';

  // console.log('start date', updatedBookingStart);
  // console.log('end date', updatedBookingEnd);

  switch (Key) {
    case 'TRIP_START':
      result = `${startDate} ${startTime}`;
      return result;
    case 'TRIP_END':
      result = `${endDate} ${endTime}`;
      return result;
    case 'TRIP_START_DATE':
      result = `${startDate}`;
      return result;
    case 'TRIP_START_TIME':
      result = `${startTime}`;
      return result;
    case 'TRIP_END_TIME':
      result = `${endTime}`;
      return result;
    case 'TRIP_END_DATE':
      result = `${endDate}`;
      return result;
    case 'HOST_FIRST_NAME':
      result = transaction.provider.attributes.profile.displayName;
      return result;
    case 'GUEST_FIRST_NAME':
      result = transaction.customer.attributes.profile.displayName;
      return result;
    case 'TRIP_EARNINGS':
      result = totalPriceHost;
      return result;
    case 'PICKUP_LOCATION':
      result = transaction.listing.attributes.publicData.location.address;
      return result;
    case 'DELIVERY_LOCATION':
      result =
        transaction.attributes.protectedData &&
        transaction.attributes.protectedData.customerLocation &&
        transaction.attributes.protectedData.customerLocation.selectedPlace &&
        transaction.attributes.protectedData.customerLocation.selectedPlace.address;
      return result;
    case 'MOD_PROPOSED_START':
      result = `${UpdatedstartDate} ${UpdatedstartTime}`;
      return result;
    case 'MOD_PROPOSED_END':
      result = `${UpdatedendDate} ${UpdatedendTime}`;
      return result;
  }
};

const bookingProcessAliasAdmin = 'admin-preauthorized/sca-compatible';

const bookingProcessAliasDrivelahGoDeposit = 'drivelah-go-deposit/release-1';

export const ExcludeProcessNames = [bookingProcessAliasAdmin, bookingProcessAliasDrivelahGoDeposit];

export const ID_VERIFICATION_STATUSES = {
  pending: 'pending',
  confirmed: 'confirmed',
  declined: 'declined',
};

export const ACTIVE_TRIPS_TRANSITIONS = [
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED,
  TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_ACCEPT,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED,
  TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
  TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR,
  TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_EXPIRE_PICK_UP_CONFIRMED,
  TRANSITION_REQUEST_DROP_OFF,
  // TRANSITION_DISPUTE,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF,
  TRANSITION_AUTO_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  // JUST FOR DRIVELAH
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_CONFIRMED,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_DECLINED,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_CONFIRMED,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_DECLINED,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
];

export function findTransitionToStringForHostInbox(transition) {
  let result = '';

  switch (transition) {
    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE:
      result = 'pending';
      return result;

    case TRANSITION_ACCEPT:
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      result = 'accepted';
      return result;

    case TRANSITION_DECLINE:
      result = 'declined';
      return result;

    case TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_REQUEST_DROP_OFF:
      result = 'beingDropoff';
      return result;

    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    // JUST FOR DRIVELAH
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
      result = 'ongoing';
      return result;

    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
      result = 'beingPickUp';
      return result;

    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_CONFIRMED:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      result = 'review';
      return result;

    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD:
      result = 'completed';
      return result;

    case TRANSITION_ENQUIRE:
      result = 'enquiry';
      return result;

    case TRANSITION_EXPIRE:
      result = 'expired';
      return result;

    case TRANSITION_WITHDRAW:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_REFUND:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_REFUND:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
      result = 'cancelled';
      return result;

    case TRANSITION_WITHDRAW_BY_ADMIN:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_ADMIN_CANCEL_NON_REFUND:
      result = 'cancelled';
      return result;

    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_CONFIRMED:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      result = 'review';
      return result;

    case TRANSITION_DISPUTE:
      result = 'dispute';
      return result;

    case TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
      result = 'modification';
      return result;

    default:
      return result;
  }
}

export function findTransitionToStringForGuestInbox(transition) {
  let result = '';
  switch (transition) {
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_ACCEPT:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      result = 'accepted';
      return result;

    case TRANSITION_DECLINE:
      result = 'declined';
      return result;

    case TRANSITION_DROP_OFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_REQUEST_DROP_OFF:
      result = 'beingDropoff';
      return result;

    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    // JUST FOR DRIVELAH
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
      result = 'ongoing';
      return result;

    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
      result = 'beingPickUp';
      return result;

    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE:
      result = 'requested';
      return result;

    case TRANSITION_ENQUIRE:
      result = 'enquiry';
      return result;

    case TRANSITION_EXPIRE_PAYMENT:
      result = 'expired';
      return result;

    case TRANSITION_EXPIRE:
      result = 'expired';
      return result;

    case TRANSITION_WITHDRAW_BY_ADMIN:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_REFUND:
    case TRANSITION_ADMIN_REFUND:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED:
      result = 'cancelled';
      return result;

    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_NON_REFUND:
      result = 'cancelled';
      return result;

    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD:
      result = 'completed';
      return result;

    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_PICK_UP_CONFIRMED:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_REVIEW_1_BY_PROVIDER:
      result = 'review';
      return result;

    case TRANSITION_DISPUTE:
      result = 'dispute';
      return result;

    case TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
      result = 'modification';
      return result;

    default:
      return result;
  }
}

export function transition60MinBeforeGuest(transaction, isHome, timeZone) {
  let result = {
    isEqual: false,
    title: '',
    message: '',
  };
  let lastTx = transaction.attributes.lastTransition;

  // const displayStartString =
  //   transaction &&
  //   transaction.attributes &&
  //   transaction.attributes.protectedData &&
  //   transaction.attributes.protectedData.displayStartString;

  const displayStartTime =
    transaction &&
    transaction.booking &&
    transaction.booking.attributes &&
    transaction.booking.attributes.displayStart;

  let startDate = moment(displayStartTime)
    .tz(timeZone)
    .format('DD MMM');
  let startTime = moment(displayStartTime)
    .tz(timeZone)
    .format('hh:mm A');
  switch (lastTx) {
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_ACCEPT:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      if (isHome) {
        result.isEqual = true;
        result.title = 'Booking confirmed';
        result.message = `Your booking has been confirmed. The car will be delivered to you at ${startDate} ${startTime}. Ensure you are aware of the pickup process.`;
      } else {
        result.isEqual = true;
        result.title = 'Location available';
        result.message =
          'The exact location of the car has been updated. Uploading of pictures will be available 15 minutes before the pick up time';
      }

      return result;
    default:
      return result;
  }
}

export function transition45MinPickupGuest(transaction, isHome, timeZone) {
  let result = {
    isEqual: false,
    title: '',
    message: '',
  };
  let lastTx = transaction.attributes.lastTransition;

  switch (lastTx) {
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
      if (isHome) {
        result.isEqual = true;
        result.title = 'Drop off available';
        result.message = `Host will pick up the car at the location above after the Trip is completed.`;
      } else {
        result.isEqual = true;
        result.title = 'Drop off available';
        result.message = 'Please ensure you drop off at the exact address given above';
      }

      return result;
    default:
      return result;
  }
}

export function transition60MinEndTripGuest(transaction, isHome, timeZone) {
  let result = {
    isEqual: false,
    title: '',
    message: '',
  };
  let lastTx = transaction.attributes.lastTransition;

  switch (lastTx) {
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
      if (isHome) {
        result.isEqual = true;
        result.title = 'Drop off available';
        result.message = `Host will pick up the car at the location above after the Trip is completed.`;
      } else {
        result.isEqual = true;
        result.title = 'Drop off available';
        result.message = 'Please ensure you drop off at the exact address given above';
      }

      return result;
    default:
      return result;
  }
}

export function transition60MinBeforeHost(transaction, isHome, timeZone) {
  let result = {
    isEqual: false,
    title: '',
    message: '',
  };

  let lastTx = transaction.attributes.lastTransition;
  let guestAddress =
    transaction.attributes.protectedData &&
    transaction.attributes.protectedData.customerLocation &&
    transaction.attributes.protectedData.customerLocation.selectedPlace &&
    transaction.attributes.protectedData.customerLocation.selectedPlace.address;
  let guestFirstName =
    transaction.customer.attributes &&
    transaction.customer.attributes.profile &&
    transaction.customer.attributes.profile.displayName;

  let pickupLocation =
    transaction &&
    transaction.listing &&
    transaction.listing.attributes &&
    transaction.listing.attributes.publicData &&
    transaction.listing.attributes.publicData.location &&
    transaction.listing.attributes.publicData.location.address;

  let guestLocation =
    transaction.attributes.protectedData &&
    transaction.attributes.protectedData.customerLocation &&
    transaction.attributes.protectedData.customerLocation.selectedPlace &&
    transaction.attributes.protectedData.customerLocation.selectedPlace.address;

  // const displayStartString =
  //   transaction &&
  //   transaction.attributes &&
  //   transaction.attributes.protectedData &&
  //   transaction.attributes.protectedData.displayStartString;

  const displayStartTime =
    transaction &&
    transaction.booking &&
    transaction.booking.attributes &&
    transaction.booking.attributes.displayStart;

  let startDate = moment(displayStartTime)
    .tz(timeZone)
    .format('DD MMM');
  let startTime = moment(displayStartTime)
    .tz(timeZone)
    .format('hh:mm A');
  switch (lastTx) {
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_ACCEPT:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      if (isHome) {
        result.isEqual = true;
        result.title = 'Booking confirmed';
        result.message = `Please deliver the car at ${guestAddress} before ${startDate} ${startTime}`;
      } else {
        result.isEqual = true;
        result.title = 'Booking confirmed';
        result.message = `${guestFirstName} will pick up your car on ${startDate} at ${startTime} from ${pickupLocation}`;
      }
      return result;
    default:
      return result;
  }
}

export function transition45MinPickupHost(transaction, isHome, timeZone) {
  let result = {
    isEqual: false,
    title: '',
    message: '',
  };
  let lastTx = transaction.attributes.lastTransition;
  let guestFirstName = transaction.customer.attributes.profile.displayName;
  // const displayEndString =
  //   transaction &&
  //   transaction.attributes &&
  //   transaction.attributes.protectedData &&
  //   transaction.attributes.protectedData.displayStartString;

  const displayEndTime =
    transaction &&
    transaction.booking &&
    transaction.booking.attributes &&
    transaction.booking.attributes.displayEnd;
  let endDate = moment(displayEndTime)
    .tz(timeZone)
    .format('DD MMM');
  let endTime = moment(displayEndTime)
    .tz(timeZone)
    .format('hh:mm A');
  switch (lastTx) {
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
      if (isHome) {
        result.isEqual = true;
        result.title = 'Ongoing';
        result.message = `${guestFirstName} is on the trip. Drop off is scheduled on ${endDate} at ${endTime}. Please pickup the car at the above location.`;
      } else {
        result.isEqual = true;
        result.title = 'Ongoing';
        result.message = `${guestFirstName} is on the trip. Drop off is scheduled on ${endDate} at ${endTime}.`;
      }
      return result;

    default:
      return result;
  }
}

export function transition60MinEndTripHost(transaction, isHome, timeZone) {
  let result = {
    isEqual: false,
    title: '',
    message: '',
  };
  let lastTx = transaction.attributes.lastTransition;
  let guestFirstName = transaction.customer.attributes.profile.displayName;

  // const displayEndString =
  //   transaction &&
  //   transaction.attributes &&
  //   transaction.attributes.protectedData &&
  //   transaction.attributes.protectedData.displayStartString;

  const displayEndTime =
    transaction &&
    transaction.booking &&
    transaction.booking.attributes &&
    transaction.booking.attributes.displayEnd;
  let endDate = moment(displayEndTime)
    .tz(timeZone)
    .format('DD MMM');
  let endTime = moment(displayEndTime)
    .tz(timeZone)
    .format('hh:mm A');
  switch (lastTx) {
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
    case TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_REFUNDABLE:
      if (isHome) {
        result.isEqual = true;
        result.title = 'Ongoing';
        result.message = `${guestFirstName} is on the trip. Drop off is scheduled on ${endDate} at ${endTime}. Please pickup the car at the above location.`;
      } else {
        result.isEqual = true;
        result.title = 'Ongoing';
        result.message = `${guestFirstName} is on the trip. Drop off is scheduled on ${endDate} at ${endTime}`;
      }

      return result;

    default:
      return result;
  }
}
