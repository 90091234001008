import {
  TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
  TRANSITION_FAKE_WAITING_FOR_REFUND,
} from '../../util/transaction';

export const addWaitingForRefundTransition = (transitions = [], isProvider) => {
  // const isWaitingForRefund = txIsCustomerCancelNeedRefund(transaction);
  // if (!isWaitingForRefund) {
  //   return transitions;
  // }

  const indexTransition = transitions.findIndex(transition =>
    [
      // TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
      // TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
    ].includes(transition.transition)
  );

  if (indexTransition === -1 || isProvider) {
    return transitions;
  }

  const customerCancelTransition = transitions[indexTransition];
  const waitingForRefundTransition = {
    ...customerCancelTransition,
    transition: TRANSITION_FAKE_WAITING_FOR_REFUND,
  };

  transitions.splice(indexTransition + 1, 0, waitingForRefundTransition);

  return transitions;
};
