import * as amplitude from '@amplitude/analytics-browser';
import { getUserId as madeUserId } from './amplitudeUser';
import { createUserProperties } from './amplitudeMapEvents';
import { event_trigger_ids } from './analyticsConstants';
import axios from 'axios';

const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;

class AmplitudeAnalytics {
  constructor(apiKey) {
    this.apiKey = apiKey;
    this.isInitialized = false;
    this.currentUser = null;
  }

  initialize() {
    if (!this.isInitialized) {
      amplitude.init(this.apiKey);
      this.isInitialized = true;
    }
  }

  // setUser(userId) {
  //   // if (!userId) {
  //   //   userId = madeUserId();
  //   // }

  //   if (userId !== this.currentUser) {
  //     // Set the current user ID if different from the previous one
  //     this.currentUser = userId;
  //     amplitude.setUserId(userId);
  //     this.reset(); // Reset when changing the user ID
  //   } else {
  //     // If the user ID is the same as the previous one, no need to set it again
  //   }
  // }

  trackEvent({ eventName, eventProperties, userId, user }) {
    // Initialize Amplitude if not already initialized
    // console.log("Track event data", userId, this.currentUser, amplitude.getUserId());
    this.initialize();

    let newUserId = userId;

    // if (!newUserId) {
    //   newUserId = madeUserId();
    //   // Retrieve the new user ID if not provided
    // }
    if(!newUserId && amplitude.getUserId()) {  
      amplitude.reset();
      // console.log("Resettting the amplitude", amplitude.getUserId())
      this.currentUser = null;
    }

    if (newUserId && newUserId !== amplitude.getUserId()) {
      // Set the current user ID if different from the previous one
      this.currentUser = newUserId;
      // this.reset(); // Reset when changing the user ID
      amplitude.setUserId(newUserId);
      if (user) {
        const { data, userId } = createUserProperties(user);
        // If user data is provided, create and set user properties
        if (data && userId) {
          setUserProperties(data);
        }
      }
    }
    console.log("Ultimate event properties: ", eventName, eventProperties);
    // Log the event with Amplitude
    amplitude.logEvent(eventName, eventProperties);
  }

  reset() {
    // Reset Amplitude
    amplitude.reset();
  }
}

// Method to check for revenue-related events and log them appropriately
export const checkForRevenue = (data) => {
  if (!data || !data.event || !data.event.event_trigger_id) {
    return;
  }
  
  switch (data.event.event_trigger_id) {
    case event_trigger_ids.BOOK_REQUEST_ACCEPTED_GUEST:
    case event_trigger_ids.BOOK_MODIFICATION_ACCEPTED_GUEST:
    case event_trigger_ids.BOOK_FUEL_CHARGE_SUCCESS_GUEST:
      let rev = new amplitude.Revenue();
      rev.setPrice(parseFloat(data.properties.booking_totalprice));
      rev.setQuantity(1);
      rev.setRevenueType(data.event.Event_Name);
      amplitude.revenue(rev);
      break;
    case event_trigger_ids.BOOK_CANCELLED_GUEST_BY_GUEST:
    case event_trigger_ids.BOOK_CANCELLED_GUEST_BY_HOST:
      // case event_trigger_ids.BOOK_CANCELLED_HOST_BY_HOST:
      // case event_trigger_ids.BOOK_REQUEST_DECLINED_GUEST:
      // case event_trigger_ids.BOOK_REQUEST_DECLINED_HOST:
      let refund = new amplitude.Revenue();
      refund.setPrice(-parseFloat(data.properties.booking_totalprice));
      refund.setQuantity(1);
      refund.setRevenueType(data.event.Event_Name);
      amplitude.revenue(refund);
      break;
  }
};


// Method to send revenue information directly
export const sendJustRevenue = (data) => {
  if (data) {
    let rev = new amplitude.Revenue();
    rev.setPrice(parseFloat(data.totalPrice));
    rev.setQuantity(1);
    rev.setRevenueType(data.transition);
    amplitude.revenue(rev);
  }
};
// Export the initialize function to be used in the main app
export const initializeAmplitude = (apiKey = amplitudeApiKey) => {
  amplitude.init(apiKey);
};

const amplitudeAnalytics = new AmplitudeAnalytics(amplitudeApiKey); // No need to pass the API key here

export const logEvent = ({ eventName, eventProperties, userId, user }) => {
  // console.log("amplitudeAnalytics object", { eventName, eventProperties, userId, user });
  // console.log("AmplitudeAnalytics instance", amplitudeAnalytics, amplitude.getSessionId());
  amplitudeAnalytics.trackEvent({ eventName, eventProperties, userId, user });
};

export const setUser = (userId) => {
  amplitudeAnalytics.setUser(userId);
};

export const resetAmplitude = () => {
  amplitudeAnalytics.reset();
};

export const setUserProperty = (property, value) => {
  amplitudeAnalytics.setUserProperty(property, value);
};

export const setUserPropertyOnce = (property, value) => {
  amplitudeAnalytics.setUserPropertyOnce(property, value);
};

export const addUserProperty = (property, value) => {
  amplitudeAnalytics.addUserProperty(property, value);
};

export const setUserProperties = (properties) => {
  try {
    // Instantiate an Identify object
    const identifyEvent = new amplitude.Identify();

    // Loop through the properties and set them using the Identify object
    for (const [key, value] of Object.entries(properties)) {
      identifyEvent.set(key, value);
    }

    // Pass the Identify object to amplitude.identify() to send the user properties
    amplitude.identify(identifyEvent);
  } catch (error) {
    console.log("Error while setting user properties: ", error);
  }
};


// Function to gather user properties
const getUserImpProperties = async () => {
  const userId = amplitude.getUserId();
  const deviceId = amplitude.getDeviceId();

  // Assuming you set user properties using Identify
  // const identifyInstance = new amplitude.Identify();
  // console.log("User idenfy instance", userId, deviceId, identifyInstance)
  
  // Simulate getting user properties (example only)
  // const userProperties = identifyInstance.user_properties || {}; 
  // console.log("User properties SSSSSSS", userProperties);

  return { userId, deviceId };
};

// const getUserProperties = () => {
//   const userProperties = {};
//   const user = amplitude.getInstance().getUserProperties();
//   for (const key in user) {
//     if (user.hasOwnProperty(key)) {
//       userProperties[key] = user[key];
//     }
//   }
//   return userProperties;
// };

export const logEventViaApi = async ({eventName, eventProperties, userId, isSessionSent=true}) => {
  let sessionId, deviceId, userProperties
  // console.log("AMplitude object",  amplitude, amplitude.getDeviceId());
  try {
    if(isSessionSent) {
      sessionId = amplitude.getSessionId();
    }
  } catch (error) {
    console.log("got errot while getting the session", error);
  }
  try {
    const { userId: guestUserId, deviceId: userDeviceId } = await getUserImpProperties();
    deviceId = userDeviceId;
    // console.log("User defaults properties", deviceId, userId);
  } catch (error) {
    console.log("got errot while getting the default properties", error);
  }
  try {
    console.log("Backend api session data", {eventName, eventProperties, userId, sessionId});
    const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/gtm/log-analytics-event`, {
      eventName,
      eventProperties,
      userId,
      sessionId,
      deviceId,
    });
    // console.log('Backend API response:', response.data);
  } catch (error) {
    console.error('Error calling backend API:', error);
  }
};
