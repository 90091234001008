import classNames from 'classnames';
import { flatten } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes, { array, arrayOf, bool, func, number, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  BookingInfoSection,
  ModalCancel,
  ModalCancelPolicy,
  ModalHelp,
  NamedLink,
  PartnerInfo,
  PrimaryButton,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import config from '../../config';
import { SendMessageForm } from '../../forms';
import { convertMoneyToNumber } from '../../util/currency';
import { sendShuCarPushConfig, startShuCar } from '../../util/notification';
import {
  checkOwnListing,
  ensureCurrentUser,
  ensureListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
  getBookingConfig,
  listingIsLongTermRental,
  userDisplayNameAsString,
} from '../../util/data';
import {
  CANCEL_BOOKING_BUTTON_ID,
  EVENT_BOOK_REQUEST_WITHDRAWN_GUEST,
  EVENT_SEARCH_READ_INSURANCE,
  EVENT_TRIP_DISPUTED_GUEST,
  EVENT_TRIP_DISPUTED_HOST,
  EVENT_TRIP_DROPOFF_REQUEST_CONFIRM,
  EVENT_TRIP_DROPOFF_REQUEST_CONFIRM_GUEST,
  EVENT_TRIP_DROPOFF_REQUEST_RECEIVED,
  EVENT_TRIP_DROPOFF_REQUEST_SENT,
  EVENT_TRIP_GUEST_CANCEL_GUEST,
  EVENT_TRIP_GUEST_REVIEW_GUEST,
  EVENT_TRIP_GUEST_REVIEW_HOST,
  EVENT_TRIP_HOST_CANCEL_HOST,
  EVENT_TRIP_HOST_REVIEW_GUEST,
  EVENT_TRIP_HOST_REVIEW_HOST,
  EVENT_TRIP_MESSAGE_SENT_GUEST,
  EVENT_TRIP_MESSAGE_SENT_HOST,
  EVENT_TRIP_PICKUP_REQUEST_CONFIRM,
  EVENT_TRIP_PICKUP_REQUEST_CONFIRM_GUEST,
  EVENT_TRIP_PICKUP_REQUEST_RECEIVED,
  EVENT_TRIP_PICKUP_REQUEST_SENT,
  SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST,
  SERVER_EVENT_TRIP_GUEST_CANCEL_HOST,
  SERVER_EVENT_TRIP_HOST_CANCEL_GUEST,
  TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
  TRIP_PUBLISH_REVIEW_BUTTON_ID,
  TRIP_SEND_MESSAGE_BUTTON_ID,
  TRIP_SUBMIT_DISPUTE_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
  getUserTxRole,
  isPickUpConfirmed,
  TRANSITION_ACCEPT,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_DISPUTE,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_ENQUIRE,
  TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  txHasBeenDelivered,
  txHasFirstReviewedByCustomer,
  txHasFirstReviewedByProvider,
  txInVerificationProcess,
  txIsAccepted,
  txIsAcceptedRefundable,
  txIsAcceptedUpdateBookingBeforeDropOff,
  txIsAcceptedUpdateBookingBeforePickUp,
  txIsAfterConfirmPickUp,
  txIsAutoAcceptedUpdateBookingBeforeDropOff,
  txIsAutoAcceptedUpdateBookingBeforePickUp,
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
  txIsBeingDropOff,
  txIsBeingPickedUp,
  txIsCanceled,
  txIsCancelledUpdateBookingBeforeDropOff,
  txIsCancelledUpdateBookingBeforePickUp,
  txIsCompleted,
  txIsDeclined,
  txIsEnquired,
  txIsExpired,
  txIsExpiredUpdateBookingBeforeDropOff,
  txIsExpiredUpdateBookingBeforePickUp,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsPickedUp,
  txIsRequested,
  txIsShu,
  txIsUnverifiedInstantRequested,
  txIsUnverifiedRequested,
  txIsUnverifiedSubmitted,
  txIsUnverifiedSubmittedInstant,
  txIsWithdrawn,
  txIsWithdrawnByAdmin,
  txIWaitingRequestDropoff,
  txIWaitingRequestPickup,
  TRANSITION_ACCEPT_BY_ADMIN,
  TRANSTION_TRIP_STARTED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_TRIP_STARTED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_TRIP_PICK_UP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_TRIP_PICKUP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE,
} from '../../util/transaction';
import {
  TRANSITION_LTF_CONFIRM_PICK_UP,
  TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_LTF_REQUEST_PICK_UP,
  txIsBeingPickedUpLTF,
  txIsDeclinedLTF,
  txIsExpiredLTF,
  txIsPickedUpLTF,
  txIsRequestedLTF,
  txIWaitingRequestPickupLTF,
  txLastTransition,
} from '../../util/transactionLongTermFirst';
import {
  TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END,
  TRANSITION_LTL_CONFIRM_DROP_OFF,
  TRANSITION_LTL_REQUEST_DROP_OFF,
  TRANSITION_LTL_TRIP_END,
  txHasFirstReviewedByCustomerLTL,
  txHasFirstReviewedByProviderLTL,
  txIsAfterTripEndLTL,
  txIsBeingDropOffLTL,
  txIsCompletedLTL,
  txIWaitingRequestDropoffLTL,
} from '../../util/transactionLongTermLast';
import { propTypes, VERIFICATION_STATUS_VERIFIED } from '../../util/types';
import { isMobileSafari } from '../../util/userAgent';

import driveTogetherLogo from '../../assets/donationPage/drive-together-logo.svg';
import { AccountSettingDriverVerificationPage } from '../../containers';
import { priceData } from '../../containers/ListingPage/ListingPage.helper';
import routeConfiguration from '../../routeConfiguration';
import { getTransitionDetails } from '../../util/apiHelper';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import {
  initiateEventFromListing,
  initiateEventFromTransaction,
  pushGTMTripEvent,
} from '../../util/gtm/gtmHelpers';
import { richText } from '../../util/richText';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { createSlug, stringify } from '../../util/urlHelpers';
import Button from '../Button/Button';
import {
  HEADING_ACCEPTED_UPDATE_BOOKING,
  HEADING_DECLINED_UPDATE_BOOKING,
  HEADING_REQUEST_UPDATE_BOOKING,
} from '../TransactionPanel/PanelHeading';
import AddressLinkMaybe from './AddressLinkMaybe';
import BookingStatus from './BookingStatus';
import ButtonsSection from './ButtonsSection';
import CustomerMap from './CustomerMap';
import { dropoffTransitions, LONG_TERM_STATUS_FINISH } from './DropOffButtonsMaybe';
import DropOffSection from './DropOffSection';
import FeedSection from './FeedSection';
import ImportantLinksSection from './ImportantLinksSection';
import InfoSection from './InfoSection';
import ListingMap from './ListingMap';
import { LockUnlockButtonMaybe } from './LockUnlockButtonsMaybe';
import ModalReasonDispute from './ModalReasonDispute';
import PanelHeading, {
  HEADING_ACCEPTED,
  HEADING_CANCELED,
  HEADING_DECLINED,
  HEADING_DELIVERED,
  HEADING_ENQUIRED,
  HEADING_ONGOING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_REQUESTED,
  HEADING_WITHDRAWN,
  HEADING_WITHDRAWN_BY_ADMIN,
} from './PanelHeading';
import { pickupTransitions } from './PickupButtonsMaybe';
import PickUpSection from './PickUpSection';
import {
  computedMapRender,
  getNextCancelTransition,
  getNextInteractableTransition,
} from './transitionHelper';
import TripDetailsSection from './TripDetailsSection';
import css from './TripPanel.css';
import UpdateBookingButtonsMaybe from './UpdateBookingButtonsMaybe';
import AlertBox from '../AlertBox/AlertBox';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;
  let otherUser = null;
  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUser = currentProvider;
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUser = currentCustomer;
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
    otherUser,
  };
};

export const getAdminTransitions = (currentTransition) => {

  if (currentTransition === TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED) {
      return TRANSTION_TRIP_STARTED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE;
  }

  if (currentTransition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE) {
      return TRANSITION_TRIP_STARTED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE;
  }

  if (currentTransition === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED) {
      return TRANSITION_TRIP_PICK_UP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE;
  }

  if (currentTransition === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE) {
      return TRANSITION_TRIP_PICKUP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE;
  }

  return null;
};


export const getTransitions = transaction => {
  const lastTransition = txLastTransition(transaction);
  let isDrivelahGo =
    transaction.listing.attributes.metadata.isDrivelahGo &&
    transaction.attributes.processVersion > 28
      ? transaction.attributes.protectedData.isDrivelahGo
      : false;
  const isAcceptedNonRefundable =
    transaction &&
    transaction.attributes.transitions &&
    transaction.attributes.transitions.find(
      t => t.transition === TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE
    );

  switch (lastTransition) {
    case TRANSITION_ACCEPT:
    case TRANSITION_ACCEPT_BY_ADMIN:
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
      if (isDrivelahGo) {
        return {
          [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
        };
      }
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
      };
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
      if (isDrivelahGo) {
        return {
          [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
        };
      }
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
      };
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
      };
    case TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_LTF_REQUEST_PICK_UP,
      };
    case TRANSITION_LTF_REQUEST_PICK_UP:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_LTF_CONFIRM_PICK_UP,
      };
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
      if (isDrivelahGo) {
        return {
          [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_REQUEST_DROP_OFF_DLGO,
          [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF_DLGO,
        };
      }
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_REQUEST_DROP_OFF,
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF,
      };
    case TRANSITION_REQUEST_DROP_OFF:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_DISPUTE, TRANSITION_CONFIRM_DROP_OFF],
        //[TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_DISPUTE,
      };
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_DISPUTE, TRANSITION_CONFIRM_DROP_OFF],
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF,
      };
    case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_DISPUTE, TRANSITION_CONFIRM_DROP_OFF],
      };
    case TRANSITION_LTL_TRIP_END:
    case TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_LTL_REQUEST_DROP_OFF],
      };
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_LTL_CONFIRM_DROP_OFF],
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
      };
    default:
      return {};
  }
};

const EXTERIOR_PHOTOS = 'exteriorPhotos';

const photoSectionListCustomer = [
  {
    id: 0,
    title: 'Exterior of the car photos',
    description:
      'Document the condition of the car from the outside. Minimum 4 photos, one from each side.',
    data: EXTERIOR_PHOTOS,
    isRemarkVisible: true,
  },
  {
    id: 1,
    title: 'Interior of the car photos',
    description: 'Document the condition of the car from the inside.',
    data: 'interiorPhotos',
    isRemarkVisible: true,
  },
  {
    id: 2,
    title: 'Odometer Photo',
    description: 'Ensure odometer reading is clearly visible.',
    data: 'odometerPhotos',
    odometerData: true,
    isRemarkVisible: false,
  },
  {
    id: 3,
    title: 'Fuel Gauge Photo',
    description: 'Ensure fuel gauge value is clearly visible and readable.',
    data: 'fuelGaugePhotos',
    isRemarkVisible: false,
  },
  {
    id: 4,
    title: 'Fuel Receipt Photo',
    description:
      'If you have topped up the fuel add receipts for that. If no receipts are added we will charge you for fuel on basis of the distance travelled.',
    data: 'fuelReceiptPhotos',
    isRemarkVisible: true,
  },
  // {
  //   id: 5,
  //   title: 'Other',
  //   description: 'Added other photos.',
  //   data: 'otherPhotos',
  //   isRemarkVisible: false,
  // },
];

const photoSectionListCustomerDLGO = [
  {
    id: 0,
    title: 'Exterior of the car photos',
    description:
      'Document the condition of the car from the outside. Minimum 4 photos, one from each side.',
    data: EXTERIOR_PHOTOS,
    isRemarkVisible: true,
  },
  {
    id: 1,
    title: 'Selfie with ID card',
    description: 'Make sure your face and all the details on the ID card are clearly visible.',
    data: 'selfieWithId',
    isRemarkVisible: false,
  },
  {
    id: 2,
    title: 'Interior of the car photos (optional)',
    description: 'Document the condition of the car from the inside.',
    data: 'interiorPhotos',
    isRemarkVisible: true,
  },
  {
    id: 3,
    title: 'Odometer Photo',
    description: 'Ensure odometer reading is clearly visible.',
    data: 'odometerPhotos',
    odometerData: true,
    isRemarkVisible: false,
  },
  {
    id: 4,
    title: 'Fuel Gauge Photo',
    description: 'Ensure fuel gauge value is clearly visible and readable.',
    data: 'fuelGaugePhotos',
    isRemarkVisible: false,
  },
  {
    id: 5,
    title: 'Fuel Receipt Photo',
    description:
      'If you have topped up the fuel add receipts for that. If no receipts are added we will charge you for fuel on basis of the distance travelled.',
    data: 'fuelReceiptPhotos',
    isRemarkVisible: true,
  },
  // {
  //   id: 6,
  //   title: 'Other',
  //   description: 'Added other photos.',
  //   data: 'otherPhotos',
  //   isRemarkVisible: false,
  // },
];

const photoSectionListProvider = [
  {
    id: 0,
    title: 'Upload photos (optional)',
    description:
      'You can choose to add photos to counter validate and avoid disputes at the end. Not mandatory.',
    data: 'pickupProviderPhoto',
    isEdit: true,
    isRemarkVisible: false,
  },
];

export class TripPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      isCancelPolicyOpen: false,
      reviewSubmitted: false,
      isStatic: true,
      showCancelModal: false,
      showCancelHelpModal: false,
      showDisputeModal: false,
      showImgModal: false,
      showImgModalUrl: null,
      reasonDispute: '',
      currentPhotos: [],
      tripPhotos: [],
      exteriorPhotos: [],
      selfieWithId: [],
      interiorPhotos: [],
      odometerPhotos: [],
      fuelGaugePhotos: [],
      fuelReceiptPhotos: [],
      otherPhotos: [],
      otherProviderPhotos: [],
      pickupProviderPhoto: [],
      reasonDisputePhotoUrls: [],
      isOpenRentalAgreement: false,
      userCloseModal: false,
      numberOdometer: null,
      isReadMore: null,
      showPickUpSection: false,
      showDropOffSection: false,
      activePhotoSection: [],
      editWithAdditionalTime: false,
      isEditBookingActive: false,
      submitting: false,
      isExcessReductionState: false,
      isDeliveryState: false,
      isOpenPaymentFuelForm: false,
      odometerStartDataState: null,
      odometerEndDataState: null,
      isFuelChildTransaction: null,
      isDonateTransactionActive: false,
      paymentForFuelError: null,
      currentTransitionInfo: null,
      drivelahGoApiCall: true,
      showAlert: false,
      tripAnalyticsEvent: null,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.shouldShowPickUpSection = this.shouldShowPickUpSection.bind(this);
    this.shouldShowDropOffSection = this.shouldShowDropOffSection.bind(this);
  }

  async componentDidMount() {
    this.isMobSaf = isMobileSafari();
    const { transaction } = this.props;
    const fuelChildTransactionId =
      transaction &&
      transaction.attributes &&
      transaction.attributes.metadata &&
      transaction.attributes.metadata.fuelChildTransactionId
        ? transaction.attributes.metadata.fuelChildTransactionId
        : null;
    const isFuelChildTransaction = !!fuelChildTransactionId;

    this.callTransitionApi();

    try {
      const response = await getEventsByBucket('trip');
      // console.log('Events fetched successfully:', response.data);

      this.setState({
        tripAnalyticsEvent: response.data,
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }

    this.setState({
      isFuelChildTransaction,
    });
  }

  // componentDidMount() {
  //   this.isMobSaf = isMobileSafari();
  //   const { transaction } = this.props;
  //   const fuelChildTransactionId =
  //     transaction &&
  //     transaction.attributes &&
  //     transaction.attributes.metadata &&
  //     transaction.attributes.metadata.fuelChildTransactionId
  //       ? transaction.attributes.metadata.fuelChildTransactionId
  //       : null;
  //   const isFuelChildTransaction = fuelChildTransactionId ? true : false;

  //   this.callTransitionApi();

  //   try {
  //     response = await getEventsByBucket('trip');
  //     // console.log('Events fetched successfully:', response.data);
  //   } catch (error) {
  //     console.log('Error fetching events:', error);
  //   }


  //   this.setState({
  //     isFuelChildTransaction,
  //   });
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.isCloseFuelWindow !== this.props.isCloseFuelWindow &&
      this.state.isOpenPaymentFuelForm !== false
    ) {
      this.setState({
        isOpenPaymentFuelForm: false,
      });
    }
  }

  callTransitionApi = async () => {
    const { transactionRole, transaction } = this.props;
    const lastTransition = transaction && transaction.attributes.lastTransition;
    // const lastTransition = 'transition/confirm-payment-instant';

    let role = '';
    if (transactionRole == 'customer') {
      role = 'guest';
    } else {
      role = 'host';
    }

    getTransitionDetails({
      lastTransition,
      role,
    })
      .then(response => {
        this.setState({ currentTransitionInfo: response.data.data });
      })
      .catch(err => {});
  };

  componentWillReceiveProps(nextProps) {
    if (!this.state.userCloseModal) {
      if (!this.state.isReviewModalOpen) {
        const { transaction, transactionRole } = nextProps;
        const isCustomer = transactionRole === 'customer';
        const isProvider = transactionRole === 'provider';
        if (transaction) {
          const isCompleted = txIsCompleted(transaction) || txIsCompletedLTL(transaction);
          const isReviewedByCustomer =
            txHasFirstReviewedByCustomer(transaction) ||
            txHasFirstReviewedByCustomerLTL(transaction);
          const isReviewedByProvider =
            txHasFirstReviewedByProvider(transaction) ||
            txHasFirstReviewedByProviderLTL(transaction);

          if (isCompleted) {
            if (isCustomer && !isReviewedByCustomer) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
            if (isProvider && !isReviewedByProvider) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
          } else {
            if (isReviewedByCustomer && isProvider && !isReviewedByProvider) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
            if (isReviewedByProvider && isCustomer && !isReviewedByCustomer) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
          }
        }
      }
    }
  }

  closeFuelChargingForm = () => this.setState({ isOpenPaymentFuelForm: false });
  openFuelChargingForm = (error = null) => {
    this.setState({
      isOpenPaymentFuelForm: true,
      paymentForFuelError: error,
    });
  };

  handleOpenStatusModal = idBtn => {
    document.getElementById(idBtn).click();
  };

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  async onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    let currentTransaction = ensureTransaction(transaction);
    const isFinishLongTerm =
      get(transaction, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
    if (isFinishLongTerm) {
      currentTransaction = transaction && transaction.currentChildTransaction;
    }
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    const events =
      transactionRole === 'provider'
        ? [event_trigger_ids.TRIP_HOST_REVIEW_BY_GUEST , event_trigger_ids.TRIP_HOST_REVIEW_BY_HOST]
        : [ event_trigger_ids.TRIP_GUEST_REVIEW_BY_HOST, event_trigger_ids.TRIP_GUEST_REVIEW_BY_GUEST];

    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => {
        // pushGTMTripEvent({
        //   props: this.props,
        //   event: events[0],
        //   transaction,
        //   buttonId: TRIP_PUBLISH_REVIEW_BUTTON_ID,
        //   existTransaction: true,
        //   userDiffActionTaker: true,
        // });
        // pushGTMTripEvent({
        //   props: this.props,
        //   event: events[1],
        //   transaction,
        //   buttonId: TRIP_PUBLISH_REVIEW_BUTTON_ID,
        //   existTransaction: true,
        // });
        triggerAnalyticsEvent({
          event_id: events[0],
          eventData: this.state.tripAnalyticsEvent,
          props: {
            host: transaction.provider,
            guest: transaction.customer,
            trip: transaction,
            ui: {
              button: TRIP_PUBLISH_REVIEW_BUTTON_ID,
              page: "TripScreen"
            }
          },
          isSessionSent: false,
          userId: transaction.provider ? transaction.provider.id.uuid : null 
        });
        triggerAnalyticsEvent({
          event_id: events[1],
          eventData: this.state.tripAnalyticsEvent,
          props: {
            host: transaction.provider,
            guest: transaction.customer,
            trip: transaction,
            ui: {
              button: TRIP_PUBLISH_REVIEW_BUTTON_ID,
              page: "TripScreen"
            }
          },
          userId: transaction.customer ? transaction.customer.id.uuid : null 
        });
        this.setState({ isReviewModalOpen: false, reviewSubmitted: true });
      })
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      // window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  async onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage, transactionRole } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);
    const event =
      transactionRole === 'provider' ? event_trigger_ids.TRIP_MESSAGESENT_HOST  : event_trigger_ids.TRIP_MESSAGESENT_GUEST;

    if (!message) {
      return;
    }

    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        // pushGTMTripEvent({
        //   props: this.props,
        //   event: event,
        //   transaction,
        //   buttonId: TRIP_SEND_MESSAGE_BUTTON_ID,
        //   existTransaction: true,
        // });
        triggerAnalyticsEvent({
          event_id: event,
          eventData: this.state.tripAnalyticsEvent,
          props: {
            host: transaction.provider,
            guest: transaction.customer,
            trip: transaction,
            ui: {
              button: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
              page: "TripScreen"
            }
          },
          userId: transactionRole === 'provider'
            ? (transaction && transaction.provider && transaction.provider.id ? transaction.provider.id.uuid : null)
            : (transaction && transaction.customer && transaction.customer.id ? transaction.customer.id.uuid : null)
        });
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  handleChooseDisputePhoto(fileUrl) {
    const isExisted = this.state.reasonDisputePhotoUrls.find(url => url === fileUrl);
    if (isExisted) {
      this.setState({
        reasonDisputePhotoUrls: this.state.reasonDisputePhotoUrls.filter(url => url !== fileUrl),
      });
    } else {
      this.setState({
        reasonDisputePhotoUrls: [...this.state.reasonDisputePhotoUrls, fileUrl],
      });
    }
  }

  onPushDisputeEventGTM = () => {
    const { transaction, transactionRole } = this.props;
    try {
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.TRIP_DISPUTED_HOST,
        eventData: this.state.tripAnalyticsEvent,
        props: {
          host: transaction.provider,
          guest: transaction.customer,
          trip: transaction,
          ui: {
            button: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
            page: "TripScreen"
          }
        },
        isSessionSent: false,
        userId: transaction.provider ? transaction.provider.id.uuid : null 
      });

      triggerAnalyticsEvent({
        event_id: event_trigger_ids.TRIP_DISPUTED_GUEST,
        eventData: this.state.tripAnalyticsEvent,
        props: {
          host: transaction.provider,
          guest: transaction.customer,
          trip: transaction,
          ui: {
            button: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
            page: "TripScreen"
          }
        },
        userId: transaction.customer ? transaction.customer.id.uuid : null 
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
  };

  onPushPickUpAndDropOffAnalyticsEvent = async (transaction, transitionName) => {
    let event = [];
    if (transitionName && transitionName.includes('request-pick-up')) {
      event = [event_trigger_ids.TRIP_PICKUP_REQUEST_SENT, event_trigger_ids.TRIP_PICKUP_REQUEST_RECEIVED];
    } else if (transitionName && transitionName.includes('confirm-pick-up')) {
      event = [ event_trigger_ids.TRIP_PICKUP_REQUEST_CONFIRM,  event_trigger_ids.TRIP_PICKUP_REQUEST_CONFIRM_GUEST];
    } else if (transitionName && transitionName.includes('request-drop-off')) {
      event = [ event_trigger_ids.TRIP_DROPOFF_REQUEST_SENT, event_trigger_ids.TRIP_DROPOFF_REQUEST_RECEIVED];
    } else if (transitionName && transitionName.includes('confirm-drop-off')) {
      event = [ event_trigger_ids.TRIP_DROPOFF_REQUEST_CONFIRM, event_trigger_ids.TRIP_DROPOFF_REQUEST_CONFIRM_GUEST];
    }
   
    try {

      triggerAnalyticsEvent({
        event_id: event[0],
        eventData: this.state.tripAnalyticsEvent,
        props: {
          host: transaction.provider,
          guest: transaction.customer,
          trip: transaction,
          ui: {
            button: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
            page: "TripScreen"
          }
        },
        isSessionSent: false,
        userId: transaction.provider ? transaction.provider.id.uuid : null 
      });

      triggerAnalyticsEvent({
        event_id: event[1],
        eventData: this.state.tripAnalyticsEvent,
        props: {
          host: transaction.provider,
          guest: transaction.customer,
          trip: transaction,
          ui: {
            button: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
            page: "TripScreen"
          }
        },
        userId: transaction.customer ? transaction.customer.id.uuid : null 
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
  };


  onCancelTripGTM = async isAccepted => {
    const { transaction, transactionRole } = this.props;
    let events;
    const response = await getEventsByBucket('book');
    try {
      if(transactionRole === 'provider') {

        triggerAnalyticsEvent({
          event_id: event_trigger_ids.BOOK_CANCELLED_GUEST_BY_HOST,
          eventData: response.data,
          props: {
            guest: transaction.provider,
          },
          userId: transaction.customer ? transaction.customer.id.uuid : null
        });
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.BOOK_CANCELLED_HOST_BY_HOST,
          eventData: response.data,
          props: {
            guest: transaction.provider,
          },
          isSessionSent: false,
          userId: transaction.provider ? transaction.provider.id.uuid : null
        });

      }
      else {
          triggerAnalyticsEvent({
            event_id: event_trigger_ids.BOOK_CANCELLED_GUEST_BY_GUEST,
            eventData: response.data,
            props: {
              guest: transaction.customer,
            },
            userId: transaction.customer ? transaction.customer.id.uuid : null
          });
          triggerAnalyticsEvent({
            event_id: event_trigger_ids.BOOK_CANCELLED_HOST_BY_GUEST_,
            eventData: response.data,
            props: {
              guest: transaction.provider,
            },
            isSessionSent: false,
            userId: transaction.provider ? transaction.provider.id.uuid : null
          });
      }
      
    } catch (error) {
      
    }
    
    // if (isAccepted) {
    //   events =
    //     transactionRole === 'provider'
    //       ? [SERVER_EVENT_TRIP_HOST_CANCEL_GUEST, EVENT_TRIP_HOST_CANCEL_HOST]
    //       : [SERVER_EVENT_TRIP_GUEST_CANCEL_HOST, EVENT_TRIP_GUEST_CANCEL_GUEST];
    //   pushGTMTripEvent({
    //     props: this.props,
    //     event: events[0],
    //     transaction,
    //     buttonId: CANCEL_BOOKING_BUTTON_ID,
    //     buttonText: 'cancel',
    //     existTransaction: true,
    //     userDiffActionTaker: true,
    //     isServerSideEvent: true,
    //   });
    //   pushGTMTripEvent({
    //     props: this.props,
    //     event: events[1],
    //     transaction,
    //     buttonId: CANCEL_BOOKING_BUTTON_ID,
    //     buttonText: 'cancel',
    //     existTransaction: true,
    //   });
    // } else {
    //   events = [SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST, EVENT_BOOK_REQUEST_WITHDRAWN_GUEST];
    //   initiateEventFromTransaction({
    //     props: this.props,
    //     transaction,
    //     buttonId: CANCEL_BOOKING_BUTTON_ID,
    //     buttonText: 'cancel',
    //     event: events[0],
    //     existTransaction: true,
    //     userDiffActionTaker: true,
    //     isServerSideEvent: true,
    //   });
    //   initiateEventFromTransaction({
    //     props: this.props,
    //     transaction,
    //     buttonId: CANCEL_BOOKING_BUTTON_ID,
    //     buttonText: 'cancel',
    //     event: events[1],
    //     existTransaction: true,
    //   });
    // }
  };

  shouldShowPickUpSection(value) {
    if (value.target) {
      this.setState({
        showPickUpSection: !this.state.showPickUpSection,
      });
    } else {
      this.setState({
        showPickUpSection: value,
      });
    }
  }

  shouldShowDropOffSection(value) {
    if (value.target) {
      this.setState({
        showDropOffSection: !this.state.showDropOffSection,
      });
    } else {
      this.setState({
        showDropOffSection: value,
      });
    }
  }

  setRawActionPhotoSection = photos => {
    const value = photos.map(photosArray => !!(Array.isArray(photosArray) && photosArray.length));
    this.setState({
      activePhotoSection: value,
    });
  };

  setActivePhotoSection = photos => {
    const { exteriorPhotos } = this.state;
    const exteriorPhotosBool = photos[0].type === EXTERIOR_PHOTOS;
    const interiorPhotosBool = photos[0].type === 'interiorPhotos';
    const odometerPhotosBool = photos[0].type === 'odometerPhotos';
    const fuelGaugePhotosBool = photos[0].type === 'fuelGaugePhotos';
    const fuelReceiptPhotosBool = photos[0].type === 'fuelReceiptPhotos';
    const selfieWithIdBool = photos[0].type === 'selfieWithId';
    const pickupProviderPhotosBool = photos[0].type === 'pickupProviderPhoto';
    const otherPhotosBool = photos[0].type === 'otherPhotos';
    const otherProviderPhotosBool = photos[0].type === 'otherProviderPhotos';
    const index = photos[0].id;
    const currentActivePhotoSection = this.state.activePhotoSection;
    const totatalExteriorPhotos = exteriorPhotosBool && [...exteriorPhotos, ...photos];
    if (exteriorPhotosBool && totatalExteriorPhotos.length >= 4) {
      currentActivePhotoSection[index] = true;
      this.setState({
        activePhotoSection: [...currentActivePhotoSection],
      });
    }
    if (
      (interiorPhotosBool ||
        odometerPhotosBool ||
        fuelGaugePhotosBool ||
        fuelReceiptPhotosBool ||
        selfieWithIdBool ||
        pickupProviderPhotosBool ||
        otherPhotosBool ||
        otherProviderPhotosBool) &&
      photos.length >= 1
    ) {
      currentActivePhotoSection[index] = true;
      this.setState({
        activePhotoSection: [...currentActivePhotoSection],
      });
    }
    this.setState({
      currentPhotos: [...this.state.currentPhotos, ...photos],
    });
  };

  // renderToastMessage = () => {
  //   const userInfoMessages = <FormattedMessage id="DlgoUnlockCarMessage" />;
  //   return (
  //     <AlertBox
  //       title="User Info"
  //       message={userInfoMessages}
  //       type="info"
  //       button={() => {
  //         console.log();
  //       }}
  //     />
  //   );
  // };

  handleSetTripPhotos = data => {
    const photoList = this.isPickUp ? this.photoSectionListPickUp : this.photoSectionListDropOff;
    const photosType = Object.keys(data)[0];
    const photoListItem = photoList.find(item => item.data === photosType);
    const photosId = photoListItem ? photoListItem.id : null;
    const newState = {
      ...data,
      currentPhotos: this.state.currentPhotos.filter(photo => {
        if (photo.type !== photosType) {
          return true;
        } else if (photo.type === photosType) {
          return !!flatten(Object.values(data)).find(item => item.fileUrl === photo.fileUrl);
        }
      }),
    };
    const dataLength = data[photosType].length;
    if (dataLength === 0 || (photosType === EXTERIOR_PHOTOS && dataLength < 4)) {
      newState.activePhotoSection = [...this.state.activePhotoSection];
      newState.activePhotoSection[photosId] = undefined;
    }
    const { transaction } = this.props;

    if (this.state.drivelahGoApiCall) {
      let selfie = newState && newState.currentPhotos.filter(item => item.type == 'selfieWithId');
      let exteriorPhotos =
        newState && newState.currentPhotos.filter(item => item.type == 'exteriorPhotos');
      if (selfie.length >= 1 && exteriorPhotos.length >= 4) {
        this.setState({ drivelahGoApiCall: false });
        const apiData = {
          user_id: transaction.customer.attributes.profile.publicData.shuUserId,
          booking_id: transaction.id.uuid,
          fleet_id: transaction.listing.attributes.publicData.license_plate_number,
        };

        sendShuCarPushConfig(apiData).then(res => {
          startShuCar(apiData).then(() => {
            console.log('called');
            // this.renderToastMessage();
            this.setState({ showAlert: true });
          });
        });
      }
    }

    this.setState(newState);
  };

  handlePaymentForChildLongTerm = () => {
    const {
      callSetInitialValues,
      onInitializeCardPaymentData,
      onResetCode,
      history,
      transaction,
      childTransaction: {},
    } = this.props;
    const childTransaction = transaction.nextTransaction;
    const listing = get(transaction, 'listing');
    const location = get(listing, 'attributes.publicData.location', {});
    const bookingStart = get(childTransaction, 'booking.attributes.displayStart');
    const bookingEnd = get(childTransaction, 'booking.attributes.displayEnd');
    const transactionId = get(childTransaction, 'id.uuid');
    if (!transactionId) {
      window && window.location.reload();
    }
    const initialValues = {
      parentTransaction: transaction,
      listing,
      bookingData: {
        signupCredits: 0,
        voucherCode: null,
      },
      bookingDates: {
        bookingStart: new Date(bookingStart),
        bookingEnd: new Date(bookingEnd),
      },
      confirmPaymentError: null,
    };

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutLongTermPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    onResetCode();
    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutLongTermPage',
        routes,
        { id: listing.id.uuid, transactionId, slug: createSlug(listing.attributes.title) },
        { searchPageParams: location.search }
      )
    );
  };

  getChildTransactionData = value => {
    const mapedLineItems = value.lineItems.map(item => {
      return {
        ...item,
        lineTotal: convertMoneyToNumber(item.lineTotal),
        unitPrice: convertMoneyToNumber(item.unitPrice),
      };
    });

    const childTransaction = {
      lineItems: mapedLineItems,
      bookingStart: moment(value.bookingStart).format('YYYY-MM-DD HH:mm:ss'),
      bookingEnd: moment(value.bookingEnd).format('YYYY-MM-DD HH:mm:ss'),
      payinTotal: convertMoneyToNumber(value.payinTotal),
      transactionId: value.transactionId,
      transaction: value.transaction,
      bookingStartTimestamp: new Date(value.bookingStart).getTime(),
      bookingEndTimestamp: new Date(value.bookingEnd).getTime(),
    };

    this.setState({
      childTransaction,
    });
  };

  onSaveCard = values => {
    const {
      currentUser,
      onAddFuelInclusionAndSaveCard,
      transaction,
      transactionRole,
      onFetchTransaction,
      onPaymentFuelAfterFail,
    } = this.props;
    const errorFuelInclusion = get(transaction, 'attributes.metadata.errorFuelInclusion');
    const isSavedCard = get(currentUser, 'stripeCustomer.defaultPaymentMethod');

    if (errorFuelInclusion) {
      const dropOffOdometer = get(transaction, 'attributes.protectedData.dropOffOdometer');
      const pickUpOdometer = get(transaction, 'attributes.protectedData.pickUpOdometer');
      const distanceTravelled = dropOffOdometer - pickUpOdometer || this.state.distanceTravelled;
      return onPaymentFuelAfterFail({ trip: transaction, distanceTravelled }).then(
        res => {
          this.closeFuelChargingForm();
          onFetchTransaction(transaction && transaction.id, transactionRole);
        }
      );
    }
    const ensuredStripeCustomer = ensureStripeCustomer(currentUser && currentUser.stripeCustomer);
    const { card, stripe, name } = values;
    const transactionId = get(transaction, 'id.uuid');

    const billingDetails = {
      name,
      email: ensureUser(currentUser).attributes.email,
    };

    const saveCardParams = isSavedCard
      ? { transactionId }
      : { card, billingDetails, ensuredStripeCustomer, stripe, transactionId };

    return onAddFuelInclusionAndSaveCard(saveCardParams).then(res => {
      this.closeFuelChargingForm();
      onFetchTransaction(transaction && transaction.id, transactionRole);
    });
  };

  onAddFuelCharging = () => {
    return this.openFuelChargingForm();
  };

  get currentTransaction() {
    return ensureTransaction(this.props.transaction);
  }

  get currentListing() {
    return ensureListing(this.currentTransaction.listing);
  }

  get isCustomer() {
    return this.props.transactionRole === 'customer';
  }

  get isProvider() {
    return this.props.transactionRole === 'provider';
  }

  get photoSectionListPickUp() {
    return this.isCustomer
      ? this.isDrivelahGo
        ? photoSectionListCustomerDLGO
        : photoSectionListCustomer
      : photoSectionListProvider;
  }

  get photoSectionListDropOff() {
    return this.isCustomer ? photoSectionListCustomer : photoSectionListProvider;
  }

  get isDrivelahGo() {
    return (
      this.currentTransaction.attributes.protectedData.isDrivelahGo ||
      this.currentListing.attributes.metadata.isDrivelahGo
    );
  }

  get isPickUp() {
    return (
      txIWaitingRequestPickup(this.currentTransaction) ||
      txIsBeingPickedUp(this.currentTransaction) ||
      txIWaitingRequestPickupLTF(this.currentTransaction) ||
      txIsBeingPickedUpLTF(this.currentTransaction) ||
      txIsAcceptedRefundable(this.currentTransaction)
    );
  }

  get isDropOff() {
    return (
      txIsBeingDropOff(this.currentTransaction) ||
      txIWaitingRequestDropoff(this.currentTransaction) ||
      txIWaitingRequestDropoffLTL(this.currentTransaction) ||
      txIsBeingDropOffLTL(this.currentTransaction)
    );
  }

  get isFuelIncluded() {
    return (
      get(this.props.transaction, 'attributes.protectedData.isFuelInclusion') ||
      get(this.props.transaction, 'attributes.metadata.isFuelInclusion')
    );
  }

  get disableOdometerDataField() {
    return this.state.odometerPhotos.length === 0;
  }

  get isOdometerStartDataValid() {
    const photos = this.state.odometerPhotos;
    return this.isFuelIncluded ? photos && photos.length && !!photos[0].odometerStartData : true;
  }

  get isOdometerEndDataValid() {
    const photos = this.state.odometerPhotos;
    return this.isFuelIncluded ? photos && photos.length && !!photos[0].odometerEndData : true;
  }

  render() {
    const {
      currentOdometerReading,
      isCancelUpdateBookingsPending,
      isCancelUpdateBookingsError,
      isCancelUpdateBookingByAdminPending,
      isAcceptUpdateBookingByAdminPending,
      isLimitedUser,
      updateBookingTxs,
      dlGoTripDistanceInProgress,
      fuelPrice,
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      acceptButtonName,
      declineButtonName,
      onTransit,
      transitInProgress,
      transitError,
      keyRulesConfig,
      onSendMessage,
      ownListing,
      nextTransitions,
      transactionWithoutRestore,
      acceptSaleGTM,
      declineSaleGTM,
      onHandlePaymentFuel,
      isMobileLayout,
      isTabletLayout,
      history,
      onUploadInteriorPhotoToMetadata,
      uploadInteriorPhotoProgress,
      uploadInteriorSuccess,
      onFetchCancelUpdateBooking,
      onFetchTimeSlots,
      monthlyTimeSlots,
      timeSlots,
      checkedCode,
      checkCodeInProgress,
      checkCodeErorr,
      unitType,
      onCheckingVoucher,
      onEstimateBreakdown,
      estimateBreakdownInProgress,
      estimateError,
      estimatedTx,
      fetchTimeSlotsInProgress,
      getListing,
      onRequestToUpdateBooking,
      customerUpdateBookingInProgress,
      transactionUpdateBooking,
      listingForMap,
      customerUpdateBookingError,
      handleFuelSubmit,
      isOpenFuelWindow,
      initiateOrderInProgress,
      handleCardPaymentInProgress,
      confirmPaymentInProgress,
      savePaymentMethodInProgress,
      payForFuelFromModal,
      dropOffMarker,
      setDropOffMarker,
      fuelNotificationWithoutCharge,
      getDlGoDistance,
      onUpdateListingOdometerData,
      onFuelNotificationFor500,
      onCancelUpdateBookingByAdmin,
      onAcceptUpdateBookingByAdmin,
    } = this.props;
    const currentTransaction = this.currentTransaction;
    const transactionId = get(currentTransaction, 'id.uuid');
    const currentListing = this.currentListing;
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = this.isCustomer;
    const isProvider = this.isProvider;
    // let insuranceType = currentListing.attributes.publicData.insurance;
    const timeZone =
      getDefaultTimeZoneOnBrowser(
        currentListing.attributes.publicData && currentListing.attributes.publicData.listingTimezone
      ) || 'Asia/Singapore';

    if (this.state.showAlert) {
      const userInfoMessages = <FormattedMessage id="DlgoUnlockCarMessage" />;
      this.setState({ showAlert: false });
      return (
        <AlertBox
          title="Car unlocked"
          message={userInfoMessages}
          type="info"
          button={() => {
            console.log();
          }}
        />
      );
    }
    const fuelType =
      currentListing.attributes.publicData && currentListing.attributes.publicData.fuelType;
    const customerLocation = currentTransaction.attributes.protectedData.customerLocation;
    const customerGeolocation =
      customerLocation &&
      Object.assign(
        { _sdkType: 'LatLng' },
        customerLocation && customerLocation.selectedPlace && customerLocation.selectedPlace.origin
      );

    const UserInfoForDisplay = this.state.currentTransitionInfo;

    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
    const { blockNo, building, floorUnit, city, country, postalCode } = protectedData;
    const { selectedPlace } = protectedData.location || {};
    const { address, origin } = selectedPlace || {};
    const initialValuesForFuelPayment = {
      name: userName,
      blockNo,
      addressLine1: blockNo + ' ' + address || '',
      addressLine2: floorUnit || '',
      location: {
        search: address || '',
        selectedPlace: selectedPlace || '',
      },
      building: building || '',
      floorUnit: floorUnit || '',
      city: city || 'Singapour',
      country: country || 'Singapour',
      postalCode: postalCode || 20111,
      postal: postalCode || 20111,
    };

    const isDelivery =
      (currentTransaction &&
        currentTransaction.attributes &&
        currentTransaction.attributes.protectedData &&
        currentTransaction.attributes.protectedData.isDelivery) ||
      (currentTransaction &&
        currentTransaction.attributes &&
        currentTransaction.attributes.metadata &&
        currentTransaction.attributes.metadata.isDelivery);

    // const isLiveListing = currentListing.id && currentListing.attributes.metadata.live;
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const localTimeZone = getDefaultTimeZoneOnBrowser(
      currentListing.attributes.publicData && currentListing.attributes.publicData.listingTimezone
    );
    const isLongTermListing = listingIsLongTermRental(currentListing);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const bookingConfig = getBookingConfig(currentListing);

    const customerAddress = customerLocation && customerLocation.selectedPlace;
    const listingId = currentListing.id.uuid;
    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    const isLongTerm = get(transaction, 'attributes.protectedData.isLongTermRental');
    const isFinishLongTerm =
      get(transaction, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
    const stateData = this.stateDataFn(isCustomer, transaction, isProvider, isCustomerBanned);
    const currentUserRole = getUserTxRole(currentUser.id, transaction);
    const isInstantBooking = currentListing.attributes.publicData.instantBooking;
    const isFuelIncluded = this.isFuelIncluded;
    const errorFuelInclusion = get(transaction, 'attributes.metadata.errorFuelInclusion');
    const isDrivelahGo = this.isDrivelahGo;

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const currentChildTransaction = get(
      currentTransaction,
      'attributes.metadata.currentChildTransaction'
    );
    const nextCancelTransition = getNextCancelTransition(
      currentChildTransaction ? currentTransaction.currentChildTransaction : currentTransaction,
      isProvider
    );
    const haveNextCancelTransit = nextCancelTransition.length > 0;

    const transactionForCancel = !isLongTerm
      ? transactionWithoutRestore
      : currentChildTransaction
      ? currentTransaction.currentChildTransaction
      : currentTransaction;

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
      otherUser,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = listingForMap && listingForMap.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;
    const isTripYetToStart = true;
    const listingRegistrationNumber = currentListing.attributes.publicData.license_plate_number;

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });
    const rentalAgreementFileLink =
      currentTransaction.attributes &&
      currentTransaction.attributes.metadata &&
      currentTransaction.attributes.metadata.rentalAgreementFileLink;

    const updateBookingButtons = (
      <UpdateBookingButtonsMaybe
        isCancelUpdateBookingByAdminPending={isCancelUpdateBookingByAdminPending}
        isAcceptUpdateBookingByAdminPending={isAcceptUpdateBookingByAdminPending}
        isLimitedUser={isLimitedUser}
        showButtons={stateData.showBookingButtons}
        transaction={currentTransaction}
        onRequestToUpdateBooking={onRequestToUpdateBooking}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        customerUpdateBookingError={customerUpdateBookingError}
        onCancelUpdateBookingByAdmin={onCancelUpdateBookingByAdmin}
        onAcceptUpdateBookingByAdmin={onAcceptUpdateBookingByAdmin}
      />
    );

    const hasDefaultPaymentMethod = !!(
      ensureStripeCustomer(currentUser && currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser && currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    const currentAuthor = currentListing && ensureUser(currentListing.author);

    const { price = null, title = '' } = currentListing.attributes;
    const { longTermRental } = currentListing.attributes.publicData;
    const { formattedPrice, priceTitle } = priceData(
      price,
      intl,
      // longTermRental && shouldShowPricePerMonth,
      true,
      currentListing
    );
    const onReadInsurance = () => {
      // initiateEventFromListing({
      //   props: this.props,
      //   listing: currentListing,
      //   event: EVENT_SEARCH_READ_INSURANCE,
      //   isHost: checkOwnListing(currentUser, currentListing),
      // });
    };
    const checkValidateBooking = () => {
      const emailVerificationNeeded = !!currentUser.id && !currentUser.attributes.emailVerified;

      const mobileInputNeeded = !currentUser.id
        ? false
        : !!currentUser.attributes.profile.protectedData &&
          !currentUser.attributes.profile.protectedData.phoneNumber
        ? true
        : false;
      const mobileUnverified = !currentUser.id
        ? false
        : !!currentUser.attributes.profile.protectedData &&
          !currentUser.attributes.profile.protectedData.phoneNumberVerified
        ? true
        : false;
      const mobileVerificationNeeded = mobileUnverified; //Show that mobile need verified or not

      // if (emailVerificationNeeded || mobileVerificationNeeded || mobileInputNeeded) {
      //   this.props.onOpenMissingInfomationModal(true);
      //   return false;
      // }
      return true;
    };
    const bookingUpdateSubTitle = (
      <div className={css.updateSubTitle}>
        <FormattedMessage id="TransactionPanel.bookingSubTitle" />
      </div>
    );
    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: 16,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="TransactionPanel.bookingTitle" values={{ title: richTitle }} />
    );

    const isDeliveryTransaction = get(currentTransaction, 'attributes.protectedData.isDelivery');
    const additionalId =
      currentTransaction.attributes.protectedData &&
      currentTransaction.attributes.protectedData.additionalTimeTransactionId;
    const hasAdditionalTransaction = !!additionalId;
    const { editWithAdditionalTime } = this.state;

    const classes = classNames(rootClassName || css.root, className);
    const actionButtonClasses = classNames(css.actionButtons);
    const nextInteractableTransitions = getNextInteractableTransition(
      isFinishLongTerm ? currentTransaction.currentChildTransaction : currentTransaction,
      isProvider
    );
    const haveNextTransit = nextInteractableTransitions.length > 0;
    const validTripPhotos =
      currentTransaction.attributes.protectedData.photoObjects &&
      currentTransaction.attributes.protectedData.photoObjects.length > 0;
    const metaPhotoObject =
      currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.protectedData &&
      currentTransaction.attributes.protectedData.photoObjects;
    const interiorPhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['interiorPhotos']).length > 0)
      : [];
    const odometerPhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['odometerPhotos']).length > 0)
      : [];
    const fuelGaugePhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['fuelGaugePhotos']).length > 0)
      : [];
    const fuelReceiptPhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['fuelReceiptPhotos']).length > 0)
      : [];
    const otherPhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['otherPhotos']).length > 0)
      : [];
    const tripPhotosProtectedData = validTripPhotos
      ? currentTransaction.attributes.protectedData.photoObjects
      : [];
    const allTripPhotos = [...tripPhotosProtectedData];

    const transitionMaybe = get(getTransitions(transaction), `${currentUserRole}`, null);

    console.log("Is Limited User", isLimitedUser);
    const transitionAdminMaybe = isLimitedUser ? getAdminTransitions(transitionMaybe): null;

    const confirmTransitions = [
      TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
      TRANSITION_CONFIRM_DROP_OFF,
    ];
    const needInputOdometer =
      get(currentTransaction, 'attributes.protectedData.payForFuel', false) &&
      [...pickupTransitions, ...dropoffTransitions].includes(nextInteractableTransitions[0]);

    const inputOdometerInvalid =
      !confirmTransitions.includes(nextInteractableTransitions[0]) &&
      needInputOdometer &&
      (isEmpty(this.state.numberOdometer) || isNaN(this.state.numberOdometer));

    const isShowPartnerInfo =
      !txIsRequested(transaction) &&
      !txIsDeclined(transaction) &&
      !txIsExpired(transaction) &&
      !txIsRequestedLTF(transaction) &&
      !txIsDeclinedLTF(transaction) &&
      !txIsExpiredLTF(transaction);

    const showSectionUploadCar =
      isShowPartnerInfo &&
      ((txIWaitingRequestPickup(currentTransaction) && isCustomer) ||
        txIsBeingPickedUp(currentTransaction) ||
        txIsAfterConfirmPickUp(currentTransaction) ||
        (txIWaitingRequestPickupLTF(currentTransaction) && isCustomer) ||
        txIsBeingPickedUpLTF(currentTransaction) ||
        txIWaitingRequestDropoffLTL(
          currentTransaction && currentTransaction.currentChildTransaction
        ) ||
        txIsAfterTripEndLTL(currentTransaction && currentTransaction.currentChildTransaction));

    const isShowUploadModal =
      (((txIWaitingRequestPickup(currentTransaction) ||
        txIWaitingRequestPickupLTF(currentTransaction)) &&
        isCustomer) ||
        ((txIsBeingPickedUp(currentTransaction) || txIsBeingPickedUpLTF(currentTransaction)) &&
          isProvider) ||
        ((txIWaitingRequestDropoff(currentTransaction) ||
          txIWaitingRequestDropoffLTL(
            currentTransaction && currentTransaction.currentChildTransaction
          )) &&
          isCustomer) ||
        txIsBeingDropOff(currentTransaction) ||
        txIsExpiredUpdateBookingBeforePickUp(currentTransaction) ||
        txIsExpiredUpdateBookingBeforeDropOff(currentTransaction) ||
        (txIsBeingDropOffLTL(currentTransaction && currentTransaction.currentChildTransaction) &&
          isProvider)) &&
      haveNextTransit;

    const createListingLink = (
      listingId,
      label,
      listingDeleted,
      searchParams = {},
      className = ''
    ) => {
      if (!listingDeleted) {
        const params = { id: listingId, slug: createSlug(label) };
        const to = { search: stringify(searchParams) };
        return (
          <NamedLink className={css.bookingListingTitle} name="ListingPage" params={params} to={to}>
            {label}
          </NamedLink>
        );
      } else {
        return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
      }
    };

    const mapSection = adress => {
      return (
        <div className={css.mapContainer}>
          {isCustomer ? (
            <ListingMap listing={currentListing} showAddress={stateData.showAddress} />
          ) : null}
          {isProvider ? (
            <CustomerMap transaction={currentTransaction} showAddress={stateData.showAddress} />
          ) : null}
          {geolocation && (
            <AddressLinkMaybe
              rootClassName={css.addressMobile}
              location={isProvider ? customerAddress : location}
              geolocation={isProvider ? customerGeolocation : geolocation}
              showAddress={stateData.showAddress}
              isProvider={isProvider}
              timeZone={timeZone}
              currentTransaction={currentTransaction}
              adress={adress}
            />
          )}
        </div>
      );
    };
    // );

    const donateSection = (
      <div className={css.donateAndHelp}>
        <div className={css.donateAndHelpHeader}>
          <div className={css.headerThumb}>
            <img src={driveTogetherLogo} alt="" />
          </div>
          <div className={css.headerTitle}>
            Help the people of Ukraine. Together we make a difference
          </div>
        </div>
        <div className={css.donateAndHelpBody}>
          <p>
            We believe in the power of sharing and what it can do to solve some of the most acute
            problems around us. In these times of crises, Drive lah donates all its April and May
            earnings.
          </p>
          <p>
            Join us and donate directly contributing funds for food, shelter, medical care and clean
            water.
          </p>
        </div>
        <div className={css.donateAndHelpFooter}>
          <div className={css.bottom}>
            <NamedLink name="DonationPage" className={css.underlinedButton} newTab={true}>
              Learn more
            </NamedLink>
          </div>
          <div className={css.bottom}>
            <Button
              className={css.donateButton}
              onClick={() => this.setState({ isDonateTransactionActive: true })}
            >
              Donate Now
            </Button>
          </div>
        </div>
      </div>
    );

    const donationForm = (
      <div className={css.donationFormWrapper}>
        <div className={css.formDescription}>
          <p>
            Join us and donate directly contributing funds for food, shelter, medical care and clean
            water.
          </p>
        </div>
        <div className={css.formWrapper}>
          <div className={css.formTitle}>Select your donation amount</div>
          <div className={classNames(css.customButtons, css.fields)}>
            <div className={css.customRadio}>
              <input type="radio" name="donationAmount" id="amount1" />
              <label htmlFor="amount1">$2</label>
            </div>
            <div className={css.customRadio}>
              <input type="radio" name="donationAmount" id="amount2" />
              <label htmlFor="amount2">$5</label>
            </div>
            <div className={css.customRadio}>
              <input type="radio" name="donationAmount" id="amount3" />
              <label htmlFor="amount3">$10</label>
            </div>
            <div className={css.customRadio}>
              <input type="radio" name="donationAmount" id="amount4" />
              <label htmlFor="amount4">$15</label>
            </div>
            <div className={css.customRadio}>
              <input type="radio" name="donationAmount" id="amount5" />
              <label htmlFor="amount5">$20</label>
            </div>
            <div className={css.customRadio}>
              <input type="radio" name="donationAmount" id="amount6" />
              <label htmlFor="amount6">$50</label>
            </div>
          </div>
        </div>
      </div>
    );

    const donationFormSubmit = (
      <div className={css.submitSection}>
        <div className={css.submitSectionInner}>
          {transaction ? (
            <div className={css.submitSectionPrice}>
              <span className={css.totalAmount}>
                {/* <LineItemTotalPriceNew
                  currentUser={currentUser}
                  transaction={currentTransaction}
                  isProvider={false}
                  intl={intl}
                /> */}
                $2
              </span>
              Total Amount
            </div>
          ) : null}
          <div className={css.submitSectionPrice}>
            <div className={css.submitContainer}>
              {/* <span className={css.errorMessage}>
                <FormattedMessage id="StripePaymentForm.stripe.cardError" />
              </span> */}
              {/* {!isInstantBooking ? <p className={css.paymentInfo}>{paymentInfo}</p> : null} */}
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                // inProgress={submitInProgress}
                // disabled={submitDisabled}
              >
                {/*{billingDetailsNeeded ? (*/}
                {/*  <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />*/}
                {/*) : (*/}
                {/*  <FormattedMessage id="StripePaymentForm.submitConfirmPaymentInfo" />*/}
                {/*)}*/}
                {/* {isInstantBooking ? (
                  <FormattedMessage id="StripePaymentForm.submitPaymentInfoInstant" />
                ) : this.props.isAddons ? (
                  <FormattedMessage id="StripePaymentForm.bookNow" />
                ) : (
                  <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />
                )} */}
                Donate
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    );

    const chatSection = (
      <div className={isProvider ? css.chatContainerProvider : css.chatContainer}>
        <FeedSection
          onOpenShowImgModal={url => this.setState({ showImgModal: true, showImgModalUrl: url })}
          rootClassName={css.feedContainer}
          currentTransaction={currentTransaction}
          currentUser={currentUser}
          fetchMessagesError={fetchMessagesError}
          fetchMessagesInProgress={fetchMessagesInProgress}
          initialMessageFailed={initialMessageFailed}
          messages={messages}
          oldestMessagePageFetched={oldestMessagePageFetched}
          onOpenReviewModal={this.onOpenReviewModal}
          onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
          totalMessagePages={totalMessagePages}
          isFinishLongTerm={isFinishLongTerm}
        />
        <div id="headMessageForm" className={css.headMessageForm} />
        {showSendMessageForm ? (
          <SendMessageForm
            formId={this.sendMessageFormName}
            rootClassName={css.sendMessageForm}
            messagePlaceholder={sendMessagePlaceholder}
            inProgress={sendMessageInProgress}
            sendMessageError={sendMessageError}
            onFocus={this.onSendMessageFormFocus}
            onBlur={this.onSendMessageFormBlur}
            onSubmit={this.onMessageSubmit}
            submitButtonId={TRIP_SEND_MESSAGE_BUTTON_ID}
          />
        ) : (
          <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
        )}
      </div>
    );

    const photoSectionListPickUp = this.photoSectionListPickUp;

    const photoSectionListDropOff = this.photoSectionListDropOff;

    const isPickUp = this.isPickUp;

    const isDropOff = this.isDropOff;

    const isPickUpInterior =
      (txIsPickedUp(currentTransaction) || txIsPickedUpLTF(currentTransaction)) && isDrivelahGo;

    const onNoteChange = (note, type) => {
      if (this.state[type].length > 0) {
        const photoWithNote = [...this.state[type]];
        photoWithNote.map(i => {
          i.note = note;
        });

        this.setState({ [type]: photoWithNote });
      }
    };

    const onOdometerStartDataChange = (note, type) => {
      if (this.state[type].length > 0) {
        const photoWithOdometerStartData = [...this.state[type]];
        photoWithOdometerStartData.map(i => {
          i.odometerStartData = note;
        });
        this.setState({
          [type]: photoWithOdometerStartData,
          odometerStartDataState: note,
        });
      }
    };

    const onOdometerEndDataChange = (note, type) => {
      if (this.state[type].length > 0) {
        const photoWithOdometerEndData = [...this.state[type]];
        photoWithOdometerEndData.map(i => {
          i.odometerEndData = note;
        });
        this.setState({
          [type]: photoWithOdometerEndData,
          odometerEndDataState: note,
        });
        this.props.setOdometerEndValue(Number(note));
      }
    };

    const isHourlyBooking = get(currentTransaction, 'attributes.protectedData.hourlyBooking');
    const { displayStart } = get(currentTransaction, 'booking.attributes', {});
    const isTimeToShowTransitionPickup = moment().isSameOrAfter(
      moment(displayStart).add(isHourlyBooking ? -15 : -60, 'minutes')
    );

    const lastTransition = txLastTransition(transaction);
    const isShowPhotoSection = this.state.showPickUpSection || this.state.showDropOffSection;
    const isShowCancelLinkCustomer =
      lastTransition === TRANSITION_CONFIRM_PAYMENT_UNVERIFIED ||
      lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED ||
      ((lastTransition === TRANSITION_CONFIRM_PAYMENT || txIsAccepted(transaction)) &&
        isTripYetToStart);
    const isShowAddonsLinkCustomer =
      (lastTransition === TRANSITION_CONFIRM_PAYMENT || txIsAccepted(transaction)) &&
      !isTimeToShowTransitionPickup;

    const isShuEnabledCar =
      currentTransaction.listing &&
      currentTransaction.listing.attributes.publicData &&
      currentTransaction.listing.attributes.publicData.isShuCar;

    const allTransitions = get(currentTransaction, 'attributes.transitions');
    const isPickupRequested =
      allTransitions &&
      allTransitions.find(i => i.transition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE);

    let getMapInfo = computedMapRender(
      isDelivery
        ? UserInfoForDisplay && UserInfoForDisplay.tripAnimationInfoHomeDelivery
        : UserInfoForDisplay && UserInfoForDisplay.tripAnimationInfo,
      currentListing
    );

    // console.log('UserInfoForDisplay', UserInfoForDisplay);

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            {!isShowPhotoSection && (
              <PanelHeading
                panelHeadingState={stateData.headingState}
                transactionRole={transactionRole}
                providerName={authorDisplayName}
                customerName={customerDisplayName}
                isCustomerBanned={isCustomerBanned}
                listingId={listingId}
                listingTitle={listingTitle}
                listingDeleted={listingDeleted}
              />
            )}
            {!isShowPhotoSection && (
              <BookingStatus
                currentUserRole={currentUserRole}
                transaction={transaction}
                otherUser={otherUser}
                shouldShowPickUpSection={this.shouldShowPickUpSection}
                shouldShowDropOffSection={this.shouldShowDropOffSection}
                transitionMaybe={transitionMaybe}
                transitionAdminMaybe={transitionAdminMaybe}
                timeZone={timeZone}
                isCustomer={isCustomer}
                isProvider={isProvider}
                isLimitedUser={isLimitedUser}
                providerName={authorDisplayName}
                customerName={customerDisplayName}
                isLongTerm={isLongTerm}
                isDrivelahGo={isDrivelahGo}
                openModalReview={() => this.setState({ isReviewModalOpen: true })}
                isMobileLayout={isMobileLayout}
                openDisputeModal={() => this.setState({ showDisputeModal: true })}
                UserInfoForDisplay={UserInfoForDisplay}
                isDelivery={isDelivery}
              />
            )}
            {/* {transactionTitle} */}
            {!isShowPhotoSection && isTabletLayout ? (
              <div className={css.mobileInfoContainer}>
                {!isShowPhotoSection && isTabletLayout ? (
                  <BookingInfoSection
                    transaction={transaction}
                    isCustomer={isCustomer}
                    showAvatar={false}
                    listing={currentListing}
                    currentProvider={currentProvider}
                    intl={intl}
                  />
                ) : null}

                <div className={css.grayLine} />

                {!isShowPhotoSection && isTabletLayout ? (
                  <PartnerInfo
                    currentUserRole={currentUserRole}
                    transaction={transaction}
                    otherUser={otherUser}
                    show={true}
                    isMobileLayout={isMobileLayout}
                    UserInfoForDisplay={UserInfoForDisplay}
                    isDelivery={isDelivery}
                  />
                ) : null}
              </div>
            ) : null}
            {this.state.showPickUpSection && (
              <PickUpSection
                currentOdometerReading={currentOdometerReading}
                shouldShowPickUpSection={this.shouldShowPickUpSection}
                isCustomer={isCustomer}
                photoSectionList={photoSectionListPickUp}
                setActivePhotoSection={this.setActivePhotoSection}
                setRawActionPhotoSection={this.setRawActionPhotoSection}
                activePhotoSection={this.state.activePhotoSection}
                intl={intl}
                onManageDisableScrolling={onManageDisableScrolling}
                isProvider={isProvider}
                isPickUp={isPickUp || isPickUpInterior}
                currentUser={currentUser}
                setTripPhotos={this.handleSetTripPhotos}
                state={this.state}
                allTripPhotos={allTripPhotos}
                inputOdometerInvalid={inputOdometerInvalid}
                onNoteChange={onNoteChange}
                onOdometerStartDataChange={onOdometerStartDataChange}
                photoSectionListCustomer={photoSectionListCustomer}
                photoSectionListCustomerDLGO={photoSectionListCustomerDLGO}
                isDrivelahGo={isDrivelahGo}
                isPickedUp={txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)}
                isDropOff={isDropOff}
                isPickupRequested={isPickupRequested}
                interiorPhotoDlGo={[]}
                odometerPhotoDlGo={[]}
                fuelGaugePhotoDlGo={[]}
                fuelReceiptPhotoDlGo={[]}
                otherPhotoDlGo={otherPhotoDlGo}
                disableOdometerDataField={this.disableOdometerDataField}
                isFuelIncluded={isFuelIncluded}
              />
            )}
            {this.state.showDropOffSection && (
              <DropOffSection
                currentOdometerReading={currentOdometerReading}
                shouldShowDropOffSection={this.shouldShowDropOffSection}
                isCustomer={isCustomer}
                photoSectionList={photoSectionListDropOff}
                setActivePhotoSection={this.setActivePhotoSection}
                activePhotoSection={this.state.activePhotoSection}
                intl={intl}
                onManageDisableScrolling={onManageDisableScrolling}
                isProvider={isProvider}
                isPickUp={isPickUp}
                isDropOff={isDropOff}
                currentUser={currentUser}
                setTripPhotos={this.handleSetTripPhotos}
                state={this.state}
                allTripPhotos={allTripPhotos}
                inputOdometerInvalid={inputOdometerInvalid}
                onNoteChange={onNoteChange}
                onOdometerEndDataChange={onOdometerEndDataChange}
                photoSectionListCustomer={photoSectionListCustomer}
                disableOdometerDataField={this.disableOdometerDataField}
                isFuelIncluded={isFuelIncluded}
                isDrivelahGo={isDrivelahGo}
              />
            )}
            {!isShowPhotoSection && isProvider && chatSection}
            {/* {!isShowPhotoSection && lastTransition !== TRANSITION_ENQUIRE &&
            ((isDeliveryTransaction && isProvider) || isCustomer) &&
              mapSection} */}
            {getMapInfo && getMapInfo.mapShow == true && mapSection(getMapInfo)}

            {!isShowPhotoSection && isCustomer && (
              <InfoSection
                listingRegistrationNumber={listingRegistrationNumber}
                fuelType={fuelType}
                keyRules={keyRulesConfig}
                selectedOptions={currentListing.attributes.publicData.keyRules}
                isMobileLayout={isMobileLayout}
                showAddress={stateData.showAddress}
                listing={currentListing}
                transaction={transaction}
                lastTransition={lastTransition}
              />
            )}
            {isCustomer && txInVerificationProcess(transaction) && (
              <div className="container">
                <AccountSettingDriverVerificationPage
                  pageName="tripPage"
                  haveNextTransit={haveNextTransit}
                  nextInteractableTransitions={nextInteractableTransitions}
                  isFromTripPage={true}
                  transaction={transaction}
                  transactionId={transactionId}
                  onTransit={onTransit}
                  verificationFromTripPanel={true}
                />
              </div>
            )}
            {!isShowPhotoSection &&
              isCustomer &&
              !(
                txIsUnverifiedRequested(transaction) ||
                txIsUnverifiedInstantRequested(transaction) ||
                txIsUnverifiedSubmittedInstant(transaction) ||
                txIsUnverifiedSubmitted(transaction)
              ) &&
              chatSection}
            {!isShowPhotoSection && !isCustomer && lastTransition !== TRANSITION_ENQUIRE && (
              <TripDetailsSection
                updateBookingTxs={updateBookingTxs}
                currentUser={currentUser}
                transaction={currentTransaction}
                parentTransaction={currentTransaction}
                transactionRole={transactionRole}
                viewport={this.props.viewport}
                transactionUpdateBooking={transactionUpdateBooking}
                fuelTransaction={this.props.fuelTransaction}
                intl={intl}
                fuelPrice={fuelPrice}
              />
            )}
            {isTabletLayout && !isShowPhotoSection ? (
              <ImportantLinksSection
                history={history}
                isCustomer={isCustomer}
                isAccepted={txIsAccepted(transaction)}
                onOpenCancelModal={() => this.setState({ showCancelModal: true })}
                onOpenCancelHelpModal={() => this.setState({ showCancelHelpModal: true })}
                isShowCancelLinkCustomer={isShowCancelLinkCustomer}
                isShowAddonsLinkCustomer={isShowAddonsLinkCustomer}
                transactionId={transactionId}
                transaction={transaction}
                onEditTrip={this.onEditTrip}
                onPoliciesClick={this.onPoliciesClick}
              />
            ) : null}
          </div>
          {/* {this.state.isDonateTransactionActive &&  !(currentUser && currentUser.attributes  && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.DrivetogherDonated) ? (
              <TripDonatePage
                  transaction={currentTransaction}
                  transactionId={transactionId}
                  currentUser={currentUser}
                  transactionRole={transactionRole}
                  providerName={authorDisplayName}
                  customerName={customerDisplayName}
                  isCustomerBanned={isCustomerBanned}
                  listingId={listingId}
                  listingTitle={listingTitle}
                  listingDeleted={listingDeleted}
                  isCustomer={isCustomer}
                  isProvider={isProvider}
                  handleGoBack={() => {
                    this.setState({
                      isDonateTransactionActive: false
                    })
                  }}
               />
          ) : (
            ""
          )} */}

          {!isShowPhotoSection && (
            <div className={css.asideDesktop}>
              <BookingInfoSection
                transaction={transaction}
                isCustomer={isCustomer}
                showAvatar={false}
                listing={currentListing}
                currentProvider={currentProvider}
                intl={intl}
              />
              <PartnerInfo
                currentUserRole={currentUserRole}
                transaction={transaction}
                otherUser={otherUser}
                show={true}
                isMobileLayout={isMobileLayout}
                UserInfoForDisplay={UserInfoForDisplay}
                isDelivery={isDelivery}
              />
              {isDrivelahGo && !isShowPhotoSection && !isTabletLayout ? (
                <LockUnlockButtonMaybe
                  canShowButtons={haveNextTransit}
                  transaction={currentTransaction}
                  transitInProgress={transitInProgress}
                  transitError={transitError}
                  onTransit={onTransit}
                  currentUser={currentUser}
                  transitionNames={nextInteractableTransitions}
                  handleOpenStatusModal={this.handleOpenStatusModal}
                  tripPhotos={this.state.tripPhotos}
                  allTripPhotos={allTripPhotos}
                  ownListing={ownListing}
                  onPushEventGTM={this.onPushPickUpAndDropOffAnalyticsEvent}
                  isTabletLayout={isTabletLayout}
                />
              ) : null}
              <ImportantLinksSection
                history={history}
                isCustomer={isCustomer}
                isAccepted={txIsAccepted(transaction)}
                onOpenCancelHelpModal={() => this.setState({ showCancelHelpModal: true })}
                onOpenCancelModal={() => this.setState({ showCancelModal: true })}
                isShowCancelLinkCustomer={isShowCancelLinkCustomer}
                isShowAddonsLinkCustomer={isShowAddonsLinkCustomer}
                transactionId={transactionId}
                transaction={transaction}
                onEditTrip={this.onEditTrip}
                onPoliciesClick={this.onPoliciesClick}
              />
            </div>
          )}
          {updateBookingButtons}
          {stateData.showSaleButtons || isShowPhotoSection ? (
            <ButtonsSection
              currentOdometerReading={currentOdometerReading}
              dlGoTripDistanceInProgress={dlGoTripDistanceInProgress}
              fuelPrice={fuelPrice}
              declineInProgress={declineInProgress}
              declineButtonName={declineButtonName}
              transaction={transaction}
              acceptInProgress={acceptInProgress}
              acceptSaleError={acceptSaleError}
              declineSaleError={declineSaleError}
              acceptButtonName={acceptButtonName}
              currentUser={currentUser}
              onDeclineSale={onDeclineSale}
              declineSaleGTM={declineSaleGTM}
              onAcceptSale={onAcceptSale}
              acceptSaleGTM={acceptSaleGTM}
              currentPhotos={this.state.currentPhotos}
              activePhotoSection={this.state.activePhotoSection}
              exteriorPhotos={this.state.exteriorPhotos}
              selfieWithId={this.state.selfieWithId}
              interiorPhotos={this.state.interiorPhotos}
              odometerPhotos={this.state.odometerPhotos}
              fuelGaugePhotos={this.state.fuelGaugePhotos}
              fuelReceiptPhotos={this.state.fuelReceiptPhotos}
              pickupProviderPhoto={this.state.pickupProviderPhoto}
              otherPhotos={this.state.otherPhotos}
              otherProviderPhotos={this.state.otherProviderPhotos}
              transitionMaybe={transitionMaybe}
              transitionAdminMaybe={transitionAdminMaybe}
              onTransit={onTransit}
              showSaleButtons={stateData.showSaleButtons}
              showPickUpSection={this.state.showPickUpSection}
              showDropOffSection={this.state.showDropOffSection}
              odometerStartDataStateUp={this.state.odometerStartDataState}
              odometerEndDataState={this.state.odometerEndDataState}
              transitInProgress={transitInProgress}
              inputOdometerInvalid={inputOdometerInvalid}
              isShuEnabledCar={isShuEnabledCar}
              isProvider={isProvider}
              isCustomer={isCustomer}
              allTripPhotos={allTripPhotos}
              isPickUp={isPickUp}
              isDropOff={isDropOff}
              isDrivelahGo={isDrivelahGo}
              onPushEventGTMPickUpDropOff={this.onPushPickUpAndDropOffAnalyticsEvent}
              // it will be used as login as user
              isLimitedUser={isLimitedUser}
              ownListing={ownListing}
              onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
              interiorPhotoDlGo={interiorPhotoDlGo}
              odometerPhotoDlGo={odometerPhotoDlGo}
              fuelGaugePhotoDlGo={fuelGaugePhotoDlGo}
              fuelReceiptPhotoDlGo={fuelReceiptPhotoDlGo}
              otherPhotoDlGo={otherPhotoDlGo}
              uploadInteriorPhotoProgress={uploadInteriorPhotoProgress}
              uploadInteriorSuccess={uploadInteriorSuccess}
              onHandlePaymentFuel={onHandlePaymentFuel}
              listing={currentListing}
              isFuelIncluded={isFuelIncluded}
              onEstimateFuelBreakdown={this.props.onEstimateFuelBreakdown}
              openFuelChargingForm={this.openFuelChargingForm}
              isFuelChildTransaction={this.state.isFuelChildTransaction}
              shouldShowPickUpSection={this.shouldShowPickUpSection}
              shouldShowDropOffSection={this.shouldShowDropOffSection}
              isOdometerStartDataValid={this.isOdometerStartDataValid}
              isOdometerEndDataValid={this.isOdometerEndDataValid}
              estimateBreakdownInProgress={estimateBreakdownInProgress}
              initiateOrderInProgress={initiateOrderInProgress}
              payForFuelSubmit={handleFuelSubmit}
              handleCardPaymentInProgress={handleCardPaymentInProgress}
              confirmPaymentInProgress={confirmPaymentInProgress}
              savePaymentMethodInProgress={savePaymentMethodInProgress}
              intl={intl}
              dropOffMarker={dropOffMarker}
              setDropOffMarker={setDropOffMarker}
              fuelNotificationWithoutCharge={fuelNotificationWithoutCharge}
              getDlGoDistance={getDlGoDistance}
              onUpdateListingOdometerData={onUpdateListingOdometerData}
              onFuelNotificationFor500={onFuelNotificationFor500}
              onPushDisputeEventGTM={this.onPushDisputeEventGTM}
            />
          ) : null}
          {isDrivelahGo && isShuEnabledCar && !isShowPhotoSection && isTabletLayout ? (
            <LockUnlockButtonMaybe
              canShowButtons={haveNextTransit}
              transaction={currentTransaction}
              transitInProgress={transitInProgress}
              transitError={transitError}
              onTransit={onTransit}
              currentUser={currentUser}
              transitionNames={nextInteractableTransitions}
              handleOpenStatusModal={this.handleOpenStatusModal}
              tripPhotos={this.state.tripPhotos}
              allTripPhotos={allTripPhotos}
              ownListing={ownListing}
              onPushEventGTM={this.onPushPickUpAndDropOffEventGTM}
              isTabletLayout={isTabletLayout}
            />
          ) : null}
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
          buttonId={TRIP_PUBLISH_REVIEW_BUTTON_ID}
        />
        <ModalCancel
          isCancelUpdateBookingsPending={isCancelUpdateBookingsPending}
          isCancelUpdateBookingsError={isCancelUpdateBookingsError}
          rootClassName={actionButtonClasses}
          canShowButtons={haveNextCancelTransit}
          transaction={transactionForCancel}
          transitInProgress={transitInProgress}
          transitError={transitError}
          onTransit={onTransit}
          transitionNames={nextCancelTransition}
          openCancelPolicyModal={() => this.setState({ isCancelPolicyOpen: true })}
          isCustomer={isCustomer}
          isProvider={isProvider}
          containerClassName={css.modalContainer}
          onManageDisableScrolling={onManageDisableScrolling}
          showCancelModal={this.state.showCancelModal}
          onClose={() => {
            this.setState({ showCancelModal: false });
            this.callTransitionApi();
          }}
          isInstantBooking={isInstantBooking}
          onCancelTripGTM={this.onCancelTripGTM}
          cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
          isAccepted={txIsAccepted(currentTransaction)}
          isLongTerm={isLongTerm}
          currentChildTransaction={currentChildTransaction}
          onFetchCancelUpdateBooking={onFetchCancelUpdateBooking}
        />
        <ModalHelp
          onClose={() => this.setState({ showCancelHelpModal: false })}
          cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
          isOpen={this.state.showCancelHelpModal}
          isCustomer={isCustomer}
          isProvider={isProvider}
          transaction={currentTransaction}
          currentUser={currentUser}
        />
        <ModalCancelPolicy
          containerClassName={css.missingInformationModal}
          onManageDisableScrolling={onManageDisableScrolling}
          transactionRole={transactionRole}
          showPolicyModal={this.state.isCancelPolicyOpen}
          onClose={() => this.setState({ isCancelPolicyOpen: false })}
          isLongTerm={isLongTerm}
        />
        <ModalReasonDispute
          isOpen={this.state.showDisputeModal}
          onClose={() => this.setState({ showDisputeModal: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          transaction={currentTransaction}
          transitInProgress={transitInProgress}
          transitError={transitError}
          onTransit={onTransit}
          onSendMessage={onSendMessage}
          reasonDispute={this.state.reasonDispute}
          reasonDisputePhotoUrls={this.state.reasonDisputePhotoUrls}
          onChoosePhoto={fileUrl => this.handleChooseDisputePhoto(fileUrl)}
          // tripPhotos={[
          //   ...allTripPhotos,
          //   ...(isShowUploadModal ? this.state.tripPhotos : []),
          // ].flat()}
          tripPhotos={flatten([
            ...allTripPhotos,
            ...(isShowUploadModal ? this.state.tripPhotos : []),
          ])}
          onReasonDisputeChange={value => this.setState({ reasonDispute: value })}
          onPushDisputeEventGTM={this.onPushDisputeEventGTM}
          submitButtonId={TRIP_SUBMIT_DISPUTE_BUTTON_ID}
        />
      </div>
    );
  }

  stateDataFn(isCustomer, transaction, isProvider, isCustomerBanned, isDelivery) {
    const lastTransition = transaction.attributes.lastTransition;
    if (txIsEnquired(transaction)) {
      return {
        headingState: HEADING_ENQUIRED,
        showBookingPanel: false,
      };
    } else if (txIsPaymentPending(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsPaymentExpired(transaction)) {
      return {
        headingState: HEADING_PAYMENT_EXPIRED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsWithdrawnByAdmin(transaction)) {
      return {
        headingState: HEADING_WITHDRAWN_BY_ADMIN,
        showDetailCardHeadings: true,
      };
    } else if (txIsWithdrawn(transaction)) {
      return {
        headingState: HEADING_WITHDRAWN,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsRequested(transaction) || txIsRequestedLTF(transaction)) {
      return {
        headingState: HEADING_REQUESTED,
        showDetailCardHeadings: isCustomer,
        showSaleButtons: isProvider && !isCustomerBanned,
      };
    } else if (
      txIsAccepted(transaction) &&
      (lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP ||
        lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF)
    ) {
      return {
        headingState: HEADING_DECLINED_UPDATE_BOOKING,
        showDetailCardHeadings: true,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (
      txIsAccepted(transaction) &&
      (lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
        lastTransition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP)
    ) {
      return {
        headingState: HEADING_ACCEPTED_UPDATE_BOOKING,
        showDetailCardHeadings: true,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (txIsAccepted(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAcceptedUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAcceptedUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAutoAcceptedUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsCancelledUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAutoAcceptedUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (isPickUpConfirmed(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsCancelledUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAwaitUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_REQUEST_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
        showBookingButtons: isProvider,
      };
    } else if (txIsAwaitUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_REQUEST_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
        showBookingButtons: isProvider,
      };
    } else if (
      txIWaitingRequestPickup(transaction) ||
      txIWaitingRequestPickupLTF(transaction) ||
      txIsBeingDropOff(transaction) ||
      txIsBeingDropOffLTL(transaction)
    ) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsBeingPickedUp(transaction) || txIsBeingPickedUpLTF(transaction)) {
      return {
        headingState: HEADING_REQUESTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIWaitingRequestDropoff(transaction) || txIWaitingRequestDropoffLTL(transaction)) {
      return {
        headingState: HEADING_ONGOING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsShu(transaction) || txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)) {
      return {
        headingState: HEADING_ONGOING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsDeclined(transaction) || txIsDeclinedLTF(transaction)) {
      return {
        headingState: HEADING_DECLINED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsCanceled(transaction)) {
      return {
        headingState: HEADING_CANCELED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txHasBeenDelivered(transaction)) {
      return {
        headingState: HEADING_DELIVERED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedRequested(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedInstantRequested(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedSubmitted(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else {
      return { headingState: 'unknown' };
    }
  }
}

TripPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  transitError: null,
  keyRulesConfig: config.custom.keyRules,
  nextTransitions: null,
};

TripPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  setOdometerEndValue: func.isRequired,

  //transit props
  transitInProgress: bool.isRequired,
  transitError: propTypes.error,
  onTransit: func.isRequired,

  // from injectIntl
  intl: intlShape,

  keyRulesConfig: PropTypes.array,
  initiateOrderInProgress: bool.isRequired,
  fuelTransaction: propTypes.transaction,
};

const mapStateToProps = state => {
  const { authToken } = state.Auth;

  return {
    accessToken: authToken ? authToken.access_token : null,
  };
};

// const TripPanel = injectIntl(TripPanelComponent);
const TripPanel = compose(injectIntl, connect(mapStateToProps))(TripPanelComponent);

export default TripPanel;

function userIsVerified(transaction) {
  return (
    transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED ||
    transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED
  );
}
