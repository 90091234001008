import React from 'react';
import { InsurancePlusSign, NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import insuranceLogo from '../../assets/landingpage/insurance.png';
import ntucLogo from '../../assets/landingPageNew/income_new.png';
import css from './HIWInsurance.css';

const HIWInsuranceSection = props => {
  return (
    <section className={css.insuranceSection}>
      <div className={css.textBox}>
        <div className={css.textBoxTitle}>
          <h4>
            <FormattedMessage id="HowItWorkPage.insuranceTitle" values={{plus: <InsurancePlusSign useMargin={true}/>}} />
          </h4>
          <img src={insuranceLogo} className={css.insuranceLogo}/>
          <img style={{margin: "20px 0px 20px 20px"}} src={ntucLogo} className={css.insuranceLogo}/>

        </div>
        <div className={css.textBoxDesc}>
          <FormattedMessage id="HowItWorkPage.insuranceDesc" values={{plus: <InsurancePlusSign/>}}/>
        </div>
        <NamedLink name="TrustAndSafetyPage" className={css.findOutMore}>
          <FormattedMessage id="HowItWorkPage.findOutMore" />
        </NamedLink>
      </div>
    </section>
  );
}

export default HIWInsuranceSection;
